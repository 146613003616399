import {makeAutoObservable} from 'mobx';
import {CASE_TABS} from "../Utils";
import {
    AuscultationPoint,
    Classes,
    CustomObject,
    Diagnosis, DiagnosisDisease,
    Dialogue,
    Diet,
    DragDoses,
    ExaminationData, ExaminationList,
    Hospitalization, IAddMedicine,
    InstrumentsPoint, IPassingCaseData, IPathology,
    Jobs,
    LangLocalization,
    MedicineObject, Medicines, ObjectIdAndName, PalpationPoint, ParamsGenAudio,
    Passport,
    PercussionPoint,
    Race, RowData,
    SubClasses, SubClassesProfile,
    Tabs,
    Treatment,
    VisualPoint
} from '../interface';

export default class OutpatientStore {
    //Id кейса сущность которого используеют
    selectedSubMenuId: string;
    selectedCase: SubClasses
    //Id открытой вкладки
    openTabId: string = CASE_TABS.PASSPORT;
    //категории
    classes: Array<Classes>;
    //кейсы (клинические случаи)
    subClasses: Array<SubClasses>;
    subClassesProfile: Array<SubClassesProfile>;
    // Вкладки контента в кейсах
    tabs: Array<Tabs>;
    //Данные для вкладки пасспорт в кейсах
    passport: Passport;
    //список работ
    jobs: Array<Jobs>;
    //Расы
    race: Array<Race>;
    //Диалоги в кейсах
    dialogue: Array<Dialogue> | null;
    //Перкусия в кейсах
    percussion: Array<PercussionPoint> | null;
    //Аускультация в кейсах
    auscultation: Array<AuscultationPoint> | null;
    //Пальпация в кейсах
    palpation: Array<PalpationPoint> | null;
    //Визуальный в кейсах
    visual: Array<VisualPoint> | null;
    //Интсрументы в кейсах
    instruments: Array<InstrumentsPoint> | null;
    // Диагнозы в кейсах
    diagnosis: Diagnosis;
    // Лечение в кейсах
    treatment: Treatment;
    // Госпитализация
    hospitalization: Array<Hospitalization>;
    //Диеты
    diet: Array<Diet>;
    //Кратность приема
    frequency: Array<CustomObject>;
    //Продолжительность приема
    duration: Array<CustomObject>;
    //Путь введения
    injection: Array<CustomObject>;
    //Особые условия
    condition: Array<CustomObject>;
    //Форма приема лекарств
    form: Array<CustomObject>;
    //Препараты
    medicine: Array<MedicineObject>;
    //Дозировки препарата
    dragDoses: Array<DragDoses>;
    //Доп обследования в кейсах
    examination: any | null;
    //Список вкладок
    examinationList: Array<ExaminationList>
    //модалка редактирования(имя таблицы которая редактируеться)
    editName: string;
    //Id таймеров использеумых сервисом
    timers: {
        [key: string]: any

        caseTimer: any
        dragTimer: any
    } = {
        caseTimer: undefined,
        dragTimer: undefined
    }
    //название открытой таблицы в осмотре
    nameOpenInspTab = '';
    //название открытой таблицы в обследовании
    nameOpenExamTab = '';
    //id кейса для копирования информации в текущий кейс
    idSelectedCase = '';
    //текущая аудизапись
    currentAudio: string = '';
    //отображение кейсов категории
    showSub: string = '';
    //параметры открытия модалки генерации аудио
    paramsGenAudio: ParamsGenAudio = {
        show: false,
        info: null
    }
    //патологии
    pathology: Array<IPathology>
    //данные по прохождению кейса
    passingCaseData: IPassingCaseData | null
    //данные мкб
    disease: DiagnosisDisease | null
    selectDisease: Array<DiagnosisDisease> = [];
    //данные по добавлению препарата
    addMedicine: IAddMedicine | null

    constructor() {
        this.showSubMenu = this.showSubMenu.bind(this);
        makeAutoObservable(this);
    }

    switchingCase(newCase: SubClasses, oldCaseId: string, flag?: boolean) {
        if (this.subClasses) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === oldCaseId);
            if (idx !== -1) {
                this.subClasses[idx] = newCase;
            }
        }

        if (flag) {
            this.setSelectedSubMenuId(newCase._id);
        }
    }

    setDisease(data: DiagnosisDisease | null) {
        this.disease = data;
    }

    clearSelectDisease() {
        this.selectDisease = [];
    }

    updateSelectDisease({data, oldData}: { data?: DiagnosisDisease, oldData: string }) {
        if (oldData) {
            const idx = this.selectDisease.findIndex((el: DiagnosisDisease) => el._id === oldData);
            if (idx !== -1) {
                this.selectDisease.splice(idx);
                if (data) {
                    this.selectDisease.push(data);
                }
            }
        } else {
            this.selectDisease.push(data);
        }
    }

    setAddMedicine(data: IAddMedicine | null) {
        this.addMedicine = data;
    }

    updateCasePassingText(data: { tab: string, type: string, data: string }) {
        // @ts-ignore
        this.passingCaseData.case_passing[data.tab][data.type] = data.data;
    }

    setPassingCaseData(data: IPassingCaseData) {
        this.passingCaseData = data;
    }

    setParamsGenAudio(data: ParamsGenAudio) {
        this.paramsGenAudio = data;
    }

    //установка имени текущей аудизаписи
    setCurrentAudio(url: string) {
        this.currentAudio = url;
    }

    resetCaseInfo() {
        this.selectedSubMenuId = '';
        this.selectedCase = null;
        this.openTabId = CASE_TABS.PASSPORT;
        this.nameOpenInspTab = '';
        this.nameOpenExamTab = '';

        const url = new URL(document.URL);
        url.searchParams.delete('inspTab');
        url.searchParams.delete('examTab');
        url.searchParams.delete('caseId');
        url.searchParams.delete('openTab');

        window.history.replaceState({}, '', url);
    }

    //Установка именни выбронного осмотра
    setNameOpenInspTab(name: string) {
        this.nameOpenInspTab = name;
    }

    //Установка именни выбронного доп обслд
    setNameOpenExamTab(name: string) {
        this.nameOpenExamTab = name;
    }

    //Установка id кейса из которого будет скопированы данные
    setIdSelectedCase(id: string) {
        this.idSelectedCase = id;
    }

    //Установка данных для доп обслд в кейсе
    setExamination(data: ExaminationData | null) {
        this.examination = data;
    }

    //Установка нового id открытой вкладки в кейсе
    setOpenTabId(data: string) {
        this.openTabId = data;
    }

    //Установка данных по вкладкам в кейсе
    setTabs(data: Array<Tabs>) {
        this.tabs = data;
    }

    //Установка категории кейсов
    setClasses(data: Array<Classes> | undefined) {
        this.classes = data;
    }

    updateCategoryData(data: any) {
        if (this.classes) {
            if (data.type === 'insert') {
                this.classes.push(data.value);
            } else {
                const idx = this.classes.findIndex((el: Classes) => el._id === data.categoryId);
                if (idx !== -1) {
                    if (data.type === 'delete') {
                        this.classes.splice(idx, 1);
                    } else if (data.type === 'update') {
                        this.classes[idx].name[data.lang] = data.value;
                    } else if (data.type === 'text') {
                        this.classes[idx].text[data.lang] = data.value;
                    }
                }
            }
        }

        if (data.type === 'delete' && this.subClasses && this.selectedSubMenuId) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === this.selectedSubMenuId);
            if (idx !== -1) {
                if (this.subClasses[idx].categoryId === data.categoryId) {
                    this.selectedSubMenuId = '';
                    this.selectedCase = null;
                    const url = new URL(document.URL);
                    url.searchParams.delete('caseId');
                    window.history.replaceState({}, '', url);
                }
            }
        }
    }

    deleteCase(caseId: string) {
        if (this.subClasses) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === caseId);
            if (idx !== -1) {
                this.subClasses.splice(idx, 1);
            }
        }

        if (this.selectedSubMenuId === caseId) {
            this.selectedSubMenuId = '';
            this.selectedCase = null;
            const url = new URL(document.URL);
            url.searchParams.delete('caseId');
            window.history.replaceState({}, '', url);
        }
    }

    updateSwitchCase(data: any) {
        if (this.subClasses) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === data.caseId);
            if (idx !== -1) {
                this.subClasses[idx] = data.value;
            }
        }
    }

    copyCase(data: SubClasses) {
        if (this.subClasses) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === data._id);
            if (idx === -1) {
                this.subClasses.push(data);
            }
        }
    }

    updateCaseName(data: any) {
        if (this.subClasses) {
            for (let i = 0; i < this.subClasses.length; ++i) {
                if (this.subClasses[i]._id === data.caseId || this.subClasses[i].baseCaseId === data.caseId) {
                    this.subClasses[i].name[data.lang] = data.value;
                    break;
                }
            }
        }
    }

    //Установка данных работы
    setJobs(data: Array<Jobs>) {
        this.jobs = data;
    }

    setPathology(data: Array<IPathology>) {
        this.pathology = data;
    }

    //Установка данных кейсов
    setSubClasses(data: Array<SubClasses> | undefined) {
        this.subClasses = data;
    }

    //установка id открытого кейса
    setSelectedSubMenuId(id: string, cs?: SubClasses) {
        this.selectedSubMenuId = id;

        if (cs) {
            this.selectedCase = cs;
        } else {
            if (this.subClasses) {
                const idx = this.subClasses.findIndex((el: SubClasses) => el._id === id);
                if (idx !== -1) {
                    this.selectedCase = this.subClasses[idx];
                }
            }
        }
    }

    setSubClassesProfile(data: Array<SubClassesProfile>) {
        this.subClassesProfile = data;
    }

    //Установка данных по вкладке паспорт
    setPassport(data: Passport) {
        this.passport = data;
    }

    //Обновление данных во вкладке паспорт
    updatePassport(data: Passport) {
        for (const key in data) {
            if (typeof this.passport[key] === 'object' && JSON.stringify(data[key]) !== JSON.stringify(this.passport[key])) {
                this.passport[key] = data[key];
            } else if (typeof this.passport[key] !== 'object' && data[key] !== this.passport[key]) {
                this.passport[key] = data[key];
            }
        }
    }

    //Установка данных в диалогах
    setDialogue(data: Array<Dialogue> | null) {
        this.dialogue = data;
    }

    //Установка данных в диагнозах
    setDiagnosis(data: Diagnosis) {
        this.diagnosis = data;
    }

    updateDiagnosisReplacement(data: { caseId: string, diagnosisId: string, value: number, id: string }) {
        if (data.diagnosisId === this.diagnosis._id) {
            for (let i = 0; i < this.diagnosis.disease.length; ++i) {
                if (this.diagnosis.disease[i]._id === data.id) {
                    this.diagnosis.disease[i].replacementGroup = data.value;
                    break;
                }
            }
        }
    }

    //Установка данных в лечении
    setTreatment(data: Treatment) {
        this.treatment = data;
    }

    //Установка данных госпитализации
    setHospitalization(data: Array<Hospitalization>) {
        this.hospitalization = data;
    }

    //Установка данных диеты
    setDiet(data: Array<Diet>) {
        this.diet = data;
    }

    //Установка данных кратность приема
    setFrequency(data: Array<CustomObject>) {
        this.frequency = data;
    }

    //Установка данных продолжительность приема
    setDuration(data: Array<CustomObject>) {
        this.duration = data;
    }

    //Установка данных продолжительность приема
    setInjection(data: Array<CustomObject>) {
        this.injection = data;
    }

    //Установка данных особые условия
    setCondition(data: Array<CustomObject>) {
        this.condition = data;
    }

    //Установка данных форма
    setForm(data: Array<CustomObject>) {
        this.form = data;
    }

    //Установка данных препаратов
    setMedicine(data: Array<MedicineObject>) {
        this.medicine = data;
    }

    //Установка данных категории препаратов
    setDragDoses(data: Array<DragDoses>) {
        this.dragDoses = data;
    }

    updateDiagnosisData(data: any) {
        if (this.diagnosis && this.diagnosis._id === data.diagnosisId) {
            if (data.type === 'insert') {
                this.diagnosis.disease.push(data.value);
            } else if (data.type === 'delete') {
                if (this.diagnosis.disease.length) {
                    const idx = this.diagnosis.disease.findIndex((el: DiagnosisDisease) => el._id === data.elemId);
                    if (idx !== -1) {
                        this.diagnosis.disease.splice(idx, 1);
                    }
                }
            }
        }
    }

    updatePassportData(data: any) {
        if (this.passport) {
            if (data.type === 'skin') {
                if (data.value) {
                    this.passport.skin = data.value;
                }
                this.passport.parentSkin = data.parentSkin;
            } else if (data.type === 'pathology') {
                this.passport.pathology = data.value;
            } else if (data.type === 'age') {
                this.passport.age = data.value;
            } else if (data.type === 'job') {
                this.passport.job = data.value;
            } else if (data.type === 'height') {
                this.passport.height[data.lang] = data.value;
            } else if (data.type === 'weight') {
                this.passport.weight[data.lang] = data.value;
            }
        }
    }

    updateJobs(data: any) {
        if (this.jobs) {
            if (data.type === 'insertJob') {
                this.jobs.push(data.value);
            } else {
                const idx = this.jobs.findIndex((el: Jobs) => el._id === data.elemId);
                if (idx !== -1) {
                    if (data.type === 'changeJob') {
                        this.jobs[idx].name[data.lang] = data.value;
                    } else if (data.type === 'deleteJob') {
                        this.jobs.splice(idx, 1);
                    }
                }
            }
        }

        if (data.type === 'deleteJob' && this.passport && this.passport.job === data.elemId) {
            this.passport.job = data.value;
        }
    }

    updateMedicineDataFromCommon(data: any) {
        if (this.treatment) {
            const idx = this.treatment.medicines.findIndex((el: Medicines) => el.medicine._id === data.medicineId);
            if (idx !== -1) {
                if (data.type === 'delete') {
                    this.treatment.medicines.splice(idx, 1);
                }
            }
        }
    }

    updateTreatmentDataFromCommon(data: any) {
        if (data.type === 'change') {
            // @ts-ignore
            if (this[data.tabName]) {
                // @ts-ignore
                const idx = this[data.tabName].findIndex((el: ObjectIdAndName) => el._id === data.elemId);
                if (idx !== -1) {
                    // @ts-ignore
                    this[data.tabName][idx].name[data.lang] = data.value;
                }
            }
        } else if (data.type === 'insert' && (data.tabName === 'hospitalization' || data.tabName === 'diet')) {
            // @ts-ignore
            if (this[data.tabName]) {
                // @ts-ignore
                this[data.tabName].push(data.value);
            }
        }
    }

    updateTreatmentMedication(data: any) {
        if (this.treatment && this.treatment._id === data.treatmentId) {
            if (data.type === 'insert') {
                this.treatment.medication.push(data.value);
                if (data.rightMedication !== undefined) {
                    this.treatment.rightMedication = data.rightMedication;
                }
            } else if (data.type === 'rightMedication') {
                this.treatment.rightMedication = data.value;
            } else {
                const idx = this.treatment.medication.findIndex((el: ObjectIdAndName) => el._id === data.elemId);
                if (idx !== -1) {
                    if (data.type === 'delete') {
                        this.treatment.medication.splice(idx, 1);
                        if (data.rightMedication !== undefined) {
                            this.treatment.rightMedication = data.rightMedication;
                        }
                    } else if (data.type === 'name') {
                        this.treatment.medication[idx].name[data.lang] = data.value;
                    }
                }
            }
        }
    }

    updateTreatmentData(data: any) {
        if (this.treatment && this.treatment._id === data.treatmentId) {
            if (data.type === 'hospitalization') {
                this.treatment.hospitalization._id = data.value;
            } else if (data.type === 'changeDiet') {
                const idx = this.treatment.diet.findIndex((el: CustomObject) => el._id === data.dietId);
                if (idx !== -1) {
                    this.treatment.diet[idx]._id = data.value;
                }
            } else if (data.type === 'deleteDiet') {
                const idx = this.treatment.diet.findIndex((el: CustomObject) => el._id === data.dietId);
                if (idx !== -1) {
                    this.treatment.diet.splice(idx, 1);
                }
            } else if (data.type === 'addDiet') {
                this.treatment.diet.push(data.value);
            } else if (data.type === 'score') {
                if (data.field === 'hospitalization') {
                    this.treatment.hospitalization.score.added = data.value;
                } else if (data.field === 'diet') {
                    const idx = this.treatment.diet.findIndex((el: CustomObject) => el._id === data.elemId);
                    if (idx !== -1) {
                        this.treatment.diet[idx].score.added = data.value;
                    }
                } else {
                    const idx = this.treatment.medicines.findIndex((el: Medicines) => el._id === data.elemId);
                    if (idx !== -1) {
                        this.treatment.medicines[idx][data.field].score.added = data.value;
                    }
                }
            } else if (data.type === 'addMedicine') {
                this.treatment.medicines.push(data.value);
            } else {
                const idx = this.treatment.medicines.findIndex((el: Medicines) => el._id === data.medicineId);
                if (idx !== -1) {
                    if (data.type === 'deleteMedicine') {
                        this.treatment.medicines.splice(idx, 1);
                    } else if (data.type === 'replacementMedicine') {
                        this.treatment.medicines[idx].replacementGroup = data.value;
                    } else if (data.type === 'optionMedicine') {
                        this.treatment.medicines[idx][data.field]._id = data.value;
                    }
                }
            }
        }
    }

    dialogueDragDrop({
                         dialogueId,
                         newIndex,
                         oldIndex,
                         dialogue
                     }: { dialogueId: string, newIndex: number, oldIndex: number, dialogue?: Dialogue[] }) {
        if (this.dialogue) {
            if (this.dialogue[oldIndex]._id === dialogueId) {
                this.dialogue.splice(newIndex, 0, this.dialogue.splice(oldIndex, 1)[0]);
            } /*else {
                const dialogueIdx = this.dialogue.findIndex((el: Dialogue) => el._id === dialogueId);
                if (dialogueIdx !== -1 && dialogueIdx !== newIndex) {
                    this.dialogue.splice(newIndex, 0, this.dialogue.splice(dialogueIdx, 1)[0]);
                }
            }*/

            if (dialogue) {
                for (let i = 0; i < dialogue.length; ++i) {
                    if (this.dialogue[i]._id === dialogue[i]._id) {
                        this.dialogue[i].condition = dialogue[i].condition;
                    } else {
                        const idx = this.dialogue.findIndex(el => el._id === dialogue[i]._id);
                        if (idx !== -1) {
                            this.dialogue[idx].condition = dialogue[i].condition;
                        }
                    }
                }
            }
        }
    }

    updateDialogueData(data: any) {
        if (this.dialogue) {
            if (data.type === 'insert') {
                this.dialogue.push(data.value);
            } else {
                const dialogueIdx = this.dialogue.findIndex((el: Dialogue) => el._id === data.dialogueId);
                if (dialogueIdx !== -1) {
                    if (data.type === 'text' || data.type === 'historyText' || data.type === 'question') {
                        this.dialogue[dialogueIdx][data.type][data.lang] = data.value;

                        if (data.type === 'text' && data.flagDeleteAudio) {
                            this.dialogue[dialogueIdx].sound[data.lang] = {_id: '', url: ''};
                        }
                    } else if (data.type === 'added') {
                        this.dialogue[dialogueIdx].score.added = data.value;
                    } else if (data.type === 'error') {
                        this.dialogue[dialogueIdx].errors[data.lang] = data.value;
                    } else if (data.type === 'audio') {
                        this.dialogue[dialogueIdx].sound[data.lang] = data.value;

                        if (data.text !== undefined) {
                            this.dialogue[dialogueIdx].text[data.lang] = data.text;
                        }
                    } else if (data.type === 'condition' || data.type === 'correctCategory') {
                        this.dialogue[dialogueIdx][data.type] = data.value;
                    } else if (data.type === 'delete') {
                        this.dialogue.splice(dialogueIdx, 1);
                    }
                }
            }
        }
    }

    updateInspectionData(data: any) {
        // @ts-ignore
        if (this[data.tabName]) {
            // @ts-ignore
            const idx = this[data.tabName].findIndex((el: any) => el._id === data.elemId);
            if (idx !== -1) {
                if (data.type === 'delete' || data.type === 'name' || data.type === 'image/flag') {
                    if (data.type === 'delete') {
                        // @ts-ignore
                        this[data.tabName].splice(idx, 1);
                    }
                    if (data.type === 'name') {
                        // @ts-ignore
                        this[data.tabName][idx].name[data.lang] = data.value;
                    }
                    if (data.type === 'image/flag') {
                        // @ts-ignore
                        this[data.tabName][idx].image = data.value;
                    }
                    // @ts-ignore
                } else if (this[data.tabName][idx].result._id === data.resultId) {
                    if (data.type === 'text') {
                        // @ts-ignore
                        this[data.tabName][idx].result.text[data.lang] = data.value;
                    } else if (data.type === 'added') {
                        // @ts-ignore
                        this[data.tabName][idx].result.score.added = data.value;
                    } else if (data.type === 'error') {
                        // @ts-ignore
                        this[data.tabName][idx].errors[data.lang][data.field] = data.value;
                    } else if (data.type === 'audio') {
                        // @ts-ignore
                        if (this[data.tabName][idx].result.sound) {
                            // @ts-ignore
                            this[data.tabName][idx].result.sound = data.value;
                        }
                    } else if (data.type === 'image') {
                        // @ts-ignore
                        this[data.tabName][idx].result.image = data.value;
                    } else if (data.type === 'pain') {
                        // @ts-ignore
                        this[data.tabName][idx].result.pain = data.value;
                    }
                }
            }
        }
    }

    updateExaminationData(data: any) {
        if (data.type === 'tabName') {
            if (this.examinationList) {
                const idx = this.examinationList.findIndex((el: ExaminationList) => el._id === data.elemId);
                if (idx !== -1) {
                    this.examinationList[idx].name[data.lang] = data.value;
                }
            }
        } else if (this.examination && this.examination[data.tabName]) {
            if (data.type === 'text' || data.type === 'conclusion') {
                if (this.examination[data.tabName][data.type]) {
                    this.examination[data.tabName][data.type][data.lang] = data.value;
                }
            } else if (data.type === 'added') {
                if (this.examination[data.tabName].score) {
                    this.examination[data.tabName].score[data.type] = data.value;
                }
            } else if (data.type === 'image') {
                if (this.examination[data.tabName].image) {
                    this.examination[data.tabName].image = data.value;
                }
            } else if (data.type === 'tableText') {
                if (this.examination[data.tabName].table) {
                    const rowIdx = this.examination[data.tabName].table.rows.findIndex((el: RowData) => el._id === data.rowId);
                    if (rowIdx !== -1) {
                        this.examination[data.tabName].table.rows[rowIdx].cells[data.cellIndex].text[data.lang] = data.value;
                    }
                }
            } else if (data.type === 'highlight') {
                if (this.examination[data.tabName].table) {
                    const rowIdx = this.examination[data.tabName].table.rows.findIndex((el: RowData) => el._id === data.rowId);
                    if (rowIdx !== -1) {
                        for (let i = 0; i < this.examination[data.tabName].table.rows[rowIdx].cells.length; ++i) {
                            this.examination[data.tabName].table.rows[rowIdx].cells[i].style.highlight = data.value;
                        }
                    }
                }
            } else if (data.type === 'deleteRow') {
                if (this.examination[data.tabName].table) {
                    const rowIdx = this.examination[data.tabName].table.rows.findIndex((el: RowData) => el._id === data.rowId);
                    if (rowIdx !== -1) {
                        this.examination[data.tabName].table.rows.splice(rowIdx, 1);
                    }
                }
            } else if (data.type === 'addRow' || data.type === 'addTitle') {
                if (this.examination[data.tabName].table) {
                    this.examination[data.tabName].table.rows.push(data.value);
                }
            } else if (data.type === 'error') {
                if (this.examination.errors) {
                    if (this.examination.errors[data.lang] && this.examination.errors[data.lang][data.tabName]) {
                        if (data.field === 'image') {
                            this.examination.errors[data.lang][data.tabName].image = data.value;
                        } else if (data.field === 'image_conclusion') {
                            this.examination.errors[data.lang][data.tabName].conclusion = data.value;
                        } else if (data.field === 'text_conclusion') {
                            this.examination.errors[data.lang][data.tabName] = data.value;
                        }
                    }
                }
            } else if (data.type === 'columnName') {
                if (this.examination[data.tabName].table) {
                    const columnIdx = this.examination[data.tabName].table.columns.findIndex((el: LangLocalization) => el[data.lang] === data.prevValue);
                    if (columnIdx !== -1) {
                        this.examination[data.tabName].table.columns[columnIdx][data.lang] = data.value;
                    }
                }
            } else if (data.type === 'deleteTab') {
                if (this.examinationList) {
                    const idx = this.examinationList.findIndex((el: ExaminationList) => el._id === data.elemId);
                    if (idx !== -1) {
                        this.examinationList.splice(idx, 1);
                    }
                }

                if (this.nameOpenExamTab === data.tabName) {
                    this.setNameOpenExamTab('');
                }

                delete this.examination[data.tabName];
            }
        }
    }

    //Установка данных перкуссии
    setPercussion(data: Array<PercussionPoint> | null) {
        this.percussion = data;
    }

    //Установка данных аскультации
    setAuscultation(auscultation: Array<AuscultationPoint> | null) {
        this.auscultation = auscultation;
    }

    //Установка данных пальпации
    setPalpation(palpation: Array<PalpationPoint> | null) {
        this.palpation = palpation;
    }

    //Установка данных визульного осмотра
    setVisual(visual: Array<VisualPoint> | null) {
        this.visual = visual;
    }

    //Установка данных инструментов
    setInstruments(instruments: Array<InstrumentsPoint> | null) {
        this.instruments = instruments;
    }

    //Установка данных списка доп обслд
    setExaminationList(data: Array<ExaminationList>) {
        this.examinationList = data;
    }

    //функция для отображения кейсов категории
    showSubMenu(id: string, bool?: boolean) {
        let el = document.getElementById('list_group_item_submenu_' + id);
        const list = document.getElementById('list-icons_' + id);
        if (el.style.display !== 'block') {
            setTimeout(() => el.style.display = 'block');
            if (this.showSub) {
                const div = document.getElementById('list_group_item_submenu_' + this.showSub);
                const arrow = document.getElementById('custom-arrow_' + this.showSub);
                if (div) div.style.display = 'none';
                if (arrow) arrow.style.transform = 'rotate(180deg)';
            }
            this.showSub = id;
            for (let i = 0; i < list.children.length; i++) {
                const el: any = list.children[i];
                if (el.id === 'custom-arrow_' + id) {
                    el.style.transform = 'rotate(270deg)';
                    break;
                }
            }
        } else if (!bool) {
            el.style.display = 'none';
            this.showSub = '';
            for (let i = 0; i < list.children.length; i++) {
                const el: any = list.children[i];
                if (el.id === 'custom-arrow_' + id) {
                    el.style.transform = 'rotate(180deg)';
                }
            }
        }
    }

    setCaseVerify(data: { caseId?: string; lang?: string }) {
        if (!this.subClasses || !this.subClasses.length) return;

        const changeVer = (idx: number) => {
            if (data.lang) {
                this.subClasses[idx].verify[data.lang] = false;
            } else {
                const obj: any = {};
                for (const lang in this.subClasses[idx].verify) {
                    obj[lang] = false;
                }
                this.subClasses[idx].verify = obj;
            }
        }

        if (data.caseId) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === data.caseId);
            if (idx !== -1) {
                changeVer(idx);
            }
        } else {
            for (let i = 0; this.subClasses.length; ++i) {
                changeVer(i);
            }
        }
    }

    updateCasesVerify({cases, lang}: { cases: string[], lang: string }) {
        if (!this.subClasses || !this.subClasses.length) return;

        for (let i = 0; i < this.subClasses.length; ++i) {
            if (cases.indexOf(this.subClasses[i]._id) !== -1) {
                this.subClasses[i].verify[lang] = true;
            }
        }
    }


    updateCaseVerify(data: any) {
        if (this.subClasses) {
            const idx = this.subClasses.findIndex((el: SubClasses) => el._id === data.caseId);
            if (idx !== -1) {
                this.subClasses[idx].verify[data.lang] = data.verify;
            }
        }
    }

    updateCaseError(data: any) {
        if (!this.subClasses) return;

        let idx = this.subClasses.findIndex((el: SubClasses) => el._id === data.caseId);
        if (idx === -1) return;
        if (!this.subClasses[idx].errors) return;

        for (const lang in data.caseError) {
            if (this.subClasses[idx].errors[lang]) {
                for (const key in data.caseError[lang]) {
                    if (key === 'examinationAll' || key === 'reviewAll') {
                        if (this.subClasses[idx].errors[lang][key]?.[data.tabName] !== undefined) this.subClasses[idx].errors[lang][key][data.tabName] += data.caseError[lang][key];
                    } else {
                        if (this.subClasses[idx].errors[lang][key] !== undefined) this.subClasses[idx].errors[lang][key] += data.caseError[lang][key];
                    }
                }
            }
        }

        idx = -1;
        if (this.selectedSubMenuId !== data.caseId || !data.type) return;

        if (data.type === 'dialogue') {
            if (!this.dialogue || !data.dialogueId) return;

            idx = this.dialogue.findIndex((el: Dialogue) => el._id === data.dialogueId);
            if (idx === -1) return;

            for (const lang in data.tabError) {
                if (this.dialogue[idx].errors[lang]) {
                    for (const key in data.tabError[lang]) {
                        if (this.dialogue[idx].errors[lang][key] !== undefined) this.dialogue[idx].errors[lang][key] = data.tabError[lang][key];
                    }
                }
            }
        } else if (data.type === 'examination') {
            if (!this.examination || !data.tabName) return;

            for (const lang in data.tabError) {
                if (this.examination.errors[lang]) {
                    for (const key in data.tabError[lang]) {
                        if (this.examination.errors[lang]?.[data.tabName]?.[key] !== undefined) this.examination.errors[lang][data.tabName][key] = data.tabError[lang][key];
                    }
                }
            }
        } else if (data.type === 'inspection') {
            // @ts-ignore
            if (!data.tabName || !this[data.tabName]) return;
            // @ts-ignore
            idx = this[data.tabName].findIndex((el: PercussionPoint | AuscultationPoint | PalpationPoint | VisualPoint | InstrumentsPoint) => el._id === data.elemId);
            if (idx === -1) return;

            for (const lang in data.tabError) {
                // @ts-ignore
                if (this[data.tabName][idx].errors[lang]) {
                    for (const key in data.tabError[lang]) {
                        // @ts-ignore
                        if (this[data.tabName][idx].errors[lang][key] !== undefined) this[data.tabName][idx].errors[lang][key] = data.tabError[lang][key];
                    }
                }
            }
        }
    }
}
import {$api} from "./index";
import {detectedError} from "../Utils";
import axios from "axios";

//Получение паков
export const getPack = async (userId?: string) => {
    try {
        let response;
        if (userId) {
            response = await $api.get('api/main/pack?userId=' + userId);
        } else {
            response = await $api.get('api/main/pack');
        }
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Создание пака
export const insertPack = (params: object) => {
    $api.post('api/main/pack', params).catch(ex => detectedError(ex));
}

export const insertNursingPack = () => {
    $api.post('api/nursing/pack').catch(ex => detectedError(ex));
}

export const getNursingPacks = async (userId?: string) => {
    try {
        const response = await $api.get('api/nursing/packs' + (userId ? `?userId=${userId}` : ''));
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteNursingPack = (packId: string) => {
    $api.delete(`api/nursing/pack?packId=${packId}`).catch(ex => detectedError(ex));
}

export const nursingPackUpdate = async (params: object) => {
    try {
        await $api.put('api/nursing/pack', params);
    } catch (ex) {
        return detectedError(ex);
    }
}

//Обновление пака
export const updatePack = async (params: object) => {
    try {
        await $api.put('api/main/pack', params);
    } catch (ex) {
        if (axios.isAxiosError(ex)) {
            return ex.response.data;
        }
    }
}

export const updatePackCase = (params: object) => {
    $api.put('api/main/pack/case', params).catch(ex => detectedError(ex));
}

//Удаление пака
export const deletePack = (id: string, std: string) => {
    $api.delete(`api/main/pack?packId=${id}&std=${std}`).catch(ex => detectedError(ex));
}

export const updateCasesVerifyInPack = (params: object) => {
    $api.put('api/main/case/verify/pack', params).catch(ex => detectedError(ex));
}

export const copyPackCases = (params: object) => {
    $api.put('api/main/pack/copy/cases', params).catch(ex => detectedError(ex));
}

//Проверку, что сервер работает
export const getCheck = async () => {
    try {
        await $api.get('api/check');
        return false;
    } catch (e: any) {
        return true;
    }
}

//Получение списка файлов по типу
export const getInfoFiles = async (params: object) => {
    try {
        const response = await $api.post('api/main/media/files', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение словаря
export const updateVocabulary = (params: object) => {
    $api.put('api/main/vocabulary', params).catch(ex => detectedError(ex));
}

//Получение списка логов
export const getLogs = async (params: object) => {
    try {
        const response = await $api.post('api/main/log', params);
        return {data: response.data.data, total: response.headers['x-documents-limit']};
    } catch (ex) {
        detectedError(ex);
    }
}

//автокомплит для фильтра логов
export const logAutocomplete = async (params: object) => {
    try {
        const response = await $api.post('api/main/log/autocomplete', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Откат изменений
export const updateLogs = (params: object) => {
    $api.put('api/main/log', params).catch(ex => detectedError(ex));
}

// Обновление описание файла
export const updateFileDesc = (params: object) => {
    $api.put('api/main/media/description', params).catch(ex => detectedError(ex));
}

//отправить письмо с ошибкой разработчикам
export const sendErrorEmail = async (params: object) => {
    try {
        await $api.post('api/main/error', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение перевода
export const exportProfileLang = async (params: object) => {
    try {
        const response = await $api.post('api/main/export/xlsx', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Отправка перевода
export const importProfileLang = async (params: object) => {
    try {
        return await $api.post('api/main/import/xlsx', params, {
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    } catch (ex) {
        detectedError(ex);
    }
}

export const exportVocabulary = async (params: object) => {
    try {
        return await $api.post('api/main/vocabulary/export', params);
    } catch (ex) {
        detectedError(ex);
    }
}

export const importVocabulary = async (params: object) => {
    try {
        return await $api.post('api/main/vocabulary/import', params, {headers: {'Content-Type': 'multipart/form-data'}});
    } catch (ex) {
        detectedError(ex);
    }
}

export const searchPhrase = async (params: object) => {
    try {
        const response = await $api.post('api/main/search/phrase', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateCategoryText = async (params: object) => {
    try {
        const response = await $api.put('api/main/case/category/text', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateProfileCategory = async (params: object) => {
    try {
        const response = await $api.put('api/main/pack/category', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}
import React, {useContext, useEffect, useState} from "react";
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from "./components/router/AppRouter";
import {check} from "./http/user";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {Spinner} from "react-bootstrap";
import useSocket from "./hooks/useSocket";
import {getCheck} from "./http/core";
import {updateApi} from "./http";
import ModalAccept from "./components/UI/Modal/ModalAccept";
import ModalLoadingProcess from "./components/UI/Modal/Outpatient/ModalLoadingProcess";
import ContextMenu from "./components/UX/ContextMenu/ContextMenu";
import ModalImageFullScreen from "./components/UI/Modal/ModalImageFullScreen";
import ModalTechnical from "./components/UI/Modal/ModalTechnical";
import ModalChooseFileUpload from "./components/UI/Modal/ModalChooseFileUpload/ModalChooseFileUpload";
import ModalError from "./components/UI/Modal/ModalError";

const App = observer(() => {
    const {user, core_store, userStore} = useContext(Context);
    const [loading, setLoading] = useState(true);
    //Вызываем хук для подключения сокета
    useSocket();

    useEffect(() => {
        if (user.auth) {
            const url = new URL(document.URL);
            if (url.searchParams.has('lang') && core_store.listLanguages.indexOf(url.searchParams.get('lang')) !== -1) {
                core_store.setLocalization(url.searchParams.get('lang'))
                localStorage.setItem('lang', core_store.localization);
            } else if (localStorage.getItem('lang') && core_store.listLanguages.indexOf(localStorage.getItem('lang')) !== -1) {
                core_store.setLocalization(localStorage.getItem('lang'));
                url.searchParams.set('lang', core_store.localization);
                window.history.replaceState({}, '', url);
            } else {
                url.searchParams.set('lang', core_store.localization);
                window.history.replaceState({}, '', url);
                localStorage.setItem('lang', core_store.localization);
            }
        }
        // eslint-disable-next-line
    }, [core_store.listLanguages]);

    useEffect(() => {
        //Проверка работает ли сервер
        getCheck().then(r => {
            core_store.setModalTechnical(r);
        });

        check().then(res => {
            if (res && res.user && res.user.languages && res.user._id && res.user.role && res.user.email) {
                const std = localStorage.getItem('std');
                if (std) {
                    updateApi(std);
                } else {
                    if (res.user.std) {
                        if (res.user.std['original']) {
                            updateApi('original');
                        } else {
                            for (const db in res.user.std) {
                                if (res.user.std[db]) {
                                    updateApi(db);
                                    break;
                                }
                            }
                        }
                    }
                }

                user.setCurUser(res.user);
                user.setEmail(res.user.email);
                core_store.setLanguages(res.user);
                user.changeAuth(res.user.role, res.user._id);
                userStore.changeAuth(res.user.packId, res.user.nursingPackId);

                if (res.role !== 'admin' && res.role !== 'user' && res.role !== 'client') {
                    if (core_store.listLanguages.indexOf(res.role) !== -1) {
                        core_store.setLocalization(res.role);
                    }
                }
            } else {
                user.logOut();
            }
        }).finally(() => {
            setLoading(false);
        });

        //При изменении размера экрана
        window.onresize = (e: any) => {
            if (e.currentTarget.innerWidth > 600 && e.currentTarget.innerWidth < 1400 && !core_store.tabMode) {
                core_store.changeTabMode(true);
                if (core_store.mobileMode) core_store.changeMobileMode(false);
            } else if (e.currentTarget.innerWidth < 600 && !core_store.mobileMode) {
                core_store.changeMobileMode(true);
                if (core_store.tabMode) core_store.changeTabMode(false);
            } else if (e.currentTarget.innerWidth > 1400) {
                if (core_store.mobileMode) core_store.changeMobileMode(false);
                if (core_store.tabMode) core_store.changeTabMode(false);
            }
        }

        if (window.innerWidth > 600 && window.innerWidth < 1400 && !core_store.tabMode) {
            core_store.changeTabMode(true);
            if (core_store.mobileMode) core_store.changeMobileMode(false);
        } else if (window.innerWidth < 600 && !core_store.mobileMode) {
            core_store.changeMobileMode(true);
            if (core_store.tabMode) core_store.changeTabMode(false);
        } else if (window.innerWidth > 1400) {
            if (core_store.mobileMode) core_store.changeMobileMode(false);
            if (core_store.tabMode) core_store.changeTabMode(false);
        }
        // eslint-disable-next-line
    }, []);

    if (core_store.modalTechnical) return <ModalTechnical/>
    if (loading) return <Spinner animation="border" variant="primary"/>

    return (
        <>
            <AppRouter/>
            <ModalAccept/>
            <ModalError/>
            <ModalLoadingProcess/>
            <ContextMenu/>
            <ModalImageFullScreen/>
            {core_store.fileParams?.show &&
                <ModalChooseFileUpload/>
            }
        </>
    );
})

export default App;
import {makeAutoObservable} from 'mobx';
import {UsersInterface} from '../interface';

export default class UserStore {
    wsId: string;
    //флаг авторизации
    auth: boolean = false;
    //роль пользователя
    role: string;
    //Почта текущего пользователя
    email: string
    //id пользователя
    id: string
    //данные по всем пользователям
    users: Array<UsersInterface> | null
    //текущий пользователь
    curUser: UsersInterface
    //модалка редактирования пользователя
    editUser: UsersInterface | null
    //список стандартов
    std: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    //Выход из пользвателя
    logOut() {
        this.auth = false;
        this.role = null;
        this.id = null;
        this.email = null;
        this.curUser = null;
        localStorage.clear();
    }

    setStd(data: string[]) {
        this.std = data;
    }

    setWS(data: string) {
        this.wsId = data;
    }

    //Изменение флага авторизации
    changeAuth(role: string, id: string) {
        this.auth = !this.auth;
        if (role && id) {
            this.role = role;
            this.id = id;
        }
    }

    //Установка данных по всем пользователям
    setUsers(data: Array<UsersInterface> | null) {
        this.users = data;
    }

    //Установка почты пользователя
    setEmail(data: string) {
        this.email = data;
    }

    //Установка данных текущему пользователю
    setCurUser(data: UsersInterface) {
        this.curUser = data;
    }

    setShowEditUser(data: UsersInterface | null) {
        this.editUser = data;
    }

    updateUser(data: any) {
        if (!this.users) return;

        const idxUser = this.users.findIndex((el: UsersInterface) => el._id === data.userId);
        if (idxUser === -1) return;

        switch (data.type) {
            case 'emailEdit':
                this.users[idxUser].email = data.newEmail;
                break;
            case 'nursingBanEdit':
            case 'banEdit':
                // @ts-ignore
                this.users[idxUser][data.type] = data.flag;
                break;
            case 'accessEdit':
                this.users[idxUser].access = data.access;
                break;
            case 'langEdit':
                // @ts-ignore
                this.users[idxUser][data.field] = data.languages;
                break;
            case 'packEdit':
                if (data.std === 'original' || data.std === 'international') {
                    this.users[idxUser].std = data.value;
                } else if (data.std === 'nursing') {
                    if (data.flag) {
                        this.users[idxUser].nursing.push(data.packId);
                    } else {
                        const idx = this.users[idxUser].nursing.indexOf(data.packId);
                        if (idx === -1) return;

                        this.users[idxUser].nursing.splice(idx, 1);
                    }
                }
                break;
            case 'deleteUser':
                this.users.splice(idxUser, 1);
                break;
            case 'info':
                // @ts-ignore
                this.users[idxUser][data.field] = data.value;
                break;
        }
    }
}


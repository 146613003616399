import {observer} from "mobx-react-lite";
import React, {useCallback, useContext, useRef, useState} from "react";
import {Button, FloatingLabel, Form, Modal} from "react-bootstrap";
import {lang} from "../../../../Utils/lang";
import {Context} from "../../../../index";
import styles from "./ModalChooseFileUpload.module.scss";
import {VARIANT_BUTTON} from "../../../../Utils";
import {
    insertAuscultationAudioExists,
    insertExaminationPhotoExists, insertInstrumentsImageExists,
    insertPercussionAudioExists, replaceFile, uploadFile
} from "../../../../http/Outpatient";
import FileList from "./FileList";
import {nursingUploadFile} from "../../../../http/Nursing";


const ModalChooseFileUpload = observer(() => {
    const {core_store, ambulant, nursingStore} = useContext(Context);
    const [showFile, setShowFile] = useState(core_store.fileParams.tab === 'dialogue' || core_store.fileParams.collection === 'investigation_image' || core_store.fileParams.collection === 'case_data_image' || core_store.fileParams.collection === 'case_flowchart' || core_store.fileParams.collection === 'nursing_dialogue_audio');
    const [fileLoader, setFileLoader] = useState<any>(null);
    const [showError, setShowError] = useState<boolean>(false);
    const listFilesRef = useRef<string>('');
    const urlFileRef = useRef<string>('');

    const descriptionRef = useRef(null);

    const onHide = () => {
        core_store.setFileParams(null);
        core_store.setListFiles([]);
    }

    const handleShowFileUpload = () => {
        urlFileRef.current = '';
        setShowFile(!showFile);
    }

    const loadExistsFile = () => {
        if (listFilesRef.current) {
            core_store.setLoading(true);
            switch (core_store.fileParams.tab) {
                /*case 'dialogue':
                    insertAudioExists({
                        answerId: core_store.fileParams.info.answerId,
                        dialogueId: core_store.fileParams.info.dialogueId,
                        id: listFilesRef.current,
                        lang: core_store.localization,
                        caseId: ambulant.selectedSubMenuId,
                        index: core_store.fileParams.info.index
                    });
                    break;*/
                case 'instruments':
                    insertInstrumentsImageExists({
                        resultId: core_store.fileParams.info.resultId,
                        instrumentsId: core_store.fileParams.info.instrumentsId,
                        id: listFilesRef.current,
                        caseId: ambulant.selectedSubMenuId,
                        verifyFlag: core_store.fileParams.info.verifyFlag
                    });
                    break;
                case 'percussion':
                    insertPercussionAudioExists({
                        resultId: core_store.fileParams.info.resultId,
                        percussionId: core_store.fileParams.info.percussionId,
                        id: listFilesRef.current,
                        caseId: ambulant.selectedSubMenuId,
                        lang: core_store.localization,
                        index: core_store.fileParams.info.index,
                        verifyFlag: core_store.fileParams.info.verifyFlag
                    });
                    break;
                case 'auscultation':
                    insertAuscultationAudioExists({
                        resultId: core_store.fileParams.info.resultId,
                        auscultationId: core_store.fileParams.info.auscultationId,
                        id: listFilesRef.current,
                        caseId: ambulant.selectedSubMenuId,
                        lang: core_store.localization,
                        index: core_store.fileParams.info.index,
                        verifyFlag: core_store.fileParams.info.verifyFlag
                    });
                    break;
                case 'examination':
                    insertExaminationPhotoExists({
                        tab: core_store.fileParams.info.tabName,
                        id: listFilesRef.current,
                        caseId: ambulant.selectedSubMenuId,
                        verifyFlag: core_store.fileParams.info.verifyFlag
                    });
                    break;
            }
            onHide();
        }
    }

    const replaceFileUpload = async () => {
        const file = fileLoader;
        const description = descriptionRef.current.value.trim();

        if (!file || !description) {
            return;
        }
        const formData: any = new FormData();
        formData.append(core_store.fileParams.type, file);
        formData.append('description', description);
        formData.append('type', core_store.fileParams.type);
        formData.append('collection', core_store.fileParams.collection);
        formData.append('id', listFilesRef.current);
        formData.append('url', urlFileRef.current);

        core_store.setLoading(true);

        const res = await replaceFile(formData);
        if (res && res === 'Unsupported file format') {
            if (fileLoader) setFileLoader(null);
            return setShowError(true);
        }
        onHide();
    }

    const handleLoadFile = async () => {
        const file = fileLoader;

        let description = descriptionRef.current?.value?.trim();

        if (core_store.fileParams.collection === 'case_flowchart') {
            description = nursingStore.selectedCase._id;
        }

        if (!file || !description) {
            return;
        }


        const simulator = core_store.fileParams.collection === 'investigation_image' || core_store.fileParams.collection === 'case_data_image' || core_store.fileParams.collection === 'case_flowchart' || core_store.fileParams.collection === 'nursing_dialogue_audio' ? 'nursing' : 'ambulant';

        const caseId = simulator === 'nursing' ? core_store.fileParams.collection === 'nursing_dialogue_audio' ? core_store.fileParams.info.caseId : nursingStore.selectedCase._id : ambulant.selectedSubMenuId;

        const formData: any = new FormData();
        formData.append(core_store.fileParams.type, file);
        formData.append('description', description);
        formData.append('lang', core_store.localization);
        formData.append('caseId', caseId);
        formData.append('type', core_store.fileParams.type);
        formData.append('collection', core_store.fileParams.collection);
        formData.append('verifyFlag', core_store.fileParams.info.verifyFlag);

        if (simulator === 'ambulant') {
            switch (core_store.fileParams.tab) {
                case 'dialogue':
                    formData.append('tab', 'dialogue');
                    formData.append('dialogueId', core_store.fileParams.info.dialogueId);
                    formData.append('index', core_store.fileParams.info.index);
                    break;
                case 'percussion':
                    formData.append('tab', 'percussion');
                    formData.append('resultId', core_store.fileParams.info.resultId);
                    formData.append('percussionId', core_store.fileParams.info.percussionId);
                    formData.append('index', core_store.fileParams.info.index);
                    break;
                case 'auscultation':
                    formData.append('tab', 'auscultation');
                    formData.append('resultId', core_store.fileParams.info.resultId);
                    formData.append('auscultationId', core_store.fileParams.info.auscultationId);
                    formData.append('index', core_store.fileParams.info.index);
                    break;
                case 'instruments':
                    formData.append('tab', 'instruments');
                    formData.append('resultId', core_store.fileParams.info.resultId);
                    formData.append('instrumentsId', core_store.fileParams.info.instrumentsId);
                    break;
                case 'examination':
                    formData.append('tab', 'examination');
                    formData.append('examTab', core_store.fileParams.info.tabName);
                    break;
            }
        } else {
            switch (core_store.fileParams.collection) {
                case 'investigation_image':
                    formData.append('tab', 'investigation');
                    formData.append('tabId', core_store.fileParams.info.investigationId);
                    formData.append('index', core_store.fileParams.info.index);
                    break;
                case 'nursing_dialogue_audio':
                    formData.append('tab', 'dialogue');
                    formData.append('tabId', core_store.fileParams.info.dialogueId);
                    formData.append('index', core_store.fileParams.info.index);
                    break;
                case 'case_data_image':
                    formData.append('tab', 'case_data');
                    formData.append('tabId', core_store.fileParams.info.caseDataId);
                    break;
                case 'case_flowchart':
                    formData.append('tab', 'case');
                    formData.append('tabId', core_store.fileParams.info.caseId);
                    break;
            }
        }


        core_store.setLoading(true);

        let res;
        if (simulator === 'nursing') {
            res = await nursingUploadFile(formData);
        } else {
            res = await uploadFile(formData);
        }

        if (res && res === 'Unsupported file format') {
            if (fileLoader) setFileLoader(null);
            return setShowError(true);
        }
        onHide();
    }


    const checkValidFile = (e: any) => {
        const file = e.currentTarget.files[0];
        if (!file) {
            if (fileLoader) setFileLoader(null);
            setShowError(true);
        }

        let flag = false;

        if (core_store.fileParams.type === 'image') {
            const allowedTypes = ['image/png', 'image/jpeg'];

            flag = allowedTypes.includes(file.type);
        } else if (core_store.fileParams.type === 'audio') {
            flag = file.type.indexOf('audio') !== -1;
        } else if (core_store.fileParams.type === 'pdf') {
            flag = file.type === 'application/pdf';
        }

        if (flag) {
            if (showError) setShowError(false);
            setFileLoader(file);
        } else {
            if (fileLoader) setFileLoader(null);
            setShowError(true);
        }
    }

    const handleShowFiles = useCallback((e: any) => {
        e.currentTarget.parentNode.firstChild.click();
    }, []);

    return (
        <Modal
            show={true}
            onHide={onHide}
            size={"lg"}
            aria-labelledby={"contained-modal-title-vcenter"}
            centered
            fullscreen={core_store.tabMode || core_store.mobileMode}
        >
            <Modal.Header
                closeButton
            >
                <Modal.Title>
                    {lang.info.outpatient_111[core_store.localization]}
                </Modal.Title>
            </Modal.Header>
            <div>
                {core_store.fileParams.tab === 'dialogue' || core_store.fileParams.collection === 'investigation_image' || core_store.fileParams.collection === 'case_data_image' || core_store.fileParams.collection === 'case_flowchart' || core_store.fileParams.collection === 'nursing_dialogue_audio'
                    ? <></>
                    :
                    <div>
                        <Button
                            className={styles.btnLoadNewFile}
                            variant={VARIANT_BUTTON}
                            onClick={handleShowFileUpload}
                        >
                            {showFile
                                ? lang.info.core_108[core_store.localization]
                                : lang.info.core_43[core_store.localization]
                            }
                        </Button>
                    </div>
                }
                {showFile
                    ?
                    <div
                        className={styles.fileUploadDiv}
                    >
                        {showError &&
                            <span className={styles.errorType}>{lang.info.core_120[core_store.localization]}</span>
                        }
                        <div className={styles.fileUpload}>
                            <input
                                accept={core_store.fileParams.type === 'image' ? '.png, .jpeg, .jpg' : core_store.fileParams.type === 'audio' ? 'audio/*' : 'application/pdf'}
                                onChange={checkValidFile}
                                type={'file'}
                                style={{display: 'none'}}
                            />
                            <div
                                className={styles.chooseFile}
                                onClick={handleShowFiles}
                            >
                                {lang.info.core_129[core_store.localization]}
                            </div>
                            <div
                                className={styles.divFile}
                                title={fileLoader ? fileLoader.name : ''}
                            >
                                {fileLoader ? fileLoader.name : lang.info.core_130[core_store.localization]}
                            </div>
                        </div>
                        {fileLoader && core_store.fileParams.collection !== 'case_flowchart'
                            ?
                            <>
                                <strong>{lang.info.core_117[core_store.localization]}</strong>
                                <FloatingLabel
                                    controlId="floatingDesc"
                                    label={lang.info.core_5[core_store.localization] + ' ' + fileLoader.name}
                                    className={styles.description}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder={'*'}
                                        ref={descriptionRef}
                                    />
                                </FloatingLabel>
                            </>
                            : null
                        }
                    </div>
                    : <FileList
                        listFilesRef={listFilesRef}
                        setShowFile={setShowFile}
                        urlFileRef={urlFileRef}
                    />
                }
            </div>
            <Modal.Footer>
                {showFile
                    ?
                    <Button
                        variant={VARIANT_BUTTON}
                        onClick={urlFileRef.current ? replaceFileUpload : handleLoadFile}
                    >
                        {lang.info.core_11[core_store.localization]}
                    </Button>
                    :
                    <Button
                        variant={VARIANT_BUTTON}
                        onClick={loadExistsFile}
                    >
                        {lang.info.core_29[core_store.localization]}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
});

export default ModalChooseFileUpload;
/* вся локализация сайта */
import {LangLocalization} from "../interface";

export const lang: Lang = {
    errors: {
        core_1: {
            ru: 'Картинка отсутствует.',
            en: 'No picture',
            tr: 'Hiçbir resmi',
            es: 'No imágenes',
            ar: 'لا توجد صورة',
            de: 'Bild fehlt.',
            pt: 'Imagem não encontrada.',
            fr: "L'image est manquante.",
            zh: '没有图片',
            ko: '그림 없음',
            ja: '画像がありません',
            th: 'ไม่มีรูปภาพ',
            he: 'אין תמונה',
            zh_tw: '無圖片',
            fil: 'Walang larawan',
            id: 'Tidak ada gambar'
        },
        core_2: {
            ru: 'В этом поле могут быть только цифры.',
            en: 'This field can only contain numbers.',
            tr: 'Bu alan yalnızca sayı içerebilir.',
            es: 'Este campo solo puede contener números.',
            ar: 'يمكن أن تحتوي هذه الخانة أرقام فقط.',
            de: 'In diesem Feld können nur Zahlen eingegeben werden.',
            pt: 'Este campo só pode conter números.',
            fr: 'Ce champ ne peut contenir que des chiffres.',
            zh: '此字段只能包含数字。',
            ko: '이 필드에는 숫자만 입력할 수 있습니다.',
            ja: 'このフィールドには数字のみを入力してください',
            th: 'ฟิลด์นี้สามารถรับเฉพาะตัวเลขได้เท่านั้น',
            he: 'השדה יכול להכיל רק מספרים.',
            zh_tw: '此欄位只能包含數字。',
            fil: 'Ang patlang na ito ay maaaring maglaman lamang ng mga numero.',
            id: 'Bagian ini hanya bisa diisi angka.'
        },
        core_3: {
            ru: 'Значение может быть от {MIN} до {MAX}',
            en: 'Value can be from {MIN} to {MAX}',
            tr: 'Değer {MIN} ile {MAX} arasında olabilir',
            es: 'El valor puede ser de {MIN} a {MAX}',
            ar: 'يمكن أن تتراوح القيمة من {MIN} إلى {MAX}',
            de: 'Der Wert kann von {MIN} bis {MAX} sein.',
            pt: 'O valor pode ser de {MIN} até {MAX}.',
            fr: 'La valeur peut être comprise entre {MIN} et {MAX}.',
            zh: '值可以从{MIN}到{MAX}。',
            ko: '값은 {MIN}부터 {MAX}까지 가능합니다.',
            ja: '値は{MIN}から{MAX}までの範囲内である必要があります',
            th: 'ค่าสามารถเป็นตั้งแต่ {MIN} ถึง {MAX}',
            he: 'הערך יכול להיות בין {MIN} ל-{MAX}',
            zh_tw: '值可以從 {MIN} 到 {MAX}',
            fil: 'Ang halaga ay maaaring mula {MIN} hanggang {MAX}',
            id: 'Nilai dapat diisi dari {MIN} hingga {MAX}'
        },
        core_4: {
            ru: 'Что-то пошло не так.',
            en: 'Something went wrong.',
            tr: 'Bir şeyler yanlış gitti.',
            es: 'Algo salió mal.',
            ar: 'هناك خطأ ما.',
            de: 'Etwas ist schiefgelaufen.',
            pt: 'Algo deu errado.',
            fr: "Quelque chose s'est mal passé.",
            zh: '出了点问题。',
            ko: '문제가 발생했습니다.',
            ja: '問題が発生しました',
            th: 'มีบางอย่างผิดพลาด',
            he: 'משהו השתבש.',
            zh_tw: '出了些問題。',
            fil: 'May nangyaring error.',
            id: 'Terdapat gangguan'
        },
        core_5: {
            ru: 'Перезагрузить страницу.',
            en: 'Reload page.',
            tr: 'SAYFAYI yeniden yuklemek.',
            es: 'Vuelve a cargar la página.',
            ar: 'إعادة تحميل الصفحة.',
            de: 'Seite neu laden.',
            pt: 'Recarregue a página.',
            fr: 'Recharger la page.',
            zh: '重新加载页面。',
            ko: '페이지 새로 고침.',
            ja: 'ページをリロードしてください',
            th: 'โหลดหน้าใหม่',
            he: 'טען מחדש את העמוד.',
            zh_tw: '重新載入頁面。',
            fil: 'I-refresh ang pahina.',
            id: 'Muat ulang laman ini.'
        },
        core_6: {
            ru: 'Поле не может быть пустым.',
            en: 'The field cannot be empty.',
            tr: 'Alan boş olamaz.',
            es: 'El campo no puede estar vacío.',
            ar: 'لا يمكن أن تكون هذه الخانة فارغةٌ.',
            de: 'Das Feld darf nicht leer sein.',
            pt: 'Este campo não pode ficar vazio.',
            fr: 'Le champ ne peut pas être vide.',
            zh: '该字段不能为空。',
            ko: '이 필드는 비워둘 수 없습니다.',
            ja: 'フィールドは空にできません',
            th: 'ฟิลด์นี้ไม่สามารถเว้นว่างได้',
            he: 'השדה אינו יכול להיות ריק.',
            zh_tw: '欄位不能為空。',
            fil: 'Hindi maaaring walang laman ang patlang.',
            id: 'Bagian ini tidak boleh kosong.'
        },
        core_7: {
            ru: 'Ошибка сохранения данных.',
            en: 'Data saving error.',
            tr: 'Veri kaydetme hatası.',
            es: 'Error al guardar datos.',
            ar: 'خطأ في حفظ البيانات.',
            de: 'Fehler beim Speichern von Daten.',
            pt: 'Erro ao salvar dados.',
            fr: 'Erreur lors de la sauvegarde des données.',
            zh: '数据保存错误。',
            ko: '데이터 저장 오류.',
            ja: 'データの保存エラー',
            th: 'เกิดข้อผิดพลาดในการบันทึกข้อมูล',
            he: 'שגיאה בשמירת הנתונים.',
            zh_tw: '數據保存錯誤。',
            fil: 'Error sa pag-save ng data.',
            id: 'Terdapat gangguan penyimpanan data.'
        },
        core_8: {
            ru: 'Ошибка',
            en: 'Error',
            tr: 'Hata',
            es: 'Error',
            ar: 'خطأ',
            de: 'Fehler',
            pt: 'Erro.',
            fr: 'Erreur.',
            zh: '错误',
            ko: '오류',
            ja: 'エラー',
            th: 'ข้อผิดพลาด',
            he: 'שגיאה',
            zh_tw: '錯誤',
            fil: 'Error',
            id: 'Terdapat gangguan'
        },
        core_9: {
            ru: 'Нужно отметить хотя бы один кейс для данного профиля',
            en: 'You must mark at least one case for this profile',
            tr: 'Bu profil için en az bir vakayı işaretlemelisiniz',
            es: 'Debes marcar al menos un caso para este perfil',
            ar: 'يجب وضع علامة على حالة واحدة على الأقل لهذا الملف الشخصي',
            de: 'Es muss mindestens ein Fall für dieses Profil markiert werden.',
            pt: 'Pelo menos um caso deve ser selecionado para este perfil.',
            fr: 'Au moins un cas doit être sélectionné pour ce profil.',
            zh: '您必须标记至少一个档案才能使用此配置文件。',
            ko: '프로필에는 최소한 하나의 사례를 표시해야 합니다.',
            ja: '少なくとも1つのケースにチェックを入れる必要があります',
            th: 'คุณต้องทำเคสอย่างน้อยหนึ่งรายการสำหรับโปรไฟล์นี้',
            he: 'עליך לסמן לפחות מקרה אחד עבור הפרופיל הזה',
            zh_tw: '您必須為此資料檔案標記至少一個案例',
            fil: 'Kailangan mong markahan ang hindi bababa sa isang kaso para sa profil na ito',
            id: 'Anda harus menandai minimal satu kasus untuk profil ini'
        },
        core_10: {
            ru: 'Сервер недоступен, мы его лечим)',
            en: 'The server is unavailable, we are treating it)',
            tr: 'Sunucu kullanılamıyor, tedavi ediyoruz',
            es: 'El servidor no está disponible, lo estamos solucionando)',
            ar: 'الخادم غير متوفر ، نحن نتعامل معه)',
            de: 'Der Server ist nicht verfügbar, wir arbeiten daran)',
            pt: 'Servidor indisponível, estamos trabalhando nisso.',
            fr: 'Le serveur est indisponible, nous le réparons).',
            zh: '服务器不可用，我们正在处理它）',
            ko: '서버를 사용할 수 없습니다. 처리 중입니다)',
            ja: 'サーバーが利用できません、',
            th: 'เซิร์ฟเวอร์ไม่สามารถใช้งานได้ เรากำลังดำเนินการแก้ไข',
            he: 'השרת אינו זמין, אנו טופלים בזה :)',
            zh_tw: '伺服器不可用，我們正在處理）',
            fil: 'Ang server ay hindi available, binibigyan namin ito ng pansin)',
            id: 'Server tidak tersedia, kami sedang memperbaikinya'
        },
        core_11: {
            ru: 'Попробуйте зайти чуть позже.',
            en: 'Try to come back a little later.',
            tr: 'Biraz sonra geri gelmeye çalışın.',
            es: 'Inténtalo un poco más tarde.',
            ar: 'من فضلك حاول الدخول لاحقا',
            de: 'Versuchen Sie es später noch einmal.',
            pt: 'Tente novamente mais tarde.',
            fr: 'Veuillez réessayer plus tard.',
            zh: '稍后再试。',
            ko: '잠시 후에 다시 시도해보세요.',
            ja: 'しばらくしてから再度お試しください',
            th: 'ลองกลับมาใหม่ในอีกสักครู่',
            he: 'נסה לחזור מאוחר יותר.',
            zh_tw: '請稍後再回來。',
            fil: 'Subukan bumalik ng kaunti mamaya.',
            id: 'Coba kembali beberapa saat lagi.'
        },
        core_12: {
            ru: 'Цифры должны быть больше 0.',
            en: 'Numbers must be greater than 0.',
            tr: 'Sayılar 0"dan büyük olmalıdır.',
            es: 'Los números deben ser mayores que 0.',
            ar: 'يجب أن تكون الأرقام أكبر من 0.',
            de: 'Zahlen müssen größer als 0 sein.',
            pt: 'Os números devem ser maiores que 0.',
            fr: 'Les nombres doivent être supérieurs à 0.',
            zh: '数字必须大于0。',
            ko: '숫자는 0보다 커야 합니다.',
            ja: '数字は0より大きくする必要があります',
            th: 'ตัวเลขต้องมีค่ามากกว่า 0',
            he: 'המספרים חייבים להיות גדולים מ-0.',
            zh_tw: '數字必須大於0。',
            fil: 'Ang mga numero ay dapat na mas malaki sa 0.',
            id: 'Angka harus lebih besar dari 0.'
        },
        core_13: {
            ru: 'Профиль с таким названием уже создан.',
            en: 'Profile with this name already created.',
            tr: 'Bu ada sahip profil zaten oluşturuldu.',
            es: 'Perfil con este nombre ya creado.',
            ar: 'الملف الشخصي مع هذا الاسم تم إنشاؤه بالفعل.',
            de: 'Ein Profil mit diesem Namen wurde bereits erstellt.',
            pt: 'Perfil com este nome já criado.',
            fr: 'Profil avec ce nom déjà créé.',
            zh: '此名称的配置文件已创建。',
            ko: '이름이 같은 프로필이 이미 만들어졌습니다.',
            ja: 'この名前のプロファイルは既に作成されています',
            th: 'โปรไฟล์ที่ใช้ชื่อนี้ถูกสร้างแล้ว',
            he: 'פרופיל עם השם הזה כבר נוצר.',
            zh_tw: '已創建具有此名稱的資料檔案。',
            fil: 'Ang profil na may ganitong pangalan ay mayroon nang nilikha.',
            id: 'Profil dengan nama ini sudah dibuat.'
        },
        core_14: {
            ru: 'Нет изменений.',
            en: 'There are no changes.',
            tr: 'Değişiklik yok.',
            es: 'No hay cambios.',
            ar: 'لا توجد تغييرات.',
            de: 'Keine Änderungen.',
            pt: 'Nenhuma mudança foi feita.',
            fr: 'Aucune modification.',
            zh: '未做任何更改',
            ko: '변경 사항이 없습니다.',
            ja: '変更はありません',
            th: 'ไม่มีการเปลี่ยนแปลง',
            he: 'אין שינויים.',
            zh_tw: '沒有更改。',
            fil: 'Walang mga pagbabago.',
            id: 'Tidak terdapat perubahan.'
        },
        core_15: {
            ru: 'Поле не может содержать слово Admin.',
            en: 'The field cannot be repeated with the word Admin.',
            tr: 'Alan, Admin kelimesiyle tekrarlanamaz.',
            es: 'El campo no se puede repetir con la palabra Admin.',
            ar: 'لا يمكن تكرار الحقل بكلمة Admin.',
            de: 'Das Feld darf das Wort Admin nicht enthalten.',
            pt: 'Este campo não pode conter a palavra Admin.',
            fr: 'Le champ ne peut pas contenir le mot Admin.',
            zh: '该字段不能包含重复的单词“Admin”',
            ko: "단어 'Admin'으로 필드를 반복해서 사용할 수 없습니다.",
            ja: 'フィールドは「Admin」という単語と重複することはできません',
            th: 'ฟิลด์นี้ไม่สามารถทำซ้ำกับคำว่า Admin ได้',
            he: 'השדה אינו יכול לחזור על עצמו עם המילה אדמין.',
            zh_tw: '欄位不能與Admin重複。',
            fil: 'Hindi maaaring ulitin ang patlang na may salitang Admin.',
            id: 'Bagian ini tidak dapat diulangi dengan kata Admin.'
        },
        core_16: {
            ru: 'Отправить ошибку разработчикам.',
            en: 'Send the error to the developers.',
            tr: 'Hatayı geliştiricilere gönderin.',
            es: 'Envía el error a los desarrolladores.',
            ar: 'إرسال الخطأ إلى المطورين.',
            de: 'Fehler an Entwickler senden.',
            pt: 'Enviar erro para os desenvolvedores.',
            fr: 'Envoyer une erreur aux développeurs.',
            zh: '将错误发送给开发人员。',
            ko: '오류를 개발자에게 보내세요.',
            ja: 'エラーを開発者に送信してください',
            th: 'ส่งข้อผิดพลาดให้กับผู้พัฒนา',
            he: 'שלח את השגיאה למפתחים.',
            zh_tw: '將錯誤發送給開發者。',
            fil: 'Ipadala ang error sa mga developer.',
            id: 'Kirim gangguan/eror ke developer.'
        },
        core_17: {
            ru: 'Пользователь с таким адресом электронной почты уже существует.',
            en: 'A user with this email already exists.',
            tr: 'Bu e-postaya sahip bir kullanıcı zaten var.',
            es: 'Ya existe un usuario con este correo electrónico.',
            ar: 'يوجد بالفعل مستخدم بهذا البريد الإلكتروني.',
            de: 'Ein Benutzer mit dieser E-Mail-Adresse existiert bereits.',
            pt: 'Já existe um usuário com este endereço de e-mail.',
            fr: 'Un utilisateur avec cette adresse e-mail existe déjà.',
            zh: '该电子邮件的用户已经存在。',
            ko: '이 이메일을 사용하는 사용자가 이미 존재합니다.',
            ja: 'このメールアドレスのユーザーは既に存在します',
            th: 'มีผู้ใช้ที่ใช้อีเมลนี้อยู่แล้ว',
            he: 'משתמש עם כתובת האימייל הזו כבר קיים.',
            zh_tw: '此電子郵件的用戶已存在。',
            fil: 'May user na may email na ito na umiiral na.',
            id: 'Pengguna dengan email ini sudah ada.'
        },
        core_18: {
            ru: 'Ошибка при загрузке файла.',
            en: 'Error loading file.',
            tr: 'Dosya yüklenirken hata oluştu.',
            es: 'Error al cargar el archivo.',
            ar: 'خطأ في تحميل الملف.',
            de: 'Fehler beim Hochladen der Datei.',
            pt: 'Erro ao carregar arquivo.',
            fr: 'Erreur lors du chargement du fichier.',
            zh: '加载文件时出错。',
            ko: '파일을 불러오는 중 오류가 발생했습니다.',
            ja: 'ファイルの読み込みエラー',
            th: 'เกิดข้อผิดพลาดในการโหลดไฟล์',
            he: 'שגיאה בטעינת הקובץ.',
            zh_tw: '文件加載錯誤。',
            fil: 'Error sa pag-load ng file.',
            id: 'Terdapat gangguan memuat file.'
        },
        core_19: {
            ru: 'Ошибка при загрузке данных',
            en: 'Error loading data.',
            tr: 'Veriler yüklenirken hata oluştu.',
            es: 'Error al cargar datos.',
            ar: 'خطأ في تحميل البيانات.',
            de: 'Fehler beim Laden der Daten.',
            pt: 'Erro ao carregar dados.',
            fr: 'Erreur lors du chargement des données.',
            zh: '加载数据时出错。',
            ko: '데이터를 불러오는 중 오류가 발생했습니다.',
            ja: 'データの読み込みエラー',
            th: 'เกิดข้อผิดพลาดในการโหลดข้อมูล',
            he: 'שגיאה בטעינת הנתונים.',
            zh_tw: '數據加載錯誤。',
            fil: 'Error sa pag-load ng data.',
            id: 'Terdapat gangguan memuat data.'
        },
        core_20: {
            ru: 'Кол-во кейсов не может превышать лимит.',
            en: 'The number cases cannot exceed the limit.',
            tr: 'Vaka sayısı sınırı aşamaz.',
            es: 'El número de casos no puede exceder el límite.',
            ar: 'عدد الحالات لا يمكن أن يتجاوز الحد.',
            de: 'Die Anzahl der Fälle darf den Grenzwert nicht überschreiten.',
            pt: 'O número de casos não pode exceder o limite.',
            fr: 'Le nombre de cas ne peut pas dépasser la limite.',
            zh: '案例数量不能超过限制。',
            ko: '사례 수가 제한을 초과할 수 없습니다.',
            ja: 'ケース数は制限を超えることはできません',
            th: 'จำนวนเคสไม่สามารถเกินขีดจำกัดได้',
            he: 'מספר המקרים לא יכול לחרוג מהמגבלה.',
            zh_tw: '案例數不能超過限制。',
            fil: 'Hindi maaaring lampasan ang limitasyon ng bilang ng mga kaso.',
            id: 'Jumlah kasus tidak boleh melebihi batas.'
        },
        core_21: {
            ru: 'У клиента может быть только один профиль.',
            en: 'A client can have only one profile and one case.',
            tr: 'Bir müşteri yalnızca bir profile sahip olabilir.',
            es: 'Un cliente puede tener solo un perfil.',
            ar: 'يمكن للعميل أن يكون لديه ملف تعريف واحد فقط.',
            de: 'Ein Kunde kann nur ein Profil haben.',
            pt: 'Cada cliente pode ter apenas um perfil.',
            fr: "Le client ne peut avoir qu'un seul profil.",
            zh: '客户只能拥有一个配置文件和一个案例。',
            ko: '고객은 하나의 프로필과 하나의 사례만 가질 수 있습니다.',
            ja: 'クライアントは1つのプロファイルと1つのケースしか持てません',
            th: 'ลูกค้าสามารถมีโปรไฟล์และเคสได้เพียงอย่างเดียว',
            he: 'לקוח יכול להיות עם פרופיל אחד בלבד ומקרה אחד.',
            zh_tw: '客戶只能有一個資料檔案和一個案例。',
            fil: 'Ang isang kliyente ay maaaring magkaroon lamang ng isang profil at isang kaso.',
            id: 'Klien hanya dapat memiliki satu profil dan satu kasus.'
        },
        core_22: {
            ru: 'Ваш аккаунт был заблокирован на редактирование.',
            en: 'Your account has been blocked for editing.',
            tr: 'Hesabınız düzenleme için engellendi.',
            es: 'Su cuenta ha sido bloqueada para editar.',
            ar: 'تم حظر حسابك للتحرير.',
            de: 'Ihr Konto wurde zur Bearbeitung gesperrt.',
            pt: 'Sua conta foi bloqueada para edição.',
            fr: 'Votre compte a été bloqué pour la modification.',
            zh: '您的帐户已被禁止编辑。',
            ko: '계정이 편집 중지되었습니다.',
            ja: 'アカウントは編集のためにブロックされています',
            th: 'บัญชีของคุณถูกบล็อกเพื่อการแก้ไข',
            he: 'החשבון שלך נחסם לעריכה.',
            zh_tw: '您的帳戶已被封鎖。',
            fil: 'Ang iyong account ay na-block para sa pag-edit.',
            id: 'Akun anda telah diblokir untuk mengedit.'
        },
        core_23: {
            ru: 'Не прописан промпт или не выбраны элементы на перевод',
            en: 'No prompt specified or no elements selected for translation',
            tr: 'Belirtilen bir ipucu yok veya çeviri için öğeler seçilmedi',
            es: 'No se especificó un indicador o no se seleccionaron elementos para la traducción',
            ar: 'لم يتم تحديد مؤشر أو لم يتم تحديد عناصر للترجمة',
            de: 'Kein Hinweis angegeben oder keine Elemente zur Übersetzung ausgewählt',
            pt: 'Nenhum prompt especificado ou nenhum elemento selecionado para tradução',
            fr: 'Aucun indicateur spécifié ou aucun élément sélectionné pour la traduction',
            zh: '未指定提示或未选择要翻译的元素',
            ko: '번역을 위해 프롬프트를 지정하거나 번역할 요소를 선택해야 합니다.',
            ja: 'プロンプトが指定されていないか、翻訳する要素が選択されていません',
            th: 'ไม่มีคำใบ้ที่ระบุหรือไม่ได้เลือกองค์ประกอบสำหรับการแปล',
            he: 'לא הוגדר מקטע או שאין אלמנטים שנבחרו לתרגום.',
            zh_tw: '未指定提示或未選擇用於翻譯的元素',
            fil: 'Walang prompt na tinukoy o walang mga elemento na napili para sa pagsasalin',
            id: 'Tidak terdapat perintah atau elemen yang dipilih untuk penerjemahan'
        },
        core_24: {
            ru: 'Ошибка перевода',
            en: 'Translation error',
            tr: 'Çeviri hatası',
            es: 'Error de traducción',
            ar: 'خطأ في الترجمة',
            de: 'Übersetzungsfehler',
            pt: 'Erro de tradução',
            fr: 'Erreur de traduction',
            zh: '翻译错误',
            ko: '번역 오류',
            ja: '翻訳エラー',
            th: 'ข้อผิดพลาดในการแปล',
            he: 'שגיאת תרגום.',
            zh_tw: '翻譯錯誤',
            fil: 'Error sa pagsasalin',
            id: 'Terdapat gangguan penerjemahan.'
        },
        core_25: {
            ru: 'Произошла ошибка перевода элементов с индексами:',
            en: 'An error occurred while translating elements with indexes:',
            tr: 'İndeksleri olan öğelerin çevirisi sırasında bir hata oluştu.',
            es: 'Se produjo un error al traducir elementos con índices:',
            ar: 'حدث خطأ أثناء ترجمة العناصر ذات المؤشرات:',
            de: 'Beim Übersetzen von Elementen mit Indizes ist ein Fehler aufgetreten:',
            pt: 'Ocorreu um erro ao traduzir elementos com índices:',
            fr: "Une erreur s'est produite lors de la traduction des éléments avec des index:",
            zh: '在翻译具有索引的元素时发生错误：',
            ko: '다음 인덱스의 요소를 번역하는 동안 오류가 발생했습니다:',
            ja: '次のインデックスの要素を翻訳する際にエラーが発生しました',
            th: 'เกิดข้อผิดพลาดในระหว่างการแปลองค์ประกอบที่มีดัชนี:',
            he: 'אירעה שגיאה בתרגום האלמנטים עם האינדקסים:',
            zh_tw: '翻譯索引元素時發生錯誤：',
            fil: 'Nagkaroon ng error habang isinasalin ang mga elemento na may mga indeks:',
            id: 'Terdapat gangguan saat menerjemahkan elemen dengan indeks berikut:'
        },
        core_26: {
            ru: 'Не выбран язык перевода',
            en: 'Translation language not selected',
            tr: 'Çeviri dili seçilmedi',
            es: 'No se ha seleccionado el idioma de traducción',
            ar: 'لم يتم اختيار لغة الترجمة',
            de: 'Übersetzungssprache nicht ausgewählt',
            pt: 'Idioma de tradução não selecionado',
            fr: 'Langue de traduction non sélectionnée',
            zh: '未选择翻译语言',
            ko: '번역 언어가 선택되지 않았습니다.',
            ja: '翻訳言語が選択されていません',
            th: 'ยังไม่ได้เลือกภาษาที่จะแปล',
            he: 'לא נבחר שפת תרגום.',
            zh_tw: '未選擇翻譯語言',
            fil: 'Hindi napili ang wika ng pagsasalin',
            id: 'Bahasa terjemahan tidak dipilih.'
        },
        core_27: {
            ru: 'Поле не может быть пустым и начинаться с цифры',
            en: 'The field cannot be empty and start with a number.',
            tr: 'Alan boş olamaz ve bir sayıyla başlayamaz.',
            es: 'El campo no puede estar vacío y comenzar con un número.',
            ar: 'لا يمكن أن يكون الحقل فارغًا ويبدأ برقم.',
            de: 'Das Feld darf nicht leer sein und mit einer Zahl beginnen.',
            pt: 'O campo não pode estar vazio e começar com um número.',
            fr: 'Le champ ne peut pas être vide et commencer par un chiffre.',
            zh: '该字段不能为空且不能以数字开头。',
            ko: '필드는 비어 있거나 숫자로 시작할 수 없습니다.',
            ja: 'フィールドは空であってはならず、数字で始まることはできません。',
            th: 'ฟิลด์ต้องไม่เป็นค่าว่างและเริ่มต้นด้วยตัวเลข',
            he: 'השדה לא יכול להיות ריק ולהתחיל במספר.',
            zh_tw: '欄位不能為空且不能以數字開頭。',
            fil: 'Hindi maaaring walang laman ang patlang at magsimula sa isang numero.',
            id: 'Bagian ini tidak boleh kosong dan dimulai dengan angka.'
        },
        core_28: {
            ru: 'Условие не может указывать на себя',
            en: 'The condition cannot refer to itself.',
            tr: 'Koşul kendisini işaret edemez.',
            es: 'La condición no puede referirse a sí misma.',
            ar: 'لا يمكن أن تشير الشرط إلى نفسه.',
            de: 'Die Bedingung darf nicht auf sich selbst verweisen.',
            pt: 'A condição não pode se referir a si mesma.',
            fr: 'La condition ne peut pas se référer à elle-même.',
            zh: '条件不能指向自身。',
            ko: '조건은 자기 자신을 가리킬 수 없습니다.',
            ja: '条件は自己を指すことはできません。',
            th: 'เงื่อนไขไม่สามารถอ้างอิงถึงตัวเองได้.',
            he: 'התנאי לא יכול להתייחס לעצמו.',
            zh_tw: '條件不能參考自身。',
            fil: 'Ang kondisyon ay hindi maaaring tumukoy sa sarili nito.',
            id: 'Kondisi tidak dapat merujuk kepada dirinya sendiri'
        },
        core_29: {
            ru: 'К пользователю привязан профиль с имеющимися лицензиями',
            en: '',
            tr: '',
            es: '',
            ar: '',
            de: '',
            pt: '',
            fr: '',
            zh: '',
            ko: '',
            ja: '',
            th: '',
            he: '',
            zh_tw: '',
            fil: ''
        },
        core_30: {
            ru: 'Непредвиденная ошибка. Попробуйте позже.',
            en: '',
            tr: '',
            es: '',
            ar: '',
            de: '',
            pt: '',
            fr: '',
            zh: '',
            ko: '',
            ja: '',
            th: '',
            he: '',
            zh_tw: '',
            fil: ''
        },
        export_1: {
            ru: 'Для экспорта нужно выбрать профиль из списка.',
            en: 'To export, you need to select a profile from the list.',
            tr: 'Dışa aktarmak için listeden bir profil seçmeniz gerekir.',
            es: 'Para exportar, debe seleccionar un perfil de la lista.',
            ar: 'للتصدير ، تحتاج إلى تحديد ملف تعريف من القائمة.',
            de: 'Um zu exportieren, muss ein Profil ausgewählt werden.',
            pt: 'Para exportar, selecione um perfil na lista.',
            fr: 'Pour exporter, vous devez sélectionner un profil dans la liste.',
            zh: '您需要从列表中选择一个配置文件进行导出',
            ko: '내보내려면 목록에서 프로필을 선택해야 합니다.',
            ja: 'エクスポートするにはリストからプロファイルを選択する必要があります',
            th: 'เพื่อส่งออก คุณต้องเลือกโปรไฟล์จากรายการ',
            he: 'ליצוא, עליך לבחור פרופיל מהרשימה.',
            zh_tw: '要導出，您需要從列表中選擇一個資料檔案。',
            fil: 'Upang i-export, kailangan mong pumili ng isang profil mula sa listahan.',
            id: 'Untuk mengekspor, Anda perlu memilih profil dari daftar.'
        },
        export_2: {
            ru: 'Выберите разные языки.',
            en: 'Choose different languages.',
            tr: 'Farklı diller seçin.',
            es: 'Elige diferentes idiomas.',
            ar: 'اختر لغات مختلفة.',
            de: 'Wählen Sie verschiedene Sprachen aus.',
            pt: 'Selecione idiomas diferentes.',
            fr: 'Veuillez sélectionner des langues différentes.',
            zh: '选择不同的语言。',
            ko: '다른 언어를 선택하세요.',
            ja: '異なる言語を選択してください',
            th: 'เลือกภาษาที่แตกต่างกัน',
            he: 'בחר שפות שונות.',
            zh_tw: '選擇不同的語言。',
            fil: "Pumili ng iba't ibang mga wika.",
            id: 'Pilih bahasa lain.'
        },
        import_1: {
            ru: 'Для ипорта нужно загрузить файл(json).',
            en: 'To import, you need to upload a file (json).',
            tr: 'İçe aktarmak için bir dosya (json) yüklemeniz gerekir.',
            es: 'Para importar, debe cargar un archivo (json).',
            ar: 'للاستيراد ، تحتاج إلى تحميل ملف (json).',
            de: 'Zum Importieren muss eine Datei (json) hochgeladen werden.',
            pt: 'Para importar, é necessário carregar um arquivo (json).',
            fr: 'Pour importer, vous devez télécharger un fichier (json).',
            zh: '您需要上传一个文件（json）进行导入。',
            ko: '가져오려면 파일(json)을 업로드해야 합니다.',
            ja: 'インポートするにはファイル（json）をアップロードする必要があります',
            th: 'เพื่อนำเข้า คุณต้องอัปโหลดไฟล์ (json)',
            he: 'ליבוא, עליך להעלות קובץ (json).',
            zh_tw: '要導入，您需要上傳文件 (json)。',
            fil: 'Upang mag-import, kailangan mong mag-upload ng isang file (json).',
            id: 'Untuk mengimpor, anda perlu mengunggah file (json).'
        },
        outpatient_1: {
            ru: 'Нельзя создать новый клинический случай с пустым именем.',
            en: 'Don`t create a new corporation with an empty name.',
            tr: 'Boş bir adla yeni bir şirket oluşturmayın.',
            es: 'No crees una nueva corporación con un nombre vacío.',
            ar: 'لا يمكنك إنشاء حالة سريرية جديدة باسم فارغ.',
            de: 'Es kann kein neuer klinischer Fall mit leerem Namen erstellt werden.',
            pt: 'Não é possível criar um novo caso clínico com um nome vazio.',
            fr: "Il n'est pas possible de créer un nouveau cas clinique sans nom.",
            zh: '请勿使用空名称创建新公司',
            ko: '빈 이름으로 새로운 법인을 생성하지 마세요.',
            ja: '空の名前で新しい法人を作成しないでください',
            th: 'อย่าสร้างบริษัทใหม่ที่มีชื่อว่าว่างเปล่า',
            he: 'אל תיצור חברה חדשה בשם ריק.',
            zh_tw: '不要用空名稱創建新公司。',
            fil: 'Huwag lumikha ng isang bagong korporasyon na may walang laman na pangalan.',
            id: 'Tidak dapat membuat perusahaan dengan nama kosong.'
        },
        outpatient_2: {
            ru: 'Полe {NAME} - не заполнено.',
            en: 'Field {NAME} - empty.',
            tr: '{NAME} alanı - boş.',
            es: 'Campo {NAME} - vacío.',
            ar: 'الخانة {NAME} غير معبأ.',
            de: 'Feld {NAME} - nicht ausgefüllt.',
            pt: 'O campo {NAME} não está preenchido.',
            fr: "Le champ {NAME} n'est pas rempli.",
            zh: '字段{NAME} - 空。',
            ko: '필드 {NAME} - 비어 있음.',
            ja: 'フィールド {NAME} - 空です',
            th: 'ฟิลด์ {NAME} - ว่างเปล่า',
            he: 'שדה {NAME} - ריק.',
            zh_tw: '欄位 {NAME} - 空。',
            fil: 'Patlang {NAME} - walang laman.',
            id: 'Bagian {NAME} - kosong'
        },
        outpatient_3: {
            ru: 'Не заполнена дозировка',
            en: 'Dosage not completed',
            tr: 'Dozaj tamamlanmadı',
            es: 'Dosis no completada',
            ar: 'لم تكتمل الجرعة',
            de: 'Dosierung fehlt.',
            pt: 'Dosagem não preenchida.',
            fr: "La posologie n'est pas remplie.",
            zh: '剂量未完成',
            ko: '용량이 완료되지 않았습니다.',
            ja: '用量が完了していません',
            th: 'ยาไม่สมบูรณ์',
            he: 'המינון לא הושלם.',
            zh_tw: '劑量未完成',
            fil: 'Hindi kumpleto ang dosis.',
            id: 'Dosis tidak lengkap'
        },
        outpatient_4: {
            ru: 'Выберите кейс для импорта данных.',
            en: 'Select the case to import the data.',
            tr: 'Verileri içe aktarmak için vakayı seçin.',
            es: 'Selecciona el caso para importar los datos.',
            ar: 'حدد حالة لاستيراد البيانات.',
            de: 'Wählen Sie einen Fall für den Import von Daten aus.',
            pt: 'Selecione um caso para importar dados.',
            fr: 'Sélectionnez un cas pour importer des données.',
            zh: '选择要导入数据的案例。',
            ko: '데이터를 가져올 사례를 선택하세요.',
            ja: 'データをインポートするためにケースを選択してください',
            th: 'เลือกเคสเพื่อนำเข้าข้อมูล',
            he: 'בחר את המקרה כדי ליבא את הנתונים.',
            zh_tw: '選擇案例以導入數據。',
            fil: 'Pumili ng kaso upang i-import ang data.',
            id: 'Pilih kasus untuk memasukkan data.'
        },
        outpatient_6: {
            ru: 'Нельзя создать новое обследование с пустым именем или типом.',
            en: 'You cannot create a new survey with an empty name or type.',
            tr: 'Boş bir ad veya türle yeni bir anket oluşturamazsınız.',
            es: 'No puedes crear una nueva investigación con un nombre o tipo vacío.',
            ar: 'لا يمكنك إنشاء استطلاع جديد باسم أو نوع فارغ.',
            de: 'Es kann kein neues Screening mit leerem Namen oder Typ erstellt werden.',
            pt: 'Não é possível criar um novo exame com nome ou tipo vazio.',
            fr: "Il n'est pas possible de créer un nouvel examen sans nom ou type.",
            zh: '您不能使用空名称或类型创建新调查。',
            ko: '빈 이름이나 유형으로 새로운 설문을 생성할 수 없습니다.',
            ja: '空の名前またはタイプで新しい調査を作成することはできません',
            th: 'คุณไม่สามารถสร้างแบบสำรวจใหม่ด้วยชื่อหรือประเภทที่ว่างเปล่าได้',
            he: 'אינך יכול ליצור סקר חדש עם שם או סוג ריק.',
            zh_tw: '您不能使用空名稱或類型創建新調查。',
            fil: 'Hindi ka maaaring lumikha ng isang bagong survey na walang laman o pangalan.',
            id: 'Anda tidak dapat membuat survei baru dengan nama atau tipe kosong.'
        },
        outpatient_7: {
            ru: 'Дополнительное обследование с таким именем уже есть.',
            en: 'There is already an additional survey with the same name.',
            tr: 'Aynı ada sahip ek bir anket zaten var.',
            es: 'Ya existe una investigación adicional con el mismo nombre.',
            ar: 'يوجد بالفعل استبيان إضافي بنفس الاسم.',
            de: 'Es gibt bereits ein zusätzliches Screening mit diesem Namen.',
            pt: 'Já existe um exame adicional com esse nome.',
            fr: 'Il y a déjà un examen supplémentaire avec ce nom.',
            zh: '已经有相同名称的附加调查了。',
            ko: '같은 이름의 추가 설문이 이미 있습니다.',
            ja: '同じ名前の追加調査が既に存在します',
            th: 'มีแบบสำรวจเพิ่มเติมที่มีชื่อเดียวกันแล้ว',
            he: 'כבר קיים סקר נוסף באותו השם.',
            zh_tw: '已有同名的額外調查。',
            fil: 'Mayroon nang isang karagdagang survey na may parehong pangalan.',
            id: 'Sudah terdapat survei lain dengan nama yang sama.'
        },
        outpatient_8: {
            ru: 'Текст должен соответствовать выбранному языку и не быть пустым',
            en: 'The text must match the selected language and not be empty',
            tr: 'Metin seçilen dille eşleşmeli ve boş olmamalıdır',
            es: 'El texto debe corresponder con el idioma seleccionado y no estar vacío',
            ar: 'يجب أن يتطابق النص مع اللغة المحددة ولا يكون فارغًا',
            de: 'Der Text muss der gewählten Sprache entsprechen und darf nicht leer sein.',
            pt: 'O texto deve corresponder ao idioma selecionado e não pode estar vazio.',
            fr: 'Le texte doit correspondre à la langue sélectionnée et ne doit pas être vide.',
            zh: '文本必须与所选语言匹配且不为空',
            ko: '텍스트는 선택된 언어와 일치하고 비어 있지 않아야 합니다.',
            ja: 'テキストは選択した言語と一致し、空であってはなりません',
            th: 'ข้อความต้องตรงกับภาษาที่เลือกและต้องไม่ว่างเปล่า',
            he: 'הטקסט חייב להתאים לשפה הנבחרת ולא להיות ריק.',
            zh_tw: '文字必須與所選語言匹配且不為空',
            fil: 'Ang teksto ay dapat tumugma sa napiling wika at hindi dapat walang laman.',
            id: 'Teks harus sesuai dengan bahasa yang telah dipilih dan tidak boleh kosong.'
        },
        outpatient_9: {
            ru: 'Должен быть выбран диалог',
            en: 'A dialog must be selected',
            tr: 'Bir iletişim kutusu seçilmelidir',
            es: 'Se debe seleccionar un cuadro de diálogo',
            ar: 'يجب تحديد مربع الحوار',
            de: 'Ein Dialog muss ausgewählt werden.',
            pt: 'Um diálogo deve ser selecionado.',
            fr: 'Un dialogue doit être sélectionné.',
            zh: '必须选择对话框',
            ko: '대화를 선택해야 합니다.',
            ja: 'ダイアログを選択する必要があります',
            th: 'ต้องเลือกไดอะล็อก',
            he: 'חייב לבחור דיאלוג.',
            zh_tw: '必須選擇對話',
            fil: 'Dapat piliin ang isang dialogo.',
            id: 'Dialog harus dipilih.'
        },
        outpatient_10: {
            ru: 'Аудио должно иметь название',
            en: 'The audio must have a title',
            tr: 'Sesin bir başlığı olmalı',
            es: 'El audio debe tener un título',
            ar: 'يجب أن يكون للصوت عنوان',
            de: 'Audio muss einen Namen haben.',
            pt: 'O áudio deve ter um nome.',
            fr: "L'audio doit avoir un titre.",
            zh: '音频必须有标题',
            ko: '오디오에는 제목이 있어야 합니다.',
            ja: 'オーディオにはタイトルが必要です',
            th: 'เสียงต้องมีชื่อ',
            he: 'האודיו חייב להיות עם כותרת.',
            zh_tw: '音訊必須有標題',
            fil: 'Ang audio ay dapat may pamagat.',
            id: 'Audio harus memiliki judul'
        },
        outpatient_11: {
            ru: 'Должен быть выбран голос для озвучки',
            en: 'A voice must be selected for voiceover',
            tr: 'Seslendirme için bir ses seçilmelidir',
            es: 'Se debe seleccionar una voz para voice-over',
            ar: 'يجب تحديد صوت للتعليق الصوتي',
            de: 'Eine Stimme für die Sprachausgabe muss ausgewählt werden.',
            pt: 'Uma voz deve ser selecionada para a dublagem.',
            fr: 'Une voix doit être sélectionnée pour la lecture à voix haute.',
            zh: '必须选择语音朗读者',
            ko: '성우를 선택해야 합니다.',
            ja: 'ナレーションのために声を選択する必要があります',
            th: 'ต้องเลือกเสียงสำหรับการพูด',
            he: 'חייב לבחור קול לדיבור הטקסט.',
            zh_tw: '必須選擇語音進行旁白',
            fil: 'Dapat piliin ang isang boses para sa voiceover.',
            id: 'Suara harus dipilih untuk pengisi suara'
        },
        outpatient_12: {
            ru: 'Невозможно создать препарат, пока есть незаполненный препарат',
            en: 'It is not possible to create a drug while there is an unfilled drug',
            tr: 'Doldurulmamış bir ilaç varken ilaç oluşturmak mümkün değildir',
            es: 'No es posible crear un medicamento mientras haya un medicamento sin terminar',
            ar: 'لا يمكن إنشاء عقار أثناء وجود دواء غير مملوء',
            de: 'Es kann kein Medikament erstellt werden, solange ein Medikament nicht ausgefüllt ist.',
            pt: 'Não é possível criar um medicamento enquanto houver um medicamento não preenchido.',
            fr: "Il n'est pas possible de créer un médicament tant qu'il y a un médicament non rempli.",
            zh: '在存在未填写的药品时无法创建药品',
            ko: '채워지지 않은 약이 있는 동안 약을 생성할 수 없습니다.',
            ja: '未記入の項目がある場合、薬剤を作成することはできません',
            th: 'ไม่สามารถสร้างยาขณะที่ยังมียาที่ไม่สมบูรณ์',
            he: 'אי אפשר ליצור תרופה כאשר ישנה תרופה שלא הושלמה.',
            zh_tw: '當有未填寫的藥物時，不能創建藥物',
            fil: 'Hindi posible lumikha ng isang gamot habang mayroong hindi natatapos na gamot.',
            id: 'Obat tidak dapat dibuat ketika obat tidak terisi'
        },
        outpatient_13: (name: string) => {
            return ({
                ru: `Во вкладке "${name}" произошла ошибка.`,
                en: `An error has occurred in the tab "${name}".`,
                tr: `Sekmede "${name}" bir hata oluştu.`,
                es: `Se ha producido un error en la pestaña "${name}".`,
                ar: `حدث خطأ في علامة التبويبحدث خطأ في علامة التبويب ${name}.`,
                de: `Im Tab "${name}" ist ein Fehler aufgetreten.`,
                pt: `Ocorreu um erro no separador "${name}".`,
                fr: `Une erreur s'est produite dans l'onglet "${name}".`,
                zh: `选项卡"${name}"中发生错误。`,
                ko: `"${name}" 탭에서 오류가 발생했습니다.`,
                ja: `"${name}" タブでエラーが発生しました。`,
                th: `เกิดข้อผิดพลาดในแท็บ "${name}"`,
                he: `אירעה שגיאה בכרטיסייה " ${name}".`,
                zh_tw: `选项卡"${name}"中发生错误。`,
                fil: `May naganap na error sa tab na "${name}".`,
                id: `Terjadi kesalahan di tab "${name}".`
            })
        },
        outpatient_14: {
            ru: 'Название клинического случая должно быть уникальным',
            en: 'The name of the clinical case must be unique',
            tr: 'Klinik vakanın adı benzersiz olmalıdır',
            es: 'El nombre del caso clínico debe ser único',
            ar: 'يجب أن يكون اسم الحالة السريرية فريدا',
            de: 'Der Name des klinischen Falls muss einzigartig sein.',
            pt: 'O nome do caso clínico deve ser único.',
            fr: 'Le nom du cas clinique doit être unique.',
            zh: '临床案例的名称必须是唯一的',
            ko: '임상 사례의 이름은 고유해야 합니다.',
            ja: '臨床ケースの名前は一意である必要があります',
            th: 'ชื่อเคสทางคลินิกต้องไม่ซ้ำกัน',
            he: 'שם המקרה הקליני חייב להיות ייחודי.',
            zh_tw: '臨床案例的名稱必須是唯一的',
            fil: 'Ang pangalan ng klinikal na kaso ay dapat magiging unique.',
            id: 'Nama kasus klinis harus unik'
        },
        outpatient_15: (name: string) => {
            return ({
                ru: `Не удалось загрузить: ${name}`,
                en: `Failed to load: ${name}`,
                tr: 'Yükleme başarısız: ' + name,
                es: 'Falló al cargar: ' + name,
                ar: 'فشل في التحميل: ' + name,
                de: 'Fehler beim Laden: ' + name,
                pt: 'Falha ao carregar: ' + name,
                fr: 'Échec du chargement: ' + name,
                zh: `加载失败：${name}`,
                ko: `탭 ${name}를 로드하지 못했습니다.`,
                ja: `タブ ${name} の読み込みに失敗しました。`,
                th: `ไม่สามารถโหลดแท็บ ${name} ได้`,
                he: `נכשל לטעון: ${name}`,
                zh_tw: `加载失败：${name}`,
                fil: `Nabigo ang pagkarga: ${name}`,
                id: `Gagal memuat: ${name}`
            });
        },
        outpatient_16: {
            ru: 'Ограничение по длине текста.',
            en: 'Text length limit.',
            tr: 'Metin uzunluğu sınırı.',
            es: 'Límite de longitud de texto.',
            ar: 'حد طول النص.',
            de: 'Textlängenbeschränkung.',
            pt: 'Restrição de comprimento do texto.',
            fr: 'Limite de longueur de texte.',
            zh: '文本长度限制。',
            ko: '텍스트 길이 제한.',
            ja: 'テキストの長さ制限',
            th: 'จำกัดความยาวข้อความ',
            he: 'מגבלת אורך הטקסט.',
            zh_tw: '文本長度限制。',
            fil: 'Limitasyon sa haba ng teksto.',
            id: 'Batas panjang teks'
        },
        outpatient_17: {
            ru: 'Выберите хотя бы один ответ для пакетной конвертации текста в голос.',
            en: 'Please select at least one answer to batch convert text to voice.',
            tr: 'Metni sese toplu olarak dönüştürmek için lütfen en az bir yanıt seçin.',
            es: 'Seleccione al menos una respuesta para convertir texto a voz por lotes.',
            ar: 'يرجى تحديد إجابة واحدة على الأقل لتحويل النص إلى صوت.',
            de: 'Wählen Sie mindestens eine Antwort für die Stapelkonvertierung von Text in Sprache aus.',
            pt: 'Selecione pelo menos uma resposta para conversão em lote de texto em voz.',
            fr: 'Sélectionnez au moins une réponse pour la conversion de texte en voix en masse.',
            zh: '请至少选择一个答案批量转换文本到语音。',
            ko: '일괄 변환을 위해 최소한 하나의 응답을 선택하세요.',
            ja: 'テキストを音声に一括変換するために少なくとも1つの回答を選択してください',
            th: 'โปรดเลือกอย่างน้อยหนึ่งคำตอบสำหรับการแปลงข้อความเป็นเสียง',
            he: 'אנא בחר לפחות תשובה אחת להמרה המונית של טקסט לקול.',
            zh_tw: '請選擇至少一個答案批量將文本轉換為語音。',
            fil: 'Pumili ng hindi bababa sa isang sagot upang mag-convert ng teksto sa boses.',
            id: 'Pilih minimal satu jawaban untuk konversi gabungan teks ke suara.'
        },
        outpatient_18: {
            ru: 'Название категории должно быть уникальным',
            en: 'The category name must be unique',
            tr: 'Kategori adı benzersiz olmalıdır',
            es: 'El nombre de la categoría debe ser único',
            ar: 'يجب أن يكون اسم الفئة فريدا',
            de: 'Der Kategoriename muss einzigartig sein.',
            pt: 'O nome da categoria deve ser único.',
            fr: 'Le nom de la catégorie doit être unique.',
            zh: '类别名称必须是唯一的',
            ko: '카테고리 이름은 고유해야 합니다.',
            ja: 'カテゴリ名は一意である必要があります',
            th: 'ชื่อหมวดหมู่ต้องไม่ซ้ำกัน',
            he: 'שם הקטגוריה חייב להיות ייחודי.',
            zh_tw: '類別名稱必須是唯一的',
            fil: 'Ang pangalan ng kategorya ay dapat magiging unique.',
            id: 'Nama kategori harus unik'
        },
        outpatient_19: {
            ru: 'Должен быть присвоен хотя бы один язык.',
            en: 'At least one language must be assigned.',
            tr: 'En az bir dil atanmalıdır.',
            es: 'Se debe asignar al menos un idioma.',
            ar: 'يجب تعيين لغة واحدة على الأقل.',
            de: 'Es muss mindestens eine Sprache zugewiesen sein.',
            pt: 'Deve ser atribuída pelo menos uma língua.',
            fr: 'Au moins une langue doit être attribuée.',
            zh: '至少必须分配一种语言。',
            ko: '최소한 하나의 언어가 할당되어야 합니다.',
            ja: '少なくとも1つの言語を割り当てる必要があります',
            th: 'ต้องมีการกำหนดภาษาอย่างน้อยหนึ่งภาษา',
            he: 'לפחות שפה אחת חייבת להיות מוקצת.',
            zh_tw: '至少必須分配一種語言。',
            fil: 'Dapat mayroong hindi bababa sa isang wika na nakatalaga.',
            id: 'Minimal satu bahasa harus ditetapkan.'
        },
        outpatient_20: {
            ru: 'Должен быть выбран подвид болезни или вид болезни, если отсутствует подвид.',
            en: 'A subspecies of the disease or a type of disease should be selected if there is no subspecies.',
            tr: 'Alt tür yoksa, hastalığın bir alt türü veya bir hastalık türü seçilmelidir.',
            es: 'Se debe seleccionar una subespecie de la enfermedad o un tipo de enfermedad si no hay subespecies.',
            ar: 'يجب اختيار نوع فرعي من المرض أو نوع من المرض إذا لم يكن هناك نوع فرعي.',
            de: 'Eine Unterart der Krankheit oder eine Art von Krankheit sollte ausgewählt werden, wenn es keine Unterart gibt.',
            pt: 'Uma subespécie da doença ou um tipo de doença devem ser seleccionados se não existirem subespécies.',
            fr: "Une sous-espèce de la maladie ou un type de maladie doit être sélectionné s'il n'y a pas de sous-espèce.",
            zh: '如果没有亚种，则应选择疾病的亚种或疾病类型。',
            ko: '하위 종류가 없는 경우 질병의 하위 종류 또는 유형을 선택해야 합니다.',
            ja: 'サブスペシーズまたは病気のタイプがない場合、病気のサブスペシーズを選択する必要があります',
            th: 'ต้องเลือกสายพันธุ์ของโรคหรือประเภทของโรคหากไม่มีสายพันธุ์',
            he: 'חייב לבחור תת-סוג של המחלה או סוג המחלה אם אין תת-סוג.',
            zh_tw: '應選擇疾病的亞種或疾病類型，如果沒有亞種。',
            fil: 'Dapat piliin ang isang subspecies ng sakit o uri ng sakit kung walang subspecies.',
            id: 'Subspesies penyakit atau jenis penyakit harus dipilih jika subspesies kosong.'
        },
        auth_1: {
            ru: 'Возникли проблемы при подключение, попробуйте позже.',
            en: 'There were problems connecting, please try again later.',
            tr: 'Bağlanırken sorunlar oluştu, lütfen daha sonra tekrar deneyin.',
            es: 'Hay problemas de conexión, inténtalo de nuevo más tarde.',
            ar: 'حدثت مشاكل في الاتصال ، يرجى المحاولة مرة أخرى لاحقًا.',
            de: 'Es gab Probleme bei der Verbindung, versuchen Sie es später erneut.',
            pt: 'Problemas de conexão, tente novamente mais tarde.',
            fr: 'Des problèmes sont survenus lors de la connexion, veuillez réessayer plus tard.',
            zh: '连接存在问题，请稍后再试。',
            ko: '연결하는 동안 문제가 발생했습니다. 나중에 다시 시도하세요.',
            ja: '接続に問題が発生しました、後でもう一度お試しください',
            th: 'เกิดปัญหาในการเชื่อมต่อ โปรดลองอีกครั้งในภายหลัง',
            he: 'היו בעיות בחיבור, אנא נסה שוב מאוחר יותר.',
            zh_tw: '連接有問題，請稍後再試。',
            fil: 'May mga problema sa pagkakonekta, subukan muli mamaya.',
            id: 'Ada masalah saat menghubungkan, silakan coba lagi nanti.'
        },
        auth_2: {
            server: 'User not found.',
            ru: 'Пользователь не найден.',
            en: 'User is not found.',
            tr: 'Kullanıcı bulunamadı.',
            es: 'Usuario no encontrado.',
            ar: 'لم يتم العثور على المستخدم.',
            de: 'Benutzer nicht gefunden.',
            pt: 'Usuário não encontrado.',
            fr: "L'utilisateur n'a pas été trouvé.",
            zh: '用户未找到。',
            ko: '사용자를 찾을 수 없습니다.',
            ja: 'ユーザーが見つかりません',
            th: 'ไม่พบผู้ใช้',
            he: 'המשתמש לא נמצא.',
            zh_tw: '找不到用戶。',
            fil: 'Hindi natagpuan ang user.',
            id: 'Pengguna tidak ditemukan'
        },
        auth_3: {
            server: 'Wrong password.',
            ru: 'Указан неверный пароль.',
            en: 'Invalid password specified.',
            tr: 'Yanlış şifre.',
            es: 'Contraseña incorrecta.',
            ar: 'كلمة مرور خاطئة.',
            de: 'Das Passwort ist falsch.',
            pt: 'Senha incorreta.',
            fr: 'Le mot de passe est incorrect.',
            zh: '指定密码无效。',
            ko: '잘못된 비밀번호가 지정되었습니다.',
            ja: '指定されたパスワードが無効です',
            th: 'ระบุรหัสผ่านไม่ถูกต้อง',
            he: 'סיסמה שגויה.',
            zh_tw: '指定的密碼無效。',
            fil: 'Maling password ang ipinasok.',
            id: 'Kata sandi yang dimasukkan tidak valid.'
        },
        auth_4: {
            server: 'Std empty',
            ru: 'Вам не назначен профиль, обратитесь к администратору.',
            en: 'You have not been assigned a profile, contact your administrator.',
            tr: 'Size bir profil atanmadı, yöneticinizle iletişime geçin.',
            es: 'No se le ha asignado un perfil, comuníquese con su administrador.',
            ar: 'لم يتم تعيين ملف تعريف لك ، اتصل بالمسؤول.',
            de: 'Ihnen wurde kein Profil zugewiesen. Wenden Sie sich an den Administrator.',
            pt: 'Você não tem um perfil atribuído, entre em contato com o administrador.',
            fr: "Aucun profil ne vous a été attribué, veuillez contacter l'administrateur.",
            zh: '您尚未被分配个人资料，请联系管理员。',
            ko: '프로필이 할당되지 않았습니다. 관리자에게 문의하세요.',
            ja: 'プロファイルが割り当てられていません。管理者に連絡してください。',
            th: 'คุณไม่ได้รับมอบหมายโปรไฟล์ โปรดติดต่อผู้ดูแลระบบของคุณ',
            he: 'לא הוקצה לך פרופיל, פנה למנהל שלך.',
            zh_tw: '您尚未分配資料檔案，請聯絡您的管理員。',
            fil: 'Hindi ka nabigyan ng profil, makipag-ugnayan sa iyong administrator.',
            id: 'Pengaturan profil Anda belum ditetapkan, hubungi administrator.'
        },
        auth_5: {
            ru: 'Ошибка Авторизации.',
            en: 'Authorisation Error.',
            tr: 'Yetkilendirme Hatası.',
            es: 'Error de autorización.',
            ar: 'خطأ في التفويض.',
            de: 'Autorisierungsfehler.',
            pt: 'Erro de autorização.',
            fr: "Erreur d'authentification.",
            zh: '授权错误。',
            ko: '인증 오류.',
            ja: '認証エラー。',
            th: 'ข้อผิดพลาดในการให้สิทธิ์',
            he: 'שגיאת הרשאה.',
            zh_tw: '授權錯誤。',
            fil: 'Error sa pag-oauthorize.',
            id: 'Terdapat gangguan otorisasi.'
        },
        auth_6: {
            server: 'You don\'t have permission to access this resource.',
            ru: 'У вас нет разрешения на доступ к этому ресурсу.',
            en: "You don't have permission to access this resource.",
            tr: 'Bu kaynağa erişim izniniz yok.',
            es: 'No tienes permiso para acceder a este recurso.',
            ar: 'ليس لديك إذن للوصول إلى هذا المورد.',
            de: 'Sie haben keine Berechtigung für den Zugriff auf diese Ressource.',
            pt: 'Você não tem permissão para acessar este recurso.',
            fr: "Vous n'êtes pas autorisé à accéder à cette ressource.",
            zh: '您没有访问此资源的权限。',
            ko: '이 리소스에 액세스할 권한이 없습니다.',
            ja: 'このリソースにアクセスする権限がありません。',
            th: 'คุณไม่มีสิทธิ์ในการเข้าถึงทรัพยากรนี้',
            he: 'אין לך הרשאה לגשת למשאב זה.',
            zh_tw: '您無權訪問此資源。',
            fil: 'Wala kang pahintulot na ma-access ang mapagkukunan na ito.',
            id: 'Anda tidak memiliki izin untuk mengakses sumber ini.'
        },
        auth_7: {
            ru: 'Почта не валидна.',
            en: 'Mail invalid.',
            tr: 'Posta geçersiz.',
            es: 'Correo inválido.',
            ar: 'البريد غير صالح.',
            de: 'E-Mail ist ungültig.',
            pt: 'Email inválido.',
            fr: "L'e-mail n'est pas valide.",
            zh: '邮件无效。',
            ko: '유효하지 않은 이메일입니다.',
            ja: 'メールが無効です。',
            th: 'อีเมลไม่ถูกต้อง',
            he: 'כתובת האימייל איננה תקפה.',
            zh_tw: '郵件無效。',
            fil: 'Hindi wasto ang email.',
            id: 'Email tidak valid.'
        },
        recovery_1: {
            ru: 'Ссылка не активна',
            en: 'The link is not active',
            tr: 'Bağlantı etkin değil',
            es: 'El enlace no está activo',
            ar: 'الرابط غير نشط',
            de: 'Der Link ist nicht aktiv',
            pt: 'A ligação não está activa',
            fr: "Le lien n'est pas actif",
            zh: '链接无效',
            ko: '링크가 활성화되지 않았습니다.',
            ja: 'リンクは有効ではありません。',
            th: 'ลิงก์ไม่ได้เปิดใช้งาน',
            he: 'הקישור אינו פעיל.',
            zh_tw: '鏈接不活躍',
            fil: 'Ang link ay hindi aktibo.',
            id: 'Tautan tidak aktif'
        },
        recovery_2: {
            ru: 'Есть незаполненные поля',
            en: 'There are blank fields',
            tr: 'Boş alanlar var',
            es: 'Hay campos en blanco',
            ar: 'هناك حقول فارغة',
            de: 'Es gibt leere Felder',
            pt: 'Existem campos em branco',
            fr: 'Il y a des champs vides',
            zh: '存在空白字段。',
            ko: '빈 필드가 있습니다.',
            ja: '空白のフィールドがあります。',
            th: 'มีฟิลด์ที่ว่างเปล่า',
            he: 'יש שדות ריקים.',
            zh_tw: '有空白欄位',
            fil: 'May mga patlang na walang laman.',
            id: 'Terdapat bagian kosong'
        },
        recovery_3: {
            ru: 'Пароли не совпадают, попробуйте еще раз.',
            en: "Passwords don't match, try again.",
            tr: 'Şifreler uyuşmuyor, tekrar deneyin.',
            es: 'Las contraseñas no coinciden, inténtalo de nuevo.',
            ar: 'كلمات المرور لا تتطابق ، حاول مرة أخرى.',
            de: 'Passwörter stimmen nicht überein, versuchen Sie es erneut.',
            pt: 'As palavras-passe não correspondem, tente novamente.',
            fr: 'Les mots de passe ne correspondent pas, réessayez.',
            zh: '密码不匹配，请重试。',
            ko: '비밀번호가 일치하지 않습니다. 다시 시도하세요.',
            ja: 'パスワードが一致しません。もう一度お試しください。',
            th: 'รหัสผ่านไม่ตรงกัน โปรดลองอีกครั้ง',
            he: 'הסיסמאות אינן תואמות, נסה שוב.',
            zh_tw: '密碼不匹配，請再試一次。',
            fil: 'Hindi tugma ang mga password, subukan muli.',
            id: 'Kata sandi tidak sesuai, coba lagi.'
        }
    },
    info: {
        name_company: 'CASE MANAGER',
        core_1: {
            ru: 'Сохранить',
            en: 'Save',
            tr: 'Kaydetmek',
            es: 'Guardar',
            ar: 'يحفظ',
            de: 'Speichern',
            pt: 'Salvar',
            fr: 'Enregistrer.',
            zh: '保存',
            ko: '저장',
            ja: '保存',
            th: 'บันทึก',
            he: 'שמור',
            zh_tw: '保存',
            fil: 'I-save',
            id: 'Simpan'
        },
        core_2: {
            ru: 'Закрыть',
            en: 'Close',
            tr: 'Kapat',
            es: 'Cerrar',
            ar: 'أغلق',
            de: 'Schließen',
            pt: 'Fechar',
            fr: 'Fermer.',
            zh: '关闭',
            ko: '닫기',
            ja: '閉じる',
            th: 'ปิด',
            he: 'סגור',
            zh_tw: '關閉',
            fil: 'Isara',
            id: 'Tutup'
        },
        core_4: {
            ru: 'Категория',
            en: 'Category',
            tr: 'Kategori',
            es: 'Categoría',
            ar: 'فئة',
            de: 'Kategorie',
            pt: 'Categoria',
            fr: 'Catégorie.',
            zh: '类别',
            ko: '카테고리',
            ja: 'カテゴリ',
            th: 'หมวดหมู่',
            he: 'קטגוריה',
            zh_tw: '類別',
            fil: 'Kategorya',
            id: 'Kategori'
        },
        core_5: {
            ru: 'Описание',
            en: 'Description',
            tr: 'Tanım',
            es: 'Descripción',
            ar: 'وصف',
            de: 'Beschreibung',
            pt: 'Descrição',
            fr: 'Description.',
            zh: '描述',
            ko: '설명',
            ja: '説明',
            th: 'คำอธิบาย',
            he: 'תיאור',
            zh_tw: '描述',
            fil: 'Paglalarawan',
            id: 'Deskripsi'
        },
        core_6: {
            ru: 'Имя',
            en: 'Name',
            tr: 'İsim',
            es: 'Nombre',
            ar: 'اسم',
            de: 'Name',
            pt: 'Nome',
            fr: 'Nom.',
            zh: '名称',
            ko: '이름',
            ja: '名前',
            th: 'ชื่อ',
            he: 'שם',
            zh_tw: '名稱',
            fil: 'Pangalan',
            id: 'Nama'
        },
        core_7: {
            ru: 'В разработке',
            en: 'In developing',
            tr: 'Gelişmekte',
            es: 'En desarrollo',
            ar: 'قيد التطوير',
            de: 'In Bearbeitung',
            pt: 'Em desenvolvimento',
            fr: 'En cours de développement.',
            zh: '正在开发中',
            ko: '개발 중',
            ja: '開発中',
            th: 'กำลังพัฒนา',
            he: 'בפיתוח',
            zh_tw: '正在開發',
            fil: 'Nasa pag-develop',
            id: 'Dalam pengembangan'
        },
        core_8: {
            ru: 'Локализация',
            en: 'Localizations',
            tr: 'Yerelleştirmeler',
            es: 'Localizaciones',
            ar: 'الموقع',
            de: 'Lokalisierung',
            pt: 'Localização',
            fr: 'Localisation',
            zh: '本地化',
            ko: '로컬라이제이션',
            ja: 'ローカライゼーション',
            th: 'การแปลงภาษา',
            he: 'לוקליזציות',
            zh_tw: '本地化',
            fil: 'Mga lokal na wika',
            id: 'Lokalisasi'
        },
        core_9: {
            ru: 'Загрузить новое изображение',
            en: 'Upload new image',
            tr: 'Yeni resim yükle',
            es: 'Subir nueva imagen',
            ar: 'تحميل صورة جديدة',
            de: 'Neues Bild hochladen',
            pt: 'Carregar nova imagem',
            fr: 'Télécharger une nouvelle image',
            zh: '上传新图像',
            ko: '새 이미지 업로드',
            ja: '新しい画像をアップロード',
            th: 'อัปโหลดรูปภาพใหม่',
            he: 'העלה תמונה חדשה',
            zh_tw: '上傳新圖片',
            fil: 'Mag-upload ng bagong larawan',
            id: 'Unggah gambar baru'
        },
        core_10: {
            ru: 'Добавить новую строку',
            en: 'Add new row',
            tr: 'Yeni satır ekle',
            es: 'Agregar nueva fila',
            ar: 'أضف سطرًا جديدًا',
            de: 'Neue Zeile hinzufügen',
            pt: 'Adicionar nova linha',
            fr: 'Ajouter une nouvelle ligne',
            zh: '添加新行',
            ko: '새로운 행 추가',
            ja: '新しい行を追加',
            th: 'เพิ่มแถวใหม่',
            he: 'הוסף שורה חדשה',
            zh_tw: '新增行',
            fil: 'Magdagdag ng bagong hilera',
            id: 'Tambah baris baru'
        },
        core_11: {
            ru: 'Загрузить',
            en: 'Upload',
            tr: 'Yükle',
            es: 'Subir',
            ar: 'تحميل',
            de: 'Hochladen',
            pt: 'Carregar',
            fr: 'Télécharger',
            zh: '上传',
            ko: '업로드',
            ja: 'アップロードする',
            th: 'อัปโหลด',
            he: 'העלאה',
            zh_tw: '上傳',
            fil: 'I-upload',
            id: 'Unggah'
        },
        core_12: {
            ru: 'Воспроизвести',
            en: 'Play',
            tr: 'Oyna',
            es: 'Ejecutar',
            ar: 'لعب',
            de: 'Abspielen',
            pt: 'Reproduzir',
            fr: 'Jouer',
            zh: '播放',
            ko: '재생',
            ja: '再生',
            th: 'เล่น',
            he: 'נגן',
            zh_tw: '播放',
            fil: 'I-play',
            id: 'Putar'
        },
        core_13: {
            ru: 'Редактировать список',
            en: 'Edit list',
            tr: 'Listeyi düzenle',
            es: 'Editar lista',
            ar: 'تحرير القائمة',
            de: 'Liste bearbeiten',
            pt: 'Editar lista',
            fr: 'Éditer la liste',
            zh: '编辑列表',
            ko: '목록 편집',
            ja: 'リストの編集',
            th: 'แก้ไขรายการ',
            he: 'ערוך רשימה',
            zh_tw: '編輯列表',
            fil: 'I-edit ang listahan',
            id: 'Edit daftar'
        },
        core_14: {
            ru: 'Создать',
            en: 'Create',
            tr: 'Oluşturmak',
            es: 'Crear',
            ar: 'خلق',
            de: 'Erstellen',
            pt: 'Criar',
            fr: 'Créer',
            zh: '创建',
            ko: '생성',
            ja: '作成',
            th: 'สร้าง',
            he: 'צור',
            zh_tw: '創建',
            fil: 'Lumikha',
            id: 'Buat'
        },
        core_15: {
            ru: 'Добавить',
            en: 'Add',
            tr: 'Ekle',
            es: 'Añadir',
            ar: 'إضافة',
            de: 'Hinzufügen',
            pt: 'Adicionar',
            fr: 'Ajouter',
            zh: '添加',
            ko: '추가',
            ja: '追加',
            th: 'เพิ่ม',
            he: 'הוסף',
            zh_tw: '添加',
            fil: 'Magdagdag',
            id: 'Tambah'
        },
        core_16: {
            ru: 'Дозировки',
            en: 'Dosages',
            tr: 'Dozajlar',
            es: 'Dosis',
            ar: 'الجرعات',
            de: 'Dosierungen',
            pt: 'Dosagens',
            fr: 'Dosages',
            zh: '剂量',
            ko: '용량',
            ja: '用量',
            th: 'ปริมาณยา',
            he: 'מינונים',
            zh_tw: '劑量',
            fil: 'Mga Dosage',
            id: 'Dosis'
        },
        core_17: {
            ru: 'Добавить новую дозировку',
            en: 'Add new dosages',
            tr: 'Yeni dozajlar ekleyin',
            es: 'Añadir nuevas dosis',
            ar: 'أضف جرعة جديدة',
            de: 'Neue Dosierung hinzufügen',
            pt: 'Adicionar nova dosagem',
            fr: 'Ajouter un nouveau dosage',
            zh: '添加新剂量',
            ko: '새로운 용량 추가',
            ja: '新しい用量を追加',
            th: 'เพิ่มยาใหม่',
            he: 'הוסף מינונים חדשים',
            zh_tw: '添加新劑量',
            fil: 'Magdagdag ng bagong dosage',
            id: 'Tambah dosis baru'
        },
        core_18: {
            ru: 'Удалить',
            en: 'Delete',
            tr: 'Silmek',
            es: 'Eliminar',
            ar: 'حذف',
            de: 'Löschen',
            pt: 'Excluir',
            fr: 'Supprimer',
            zh: '删除',
            ko: '삭제',
            ja: '削除',
            th: 'ลบ',
            he: 'מחק',
            zh_tw: '刪除',
            fil: 'Burahin',
            id: 'Hapus'
        },
        core_19: {
            ru: 'Отмена',
            en: 'Cancel',
            tr: 'İptal',
            es: 'Cancelar',
            ar: 'إلغاء',
            de: 'Abbrechen',
            pt: 'Cancelar',
            fr: 'Annuler',
            zh: '取消',
            ko: '취소',
            ja: 'キャンセル',
            th: 'ยกเลิก',
            he: 'ביטול',
            zh_tw: '取消',
            fil: 'Kanselahin',
            id: 'Batalkan'
        },
        core_20: {
            ru: 'Открыть изображение',
            en: 'Open Image',
            tr: 'Resmi Aç',
            es: 'Abrir Imagen',
            ar: 'فتح الصورة',
            de: 'Bild öffnen',
            pt: 'Abrir imagem',
            fr: "Ouvrir l'image",
            zh: '打开图像',
            ko: '이미지 열기',
            ja: '画像を開く',
            th: 'เปิดภาพ',
            he: 'פתח תמונה',
            zh_tw: '打開圖片',
            fil: 'Buksan ang Larawan',
            id: 'Buka gambar'
        },
        core_21: {
            ru: 'Изменить существующий',
            en: 'Edit Existing',
            tr: 'Mevcutları Düzenle',
            es: 'Editar Existente',
            ar: 'تحرير القائمة',
            de: 'Bestehendes bearbeiten',
            pt: 'Editar existente',
            fr: "Modifier l'existant",
            zh: '编辑现有的',
            ko: '기존 편집',
            ja: '既存の編集',
            th: 'แก้ไขที่มีอยู่',
            he: 'ערוך קיים',
            zh_tw: '編輯現有的',
            fil: 'I-edit ang Umiiral na',
            id: 'Edit yang sudah ada'
        },
        core_22: {
            ru: 'Редактировать',
            en: 'Edit',
            tr: 'Düzenlemek',
            es: 'Editar',
            ar: 'تعديل',
            de: 'Bearbeiten',
            pt: 'Editar',
            fr: 'Modifier',
            zh: '编辑',
            ko: '편집',
            ja: '編集',
            th: 'แก้ไข',
            he: 'ערוך',
            zh_tw: '編輯',
            fil: 'I-edit',
            id: 'Edit'
        },
        core_23: {
            ru: 'Переименовать',
            en: 'Rename',
            tr: 'Yeniden isimlendirmek',
            es: 'Cambiar nombre',
            ar: 'إعادة تسمية',
            de: 'Umbenennen',
            pt: 'Renomear',
            fr: 'Renommer',
            zh: '重命名',
            ko: '이름 변경',
            ja: '名前を変更',
            th: 'เปลี่ยนชื่อ',
            he: 'שנה שם',
            zh_tw: '重命名',
            fil: 'Palitan ang Pangalan',
            id: 'Ubah nama'
        },
        core_24: {
            ru: 'Используйте пкм для изменения имени препарата',
            en: 'Use RMB to change drug name',
            tr: 'İlaç adını değiştirmek için RMB kullanın',
            es: 'Usa RMB para cambiar el nombre del medicamento',
            ar: 'إظغت على الزر اليميني للماوس لتغيير اسم الدواء',
            de: 'Verwenden Sie die rechte Maustaste, um den Namen des Arzneimittels zu ändern',
            pt: 'Use o botão direito do mouse para alterar o nome do medicamento',
            fr: 'Utilisez le clic droit pour modifier le nom du médicament',
            zh: '使用 RMB 更改药品名称',
            ko: '약 이름 변경을 위해 RMB를 사용하세요.',
            ja: '薬剤名を変更するには、右クリックを使用してください',
            th: 'ใช้ปุ่มขวาเพื่อเปลี่ยนชื่อยา',
            he: 'השתמש בכפתור העכבר הימני לשינוי שם התרופה',
            zh_tw: '使用右鍵更改藥名',
            fil: 'Gumamit ng RMB upang baguhin ang pangalan ng gamot',
            id: 'Gunakan RMB untuk mengubah nama obat'
        },
        core_25: {
            ru: 'Поиск',
            en: 'Search',
            tr: 'Arama',
            es: 'Buscar',
            ar: 'بحث',
            de: 'Suche',
            pt: 'Pesquisar',
            fr: 'Recherche',
            zh: '搜索',
            ko: '검색',
            ja: '検索',
            th: 'ค้นหา',
            he: 'חפש',
            zh_tw: '搜索',
            fil: 'Maghanap',
            id: 'Cari'
        },
        core_26: {
            ru: 'Фильтр',
            en: 'Filter',
            tr: 'Filtre',
            es: 'Filtro',
            ar: 'تصفية',
            de: 'Filter',
            pt: 'Filtro',
            fr: 'Filtre',
            zh: '筛选',
            ko: '필터',
            ja: 'フィルター',
            th: 'กรอง',
            he: 'סנן',
            zh_tw: '過濾',
            fil: 'Salain',
            id: 'Filter'
        },
        core_27: {
            ru: 'Подождите, идет загрузка',
            en: 'Wait, is loading',
            tr: 'Bekleyin, yükleniyor',
            es: 'Espera, se está cargando',
            ar: 'من فضلك انتظر جاري التحميل',
            de: 'Bitte warten, die Seite wird geladen',
            pt: 'Aguarde, carregando',
            fr: 'Veuillez patienter, chargement en cours',
            zh: '等待，正在加载',
            ko: '로딩 중입니다.',
            ja: '読み込んでいます、お待ちください',
            th: 'รอ,กำลังโหลด',
            he: 'חכה, זה טוען',
            zh_tw: '等待，正在加載',
            fil: 'Maghintay, naglo-load',
            id: 'Harap tunggu, sedang memuat'
        },
        core_28: {
            ru: 'Список кейсов',
            en: 'List of cases',
            tr: 'Vakaların listesi',
            es: 'Lista de casos',
            ar: 'قائمة الحالات',
            de: 'Liste von Fällen',
            pt: 'Lista de casos',
            fr: 'Liste des cas',
            zh: '病例清单',
            ko: '사례 목록',
            ja: 'ケースの一覧',
            th: 'รายการของเคส',
            he: 'רשימת מקרים',
            zh_tw: '案例列表',
            fil: 'Listahan ng mga kaso',
            id: 'Daftar kasus'
        },
        core_29: {
            ru: 'Выбрать',
            en: 'Choose',
            tr: 'Seçmek',
            es: 'Seleccionar',
            ar: 'إختيار',
            de: 'Auswählen',
            pt: 'Selecionar',
            fr: 'Sélectionner',
            zh: '选择',
            ko: '선택',
            ja: '選択',
            th: 'เลือก',
            he: 'בחר',
            zh_tw: '選擇',
            fil: 'Pumili',
            id: 'Pilih'
        },
        core_30: {
            ru: 'Кейсы',
            en: 'Cases',
            tr: 'vakalar',
            es: 'Casos',
            ar: 'حالات',
            de: 'Fälle',
            pt: 'Casos',
            fr: 'Cas',
            zh: '案例',
            ko: '사례',
            ja: 'ケース',
            th: 'เคส',
            he: 'מקרים',
            zh_tw: '案例',
            fil: 'Mga Kaso',
            id: 'Kasus'
        },
        core_31: {
            ru: 'Категории',
            en: 'Categories',
            tr: 'Kategoriler',
            es: 'Categorías',
            ar: 'فئات',
            de: 'Kategorien',
            pt: 'Categorias',
            fr: 'Catégories',
            zh: '类别',
            ko: '카테고리',
            ja: 'カテゴリ',
            th: 'หมวดหมู่',
            he: 'קטגוריות',
            zh_tw: '類別',
            fil: 'Mga Kategorya',
            id: 'Kategori'
        },
        core_32: {
            ru: 'Создать профиль',
            en: 'Create profile',
            tr: 'Profil oluştur',
            es: 'Crear perfil',
            ar: 'إنشاء ملف تعريف',
            de: 'Profil erstellen',
            pt: 'Criar perfil',
            fr: 'Créer un profil',
            zh: '创建个人资料',
            ko: '프로필 생성',
            ja: 'プロファイルを作成',
            th: 'สร้างโปรไฟล์',
            he: 'צור פרופיל',
            zh_tw: '創建資料檔案',
            fil: 'Lumikha ng profil',
            id: 'Buat profil'
        },
        core_33: {
            ru: 'Пользователи',
            en: 'Users',
            tr: 'Kullanıcılar',
            es: 'Usuarios',
            ar: 'المستخدمون',
            de: 'Benutzer',
            pt: 'Usuários',
            fr: 'Utilisateurs',
            zh: '用户',
            ko: '사용자',
            ja: 'ユーザー',
            th: 'ผู้ใช้',
            he: 'משתמשים',
            zh_tw: '用戶',
            fil: 'Mga User',
            id: 'Pengguna'
        },
        core_34: {
            ru: 'Профили',
            en: 'Profiles',
            tr: 'Profiller',
            es: 'Perfiles',
            ar: 'المظاهر',
            de: 'Profile',
            pt: 'Perfis',
            fr: 'Profils',
            zh: '个人资料',
            ko: '프로필',
            ja: 'プロファイル',
            th: 'โปรไฟล์',
            he: 'פרופילים',
            zh_tw: '資料檔案',
            fil: 'Mga Profil',
            id: 'Profil'
        },
        core_35: {
            ru: 'Создание пользователя',
            en: 'Create user',
            tr: 'Kullanıcı oluştur',
            es: 'Crear usuario',
            ar: 'إنشاء مستخدم',
            de: 'Benutzer erstellen',
            pt: 'Criando usuário',
            fr: "Création d'un utilisateur",
            zh: '创建用户',
            ko: '사용자 생성',
            ja: 'ユーザーを作成',
            th: 'สร้างผู้ใช้',
            he: 'צור משתמש',
            zh_tw: '創建用戶',
            fil: 'Lumikha ng user',
            id: 'Buat pengguna'
        },
        core_36: {
            ru: 'Стандарт',
            en: 'Standard',
            tr: 'Standart',
            es: 'Estándar',
            ar: 'اساسي',
            de: 'Standard',
            pt: 'Padrão',
            fr: 'Standard',
            zh: '标准的',
            ko: '표준',
            ja: '標準',
            th: 'มาตรฐาน',
            he: 'תקן',
            zh_tw: '標準',
            fil: 'Standard',
            id: 'Standar'
        },
        core_37: {
            ru: 'Редактирование профиля',
            en: 'Edit profile',
            tr: 'Profili Düzenle',
            es: 'Editar perfil',
            ar: 'تحرير الملف الشخصي',
            de: 'Profil bearbeiten',
            pt: 'Edição de perfil',
            fr: 'Modifier le profil',
            zh: '编辑个人资料',
            ko: '프로필 편집',
            ja: 'プロファイルを編集',
            th: 'แก้ไขโปรไฟล์',
            he: 'ערוך פרופיל',
            zh_tw: '編輯資料檔案',
            fil: 'I-edit ang profil',
            id: 'Edit profil'
        },
        core_38: {
            ru: 'Вы уверены, что хотите удалить пользователя?',
            en: 'Are you sure, you want to delete user?',
            tr: 'Kullanıcıyı silmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que deseas eliminar el usuario?',
            ar: 'هل أنت متأكد أنك تريد حذف المستخدم؟',
            de: 'Sind Sie sicher, dass Sie den Benutzer löschen möchten?',
            pt: 'Tem certeza de que deseja excluir o usuário?',
            fr: "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",
            zh: '你确定要删除用户吗？',
            ko: '사용자를 삭제하시겠습니까?',
            ja: 'ユーザーを削除してもよろしいですか？',
            th: 'คุณแน่ใจหรือว่าต้องการลบผู้ใช้?',
            he: 'האם אתה בטוח שתרצה למחוק משתמש?',
            zh_tw: '您確定要刪除用戶嗎？',
            fil: 'Sigurado ka bang gusto mong burahin ang user?',
            id: 'Anda yakin ingin menghapus pengguna?'
        },
        core_39: {
            ru: 'После удаления, востановить данные будет невозможно.',
            en: 'Once deleted, the data cannot be recovered.',
            tr: 'Silindikten sonra veriler kurtarılamaz.',
            es: 'Una vez eliminados, los datos no se podrán recuperar.',
            ar: 'بمجرد الحذف ، لا يمكن استعادة البيانات.',
            de: 'Nach dem Löschen können die Daten nicht wiederhergestellt werden.',
            pt: 'Depois de excluído, os dados não poderão ser recuperados.',
            fr: 'Après la suppression, il sera impossible de récupérer les données.',
            zh: '一旦删除，数据将无法恢复。',
            ko: '한번 삭제되면 데이터를 복구할 수 없습니다.',
            ja: '削除すると、データは復元できません。',
            th: 'หลังจากที่ลบแล้ว ข้อมูลจะไม่สามารถกู้คืนได้',
            he: 'לאחר המחיקה, הנתונים לא יוכלו להיות מוחזרים.',
            zh_tw: '一旦刪除，數據將無法恢復。',
            fil: 'Kapag binura na, hindi na maaring mabawi ang data.',
            id: 'Jika dihapus, data tidak dapat dikembalikan.'
        },
        core_40: {
            ru: 'Вы уверены, что хотите удалить профиль?',
            en: 'Are you sure, you want to delete profile?',
            tr: 'Profili silmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que deseas eliminar el perfil?',
            ar: 'هل أنت متأكد أنك تريد حذف ملف التعريف؟',
            de: 'Sind Sie sicher, dass Sie das Profil löschen möchten?',
            pt: 'Tem certeza de que deseja excluir o perfil?',
            fr: 'Êtes-vous sûr de vouloir supprimer le profil ?',
            zh: '你确定要删除配置文件吗？',
            ko: '프로필을 삭제하시겠습니까?',
            ja: 'プロファイルを削除してもよろしいですか？',
            th: 'คุณแน่ใจหรือว่าต้องการลบโปรไฟล์?',
            he: 'האם אתה בטוח שתרצה למחוק פרופיל?',
            zh_tw: '您確定要刪除資料檔案嗎？',
            fil: 'Sigurado ka bang gusto mong burahin ang profil?',
            id: 'Anda yakin ingin menghapus profil?'
        },
        core_41: {
            ru: 'Инструкция:',
            en: 'Instruction:',
            tr: 'Talimat:',
            es: 'Instrucciones:',
            ar: 'تعليمات:',
            de: 'Anleitung:',
            pt: 'Instrução:',
            fr: 'Instruction:',
            zh: '说明：',
            ko: '사용 설명:',
            ja: '指示：',
            th: 'คำแนะนำ:',
            he: 'הוראות:',
            zh_tw: '說明：',
            fil: 'Tagubilin:',
            id: 'Instruksi:'
        },
        core_43: {
            ru: 'Загрузить новый файл',
            en: 'Upload new file',
            tr: 'Yeni dosya yükle',
            es: 'Subir nuevo archivo',
            ar: 'تحميل ملف جديد',
            de: 'Neue Datei hochladen',
            pt: 'Carregar novo arquivo',
            fr: 'Télécharger un nouveau fichier',
            zh: '上传新文件',
            ko: '새 파일 업로드',
            ja: '新しいファイルをアップロード',
            th: 'อัปโหลดไฟล์ใหม่',
            he: 'העלה קובץ חדש',
            zh_tw: '上傳新文件。',
            fil: 'Mag-upload ng bagong file',
            id: 'Unggah file baru'
        },
        core_44: {
            ru: 'Выбрать из существующих',
            en: 'Choose from existing',
            tr: 'Mevcut arasından seçim yapın',
            es: 'Eligir entre los existentes',
            ar: 'إختيار من القائمة',
            de: 'Aus den vorhandenen auswählen',
            pt: 'Selecionar de existente',
            fr: 'Sélectionner parmi les existants',
            zh: '从现有文件中选择',
            ko: '기존 파일에서 선택',
            ja: '既存のものから選択',
            th: 'เลือกจากที่มีอยู่',
            he: 'בחר מהקיימים',
            zh_tw: '從現有選擇',
            fil: 'Pumili mula sa umiiral na mga file',
            id: 'Pilih dari yang tersimpan'
        },
        core_45: {
            ru: 'Внимание',
            en: 'Warning',
            tr: 'Uyarı',
            es: 'Advertencia',
            ar: 'انتباه',
            de: 'Achtung',
            pt: 'Aviso',
            fr: 'Attention',
            zh: '警告',
            ko: '경고',
            ja: '警告',
            th: 'คำเตือน',
            he: 'אזהרה',
            zh_tw: '警告',
            fil: 'Babala',
            id: 'Peringatan'
        },
        core_46: {
            ru: 'Обновить',
            en: 'Update',
            tr: 'Güncelleme',
            es: 'Actualizar',
            ar: 'تحديث',
            de: 'Aktualisieren',
            pt: 'Atualizar',
            fr: 'Mettre à jour',
            zh: '更新',
            ko: '업데이트',
            ja: 'アップデート',
            th: 'อัพเดต',
            he: 'עדכן',
            zh_tw: '更新',
            fil: 'I-update',
            id: 'Memperbarui'
        },
        core_47: {
            ru: 'Соединение с сервером потеряно, пожалуйста перезагрузите страницу для корректной работы сайта. Вы уверены, что хотите продолжить работу?',
            en: 'The connection to the server is lost, please reload the page for the correct operation of the site. Are you sure you want to continue working?',
            tr: 'Sunucu bağlantısı kesildi, doğru çalışma için sayfayı yeniden yükleyin. İşleme devam etmek istediğinize emin misiniz?',
            es: 'La conexión con el servidor se ha perdido, por favor, vuelve a cargar la página para el correcto funcionamiento del sitio. ¿Estás seguro de que quieres seguir trabajando?',
            ar: 'نقطع الاتصال بالخادم ، يرجى إعادة تحميل الصفحة حتى يعمل الموقع بشكل صحيح. هل أنت متأكد أنك تريد الاستمرار',
            de: 'Die Verbindung zum Server ist verloren gegangen. Bitte laden Sie die Seite neu, um die Website ordnungsgemäß zu verwenden. Sind Sie sicher, dass Sie weiterarbeiten möchten?',
            pt: 'A conexão com o servidor foi perdida, por favor recarregue a página para o site funcionar corretamente. Tem certeza de que deseja continuar trabalhando?',
            fr: 'La connexion au serveur est perdue, veuillez recharger la page pour un fonctionnement correct du site. Êtes-vous sûr de vouloir continuer ?',
            zh: '连接到服务器已断开，请重新加载页面以正确运行网站。你确定要继续工作吗？',
            ko: '서버 연결이 끊어졌습니다. 사이트가 올바르게 작동하려면 페이지를 다시로드하십시오. 계속 작업하시겠습니까?',
            ja: 'サーバーとの接続が切断されました。正常に動作するためにページをリロードしてください。作業を続けてもよろしいですか？',
            th: 'การเชื่อมต่อกับเซิร์ฟเวอร์ขาดหายไป โปรดโหลดหน้าใหม่เพื่อให้เว็บไซต์ทำงานได้อย่างถูกต้อง คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
            he: 'החיבור לשרת אבד, אנא טען מחדש את העמוד לפעולה הנכונה של האתר. האם אתה בטוח שברצונך להמשיך לעבוד?',
            zh_tw: '與伺服器的連接已中斷，請重新載入頁面以確保網站正常運作。您確定要繼續工作嗎？',
            fil: 'Nawala ang koneksyon sa server, mangyaring i-reload ang pahina para sa tamang pagpapatakbo ng site. Sigurado ka bang gusto mong magpatuloy sa pagtatrabaho?',
            id: 'Sambungan ke server terputus, muat ulang halaman untuk pengoperasian situs yang benar. Anda yakin ingin melanjutkan?'
        },
        core_48: {
            ru: 'Перезагрузить',
            en: 'Reload',
            tr: 'Tekrar yükle',
            es: 'Cargar',
            ar: 'إعادة تحميل',
            de: 'Neuladen',
            pt: 'Recarregar',
            fr: 'Recharger',
            zh: '重新加载',
            ko: '새로 고침',
            ja: 'リロード',
            th: 'โหลดหน้าใหม่',
            he: 'טען מחדש',
            zh_tw: '重新載入',
            fil: 'I-reload',
            id: 'Memuat ulang'
        },
        core_49: {
            ru: 'Продолжить',
            en: 'Continue',
            tr: 'Devam et',
            es: 'Continuar',
            ar: 'تابع',
            de: 'Weitermachen',
            pt: 'Continuar',
            fr: 'Continuer',
            zh: '继续',
            ko: '계속',
            ja: '続行',
            th: 'ดำเนินการต่อ',
            he: 'המשך',
            zh_tw: '繼續',
            fil: 'Magpatuloy',
            id: 'Lanjutkan'
        },
        core_51: {
            ru: 'Количество ошибок',
            en: 'Number of mistakes',
            tr: 'Hata sayısı',
            es: 'Número de errores',
            ar: 'عدد الأخطاء',
            de: 'Anzahl der Fehler',
            pt: 'Número de erros',
            fr: "Nombre d'erreurs",
            zh: '错误数量',
            ko: '오류 수',
            ja: '誤りの数',
            th: 'จำนวนข้อผิดพลาด',
            he: 'מספר שגיאות',
            zh_tw: '錯誤次數',
            fil: 'Bilang ng mga pagkakamali',
            id: 'Jumlah kesalahan'
        },
        core_52: {
            ru: 'Копировать',
            en: 'Copy',
            tr: 'kopyala',
            es: 'Copiar',
            ar: 'نسخ',
            de: 'Kopieren',
            pt: 'Copiar',
            fr: 'Copier',
            zh: '复制',
            ko: '복사',
            ja: 'コピー',
            th: 'คัดลอก',
            he: 'העתק',
            zh_tw: '複製',
            fil: 'Kopyahin',
            id: 'Salin'
        },
        core_53: {
            ru: 'Темная тема',
            en: 'Dark mode',
            tr: 'Karanlık mod',
            es: 'Modo oscuro',
            ar: 'الوضع المظلم',
            de: 'Dunkles Thema',
            pt: 'Modo escuro',
            fr: 'Thème sombre',
            zh: '暗黑模式',
            ko: '어두운 모드',
            ja: 'ダークモード',
            th: 'โหมดมืด',
            he: 'מצב כהה',
            zh_tw: '深色模式',
            fil: 'Madilim na mode',
            id: 'Mode malam'
        },
        core_54: {
            ru: 'Попытаться восстановить',
            en: 'Try reestablish',
            tr: 'Yeniden kurmayı dene',
            es: 'Intenta restablecer',
            ar: 'حاول إعادة التأسيس',
            de: 'Versuchen Sie, wiederherzustellen.',
            pt: 'Tentar restaurar',
            fr: 'Tenter de récupérer',
            zh: '尝试重新连接',
            ko: '재연결 시도',
            ja: '再接続を試みる',
            th: 'ลองเชื่อมต่อใหม่',
            he: 'נסה לשחזר',
            zh_tw: '嘗試重新建立連接',
            fil: 'Subukang ibalik',
            id: 'Coba tetapkan ulang'
        },
        core_55: {
            ru: 'Выберите роль пользователя.',
            en: 'Select a user role.',
            tr: 'Bir kullanıcı rolü seçin.',
            es: 'Seleccione una función de usuario.',
            ar: 'حدد دور المستخدم.',
            de: 'Wählen Sie die Benutzerrolle.',
            pt: 'Selecione a função do usuário.',
            fr: "Choisissez le rôle de l'utilisateur.",
            zh: '选择用户角色。',
            ko: '사용자 역할 선택.',
            ja: 'ユーザーの役割を選択してください。',
            th: 'เลือกบทบาทผู้ใช้',
            he: 'בחר תפקיד משתמש.',
            zh_tw: '選擇用戶角色。',
            fil: 'Pumili ng role ng user.',
            id: 'Pilih peran pengguna.'
        },
        core_56: {
            ru: 'Да',
            en: 'Yes',
            tr: 'Evet',
            es: 'Sí',
            ar: 'نعم',
            de: 'Ja',
            pt: 'Sim',
            fr: 'Oui',
            zh: '是',
            ko: '예',
            ja: 'はい',
            th: 'ใช่',
            he: 'כן',
            zh_tw: '是',
            fil: 'Oo',
            id: 'Ya'
        },
        core_57: {
            ru: 'Для привязывания пользователя к определенному языку(запрет редактирования на других языках) или создание клиента.',
            en: 'To bind a user to a specific language (prohibition of editing in other languages) or to create a client.',
            tr: 'Bir kullanıcıyı belirli bir dile bağlamak (diğer dillerde düzenleme yasağı) veya bir istemci oluşturmak.',
            es: 'Para vincular a un usuario a un idioma específico (prohibición de editar en otros idiomas) o para crear un cliente.',
            ar: 'لإلزام المستخدم بلغة معينة (حظر التحرير بلغات أخرى) أو لإنشاء عميل.',
            de: 'Um einen Benutzer einer bestimmten Sprache zuzuordnen (Bearbeitungsverbot in anderen Sprachen) oder um einen Kunden zu erstellen.',
            pt: 'Para vincular o usuário a um idioma específico (impedindo a edição em outros idiomas) ou criar um cliente.',
            fr: "Pour lier un utilisateur à une langue spécifique (interdiction de modifier dans d'autres langues) ou créer un client.",
            zh: '将用户绑定到特定语言（禁止在其他语言中编辑）或创建客户端。',
            ko: '특정 언어에 사용자를 바인딩하거나 (다른 언어에서의 편집 금지) 클라이언트를 생성하려면.',
            ja: '特定の言語にユーザーをバインドする（他の言語での編集を禁止）またはクライアントを作成するために。',
            th: 'เพื่อผูกผู้ใช้กับภาษาที่เฉพาะเจาะจง (ห้ามแก้ไขในภาษาอื่น) หรือสร้างไคลเอ็นต์',
            he: 'לקשור משתמש לשפה מסוימת (איסור על עריכה בשפות אחרות) או ליצור לקוח.',
            zh_tw: '將用戶綁定到特定語言（禁止在其他語言中編輯）或創建客戶。',
            fil: 'Upang i-bind ang user sa isang partikular na wika (pagsasawalang-bahala sa pag-edit sa ibang mga wika) o upang lumikha ng isang kliyente.',
            id: 'Untuk menautkan pengguna ke bahasa tertentu (larangan mengedit dalam bahasa lain) atau untuk membuat klien.'
        },
        core_58: {
            ru: 'Создатели',
            en: 'Creators',
            tr: 'Creadors',
            es: 'Creadors',
            ar: 'الخالق',
            de: 'Schöpfer',
            pt: 'Criadores',
            fr: 'Créateurs',
            zh: '创建者',
            ko: '제작자',
            ja: '作成者',
            th: 'ผู้สร้าง',
            he: 'יוצרים',
            zh_tw: '創作者',
            fil: 'Mga Lumikha',
            id: 'Pembuat'
        },
        core_59: {
            ru: 'Стандарт',
            en: 'Standard',
            tr: 'Standartlar',
            es: 'Estándar',
            ar: 'المعايير',
            de: 'Standard',
            pt: 'Padrão',
            fr: 'Standard',
            zh: '标准',
            ko: '표준',
            ja: '標準',
            th: 'มาตรฐาน',
            he: 'תקן',
            zh_tw: '標準',
            fil: 'Standard',
            id: 'Standar'
        },
        core_60: {
            ru: 'Удаление профиля',
            en: 'Deleting a profile',
            tr: 'Bir profili silme',
            es: 'Eliminación de un perfil',
            ar: 'حذف ملف تعريف',
            de: 'Profil löschen',
            pt: 'Exclusão de perfil',
            fr: 'Supprimer le profil',
            zh: '删除配置文件',
            ko: '프로필 삭제',
            ja: 'プロファイルの削除',
            th: 'กำลังลบโปรไฟล์',
            he: 'מחיקת פרופיל',
            zh_tw: '刪除資料檔案',
            fil: 'Pagbubura ng profil',
            id: 'Menghapus profil'
        },
        core_61: {
            ru: 'Удалить профиль',
            en: 'Delete Profile',
            tr: 'Profili Sil',
            es: 'Borrar Perfil',
            ar: 'حذف الملف الشخصي',
            de: 'Profil löschen',
            pt: 'Excluir perfil',
            fr: 'Supprimer le profil',
            zh: '删除配置文件',
            ko: '프로필 삭제',
            ja: 'プロファイルを削除',
            th: 'ลบโปรไฟล์',
            he: 'מחק פרופיל',
            zh_tw: '刪除資料檔案',
            fil: 'Burahin ang Profil',
            id: 'Hapus profil'
        },
        core_62: {
            ru: 'Профиль',
            en: 'Profile',
            tr: 'Profil',
            es: 'Perfil',
            ar: 'الملف الشخصي',
            de: 'Profil',
            pt: 'Perfil',
            fr: 'Profil',
            zh: '个人资料',
            ko: '프로필',
            ja: 'プロファイル',
            th: 'โปรไฟล์',
            he: 'פרופיל',
            zh_tw: '資料檔案',
            fil: 'Profil',
            id: 'Profil'
        },
        core_63: {
            ru: 'Логи',
            en: 'Logs',
            tr: 'Günlükleri',
            es: 'Logie',
            ar: 'السجلات',
            de: 'Protokolle',
            pt: 'Logs',
            fr: 'Journaux',
            zh: '日志',
            ko: '로그',
            ja: 'ログ',
            th: 'บันทึก',
            he: 'יומנים',
            zh_tw: '日誌',
            fil: 'Logs',
            id: 'Log'
        },
        core_64: {
            ru: 'Все',
            en: 'All',
            tr: 'Herşey',
            es: 'Todos',
            ar: 'الجميع',
            de: 'Alle',
            pt: 'Todos',
            fr: 'Tout',
            zh: '所有的',
            ko: '모두',
            ja: 'すべて',
            th: 'ทั้งหมด',
            he: 'הכל',
            zh_tw: '全部',
            fil: 'Lahat',
            id: 'Semua'
        },
        core_65: {
            ru: 'Дата',
            en: 'Date',
            tr: 'Tarih',
            es: 'Fechas',
            ar: 'تواريخ',
            de: 'Datum',
            pt: 'Data',
            fr: 'Date',
            zh: '日期',
            ko: '날짜',
            ja: '日付',
            th: 'วันที่',
            he: 'תאריך',
            zh_tw: '日期',
            fil: 'Petsa',
            id: 'Tanggal'
        },
        core_66: {
            ru: 'Вкладка',
            en: 'Tab',
            tr: 'Sekmeler',
            es: 'Pestañas',
            ar: 'نوافذ التبويب',
            de: 'Tab',
            pt: 'Abas',
            fr: 'Onglet',
            zh: '选项卡',
            ko: '탭',
            ja: 'タブ',
            th: 'แท็บ',
            he: 'כרטיסייה',
            zh_tw: '標籤',
            fil: 'Tab',
            id: 'Lembar'
        },
        core_67: {
            ru: 'Вернуться назад',
            en: 'Come back',
            tr: 'Geri gel',
            es: 'Volver',
            ar: 'رجوع',
            de: 'Zurückkehren',
            pt: 'Voltar',
            fr: 'Retour en arrière',
            zh: '回来',
            ko: '뒤로 가기',
            ja: '戻る',
            th: 'กลับกลับมา',
            he: 'חזור',
            zh_tw: '返回',
            fil: 'Bumalik',
            id: 'Kembali'
        },
        core_69: {
            ru: 'Тема',
            en: 'Theme',
            tr: 'Tema',
            es: 'Tema',
            ar: 'سمة',
            de: 'Thema',
            pt: 'Tema',
            fr: 'Thème',
            zh: '主题',
            ko: '테마',
            ja: 'テーマ',
            th: 'ธีม',
            he: 'נושא',
            zh_tw: '主題',
            fil: 'Tema',
            id: 'Tema'
        },
        core_70: {
            ru: 'Язык',
            en: 'Language',
            tr: 'Dil',
            es: 'Idioma',
            ar: 'لغة',
            de: 'Sprache',
            pt: 'Idioma',
            fr: 'Langue',
            zh: '语言',
            ko: '언어',
            ja: '言語',
            th: 'ภาษา',
            he: 'שפה',
            zh_tw: '語言',
            fil: 'Wika',
            id: 'Bahasa'
        },
        core_71: {
            ru: 'Обновление данных пользователя:',
            en: 'User data update:',
            tr: 'Kullanıcı verileri güncellemesi:',
            es: 'Actualización de datos de usuario:',
            ar: 'تحديث بيانات المستخدم:',
            de: 'Aktualisierung der Benutzerdaten:',
            pt: 'Atualização dos dados do usuário:',
            fr: 'Mise à jour des données utilisateur:',
            zh: '用户数据更新：',
            ko: '사용자 데이터 업데이트:',
            ja: 'ユーザーデータの更新：',
            th: 'การอัปเดตข้อมูลผู้ใช้:',
            he: 'עדכון נתוני המשתמש:',
            zh_tw: '更新用戶數據：',
            fil: 'Pag-update ng data ng User:',
            id: 'Pembaruan data pengguna'
        },
        core_72: (name: any) => {
            return {
                ru: `Доступ для ${name}`,
                en: `Access for ${name}`,
                tr: `${name} için erişim`,
                es: `Acceso para ${name}`,
                ar: `الوصول لـ ${name}`,
                de: `Zugriff für ${name}`,
                pt: `Acesso para ${name}`,
                fr: `Accès pour ${name}`,
                zh: `${name} 的访问权限`,
                ko: `${name}의 접근 권한`,
                ja: `${name} のアクセス権`,
                th: `การเข้าถึงสำหรับ ${name}`,
                he: `גישה ל-${name}`,
                zh_tw: `${name} 的存取權限`,
                fil: `Access para kay ${name}`,
                id: `Akses untuk ${name}`
            }
        },
        core_72_2: {
            ru: 'Заблокировать редактирование',
            en: 'Lock editing',
            tr: 'Düzenlemeyi kilitle',
            es: 'Edición de bloqueo',
            ar: 'تحرير القفل',
            de: 'Bearbeitung sperren',
            pt: 'Bloquear edição',
            fr: "Bloquer l'édition",
            zh: '锁定编辑',
            ko: '편집 잠금',
            ja: '編集のロック',
            th: 'ล็อกการแก้ไข',
            he: 'נעילת עריכה',
            zh_tw: '鎖定編輯',
            fil: 'I-lock ang pag-edit',
            id: 'Kunci pengeditan'
        },
        core_73: {
            ru: 'Доступ:',
            en: 'Access:',
            tr: 'Erişim:',
            es: 'Acceso:',
            ar: 'الوصول:',
            de: 'Zugriff:',
            pt: 'Acesso:',
            fr: 'Accès:',
            zh: '访问：',
            ko: '액세스:',
            ja: 'アクセス：',
            th: 'เข้าถึง:',
            he: 'גישה:',
            zh_tw: '訪問權限：',
            fil: 'Access:',
            id: 'Akses:'
        },
        core_74: {
            ru: 'Спасибо',
            en: 'Thanks',
            tr: 'Teşekkürler',
            es: 'Gracias',
            ar: 'شكرا',
            de: 'Danke',
            pt: 'Obrigado',
            fr: 'Merci',
            zh: '谢谢',
            ko: '감사합니다',
            ja: 'ありがとう',
            th: 'ขอบคุณ',
            he: 'תודה',
            zh_tw: '謝謝',
            fil: 'Salamat',
            id: 'Terima kasih'
        },
        core_75: {
            ru: 'Текущий кейс',
            en: 'Current case',
            tr: 'Mevcut durum',
            es: 'Caso actual',
            ar: 'الحالة الحالية',
            de: 'Aktueller Fall',
            pt: 'Caso atual',
            fr: 'Cas actuel',
            zh: '当前案例',
            ko: '현재 사례',
            ja: '現在のケース',
            th: 'เคสปัจจุบัน',
            he: 'המקרה הנוכחי',
            zh_tw: '當前案例',
            fil: 'Kasalukuyang kaso',
            id: 'Kasus terkini'
        },
        core_76: {
            ru: 'Экспорт',
            en: 'Export',
            tr: 'İhracat',
            es: 'Exportar',
            ar: 'يصدّر',
            de: 'Exportieren',
            pt: 'Exportação',
            fr: 'Exportation',
            zh: '出口',
            ko: '내보내기',
            ja: 'エクスポート',
            th: 'ส่งออก',
            he: 'יצוא',
            zh_tw: '導出',
            fil: 'I-export',
            id: 'Ekspor'
        },
        core_77: {
            ru: 'Выберите профиль для экспорта',
            en: 'Select a profile to export',
            tr: 'Dışa aktarmak için bir profil seçin',
            es: 'Seleccione un perfil para exportar',
            ar: 'حدد ملف تعريف للتصدير',
            de: 'Wählen Sie ein Profil für den Export aus',
            pt: 'Selecione o perfil para exportação',
            fr: 'Sélectionnez le profil à exporter',
            zh: '选择要导出的配置文件',
            ko: '내보낼 프로필 선택',
            ja: 'エクスポートするプロファイルを選択',
            th: 'เลือกโปรไฟล์เพื่อส่งออก',
            he: 'בחר פרופיל ליצוא',
            zh_tw: '選擇要導出的資料檔案',
            fil: 'Pumili ng profil na i-export',
            id: 'Pilih profil yang akan diekspor'
        },
        core_78: {
            ru: 'Язык для экспорта/импорта',
            en: 'Select language to export/import',
            tr: 'Dışa aktarılacak dili seçin',
            es: 'Seleccione el idioma para exportar',
            ar: 'حدد اللغة المراد تصديرها',
            de: 'Sprache für Export / Import',
            pt: 'Idioma de exportação/importação',
            fr: "Langue pour l'exportation / l'importation",
            zh: '选择导出/导入的语言',
            ko: '내보내기/가져오기할 언어 선택',
            ja: 'エクスポート/インポートする言語を選択',
            th: 'เลือกภาษาสำหรับการส่งออก/นำเข้า',
            he: 'בחר שפה ליצוא/יבוא',
            zh_tw: '選擇要導出/導入的語言',
            fil: 'Pumili ng wika para sa export/import',
            id: 'Pilih bahasa yang akan diekspor/diimpor'
        },
        core_79: {
            ru: 'Импорт',
            en: 'Import',
            tr: 'İçe aktarmak',
            es: 'Importar',
            ar: 'يستورد',
            de: 'Importieren',
            pt: 'Importação',
            fr: 'Importation',
            zh: '进口',
            ko: '가져오기',
            ja: 'インポート',
            th: 'นำเข้า',
            he: 'יבוא',
            zh_tw: '導入',
            fil: 'I-import',
            id: 'Impor'
        },
        core_80: {
            ru: 'Изменение профиля:',
            en: 'Changing profile:',
            tr: 'Profil değiştirme:',
            es: 'Cambio de perfil:',
            ar: 'تغيير الملف الشخصي:',
            de: 'Profil ändern:',
            pt: 'Alteração do perfil:',
            fr: 'Changement de profil:',
            zh: '更改配置文件：',
            ko: '프로필 변경:',
            ja: 'プロファイルの変更：',
            th: 'การเปลี่ยนโปรไฟล์:',
            he: 'שינוי פרופיל:',
            zh_tw: '更改資料檔案：',
            fil: 'Pagbabago ng profil:',
            id: 'Mengubah profil:'
        },
        core_81: {
            ru: 'Создано вами',
            en: 'Created by you',
            tr: 'Sizin tarafınızdan yaratıldı',
            es: 'Creado por ti',
            ar: 'تم إنشاؤها من قبلك',
            de: 'Von Ihnen erstellt',
            pt: 'Criado por você',
            fr: 'Créé par vous',
            zh: '由你创建',
            ko: '당신이 만든 것',
            ja: 'あなたが作成した',
            th: 'สร้างโดยคุณ',
            he: 'נוצר על ידך',
            zh_tw: '您創建的',
            fil: 'Nilikha mo',
            id: 'Dibuat oleh Anda'
        },
        core_81_2: {
            ru: 'Посмотреть логи по данному кейсу',
            en: 'View logs for this case',
            tr: 'Bu vaka için günlükleri görüntüle',
            es: 'Ver registros para este caso',
            ar: 'عرض سجلات لهذه الحالة',
            de: 'Sehen Sie sich die Protokolle für diesen Fall an.',
            pt: 'Ver logs deste caso',
            fr: 'Voir les journaux pour ce cas',
            zh: '查看此案例的日志',
            ko: '이 사례에 대한 로그 보기',
            ja: 'このケースのログを表示する',
            th: 'ดูบันทึกสำหรับเคสนี้',
            he: 'הצג יומנים עבור מקרה זה',
            zh_tw: '查看此案例的日誌',
            fil: 'Tingnan ang mga logs para sa kaso na ito',
            id: 'Lihat log untuk kasus ini'
        },
        core_82: {
            ru: 'Верифицирован',
            en: 'Verified',
            tr: 'Doğrulandı',
            es: 'Verificar',
            ar: 'التحقق',
            de: 'Verifiziert',
            pt: 'Verificado',
            fr: 'Vérifié',
            zh: '已验证',
            ko: '확인됨',
            ja: '確認済み',
            th: 'ตรวจสอบแล้ว',
            he: 'מאומת',
            zh_tw: '已驗證',
            fil: 'Naverify',
            id: 'Terverifikasi'
        },
        core_83: {
            ru: 'Не верифицирован',
            en: 'Not verified',
            tr: 'Doğrulanmadı',
            es: 'No verificado',
            ar: 'لم يتم التحقق',
            de: 'Nicht verifiziert',
            pt: 'Não verificado',
            fr: 'Non vérifié',
            zh: '未验证',
            ko: '확인되지 않음',
            ja: '未確認',
            th: 'ยังไม่ได้ตรวจสอบ',
            he: 'לא מאומת',
            zh_tw: '未驗證',
            fil: 'Hindi naverify',
            id: 'Tidak terverifikasi'
        },
        core_84: {
            ru: 'Уверены, что хотите обновить статус верификации?',
            en: 'Are you sure you want to update the verification status?',
            tr: 'Doğrulama durumunu güncellemek istediğinizden emin misiniz?',
            es: '¿Está seguro de que desea actualizar el estado de verificación?',
            ar: 'هل أنت متأكد أنك تريد تحديث حالة التحقق?',
            de: 'Sind Sie sicher, dass Sie den Verifizierungsstatus aktualisieren möchten?',
            pt: 'Tem a certeza de que pretende actualizar o estado de verificação?',
            fr: 'Êtes-vous sûr de vouloir mettre à jour le statut de vérification?',
            zh: '你确定要更新验证状态吗？',
            ko: '확인 상태를 업데이트하시겠습니까?',
            ja: '確認ステータスを更新してもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการอัปเดตสถานะการตรวจสอบ?',
            he: 'האם אתה בטוח שברצונך לעדכן את מצב האימות?',
            zh_tw: '您確定要更新驗證狀態嗎？',
            fil: 'Sigurado ka bang gusto mong i-update ang katayuan ng pag-verify?',
            id: 'Apakah Anda yakin ingin memperbarui status verifikasi?'
        },
        core_85: {
            ru: 'Обновление статуса верификации',
            en: 'Updating the verification status',
            tr: 'Doğrulama durumunu güncelleme',
            es: 'Actualización del estado de verificación',
            ar: 'تحديث حالة التحقق',
            de: 'Aktualisierung des Verifizierungsstatus',
            pt: 'Atualização do status de verificação',
            fr: "Mise à jour de l'état de vérification",
            zh: '正在更新验证状态',
            ko: '확인 상태 업데이트',
            ja: '確認ステータスの更新',
            th: 'กำลังอัปเดตสถานะการตรวจสอบ',
            he: 'עדכון מצב האימות',
            zh_tw: '更新驗證狀態',
            fil: 'Paghahanda ng katayuan ng pag-verify',
            id: 'Memperbarui status verifikasi'
        },
        core_86: {
            ru: 'Кликните для воспроизведения аудио или превью изображения.',
            en: 'Click to listen to the audio or preview the image.',
            tr: 'Sesi dinlemek veya görüntüyü önizlemek için tıklayın.',
            es: 'Haga clic para escuchar el audio o obtener una vista previa de la imagen.',
            ar: 'انقر للاستماع إلى الصوت أو معاينة الصورة.',
            de: 'Klicken Sie hier, um Audio abzuspielen oder ein Bildvorschau anzuzeigen.',
            pt: 'Clique para reproduzir áudio ou visualizar imagem.',
            fr: "Cliquez pour lire l'audio ou la prévisualisation de l'image.",
            zh: '点击收听音频或预览图片。',
            ko: '오디오를 듣거나 이미지를 미리보기하려면 클릭하세요.',
            ja: 'オーディオを聴くか、画像をプレビューするためにクリックしてください。',
            th: 'คลิกเพื่อฟังเสียงหรือแสดงตัวอย่างรูปภาพ',
            he: 'לחץ להאזנה לשמע או תצוגה מקדימה של התמונה.',
            zh_tw: '點擊以收聽音訊或預覽圖片。',
            fil: 'I-click upang makinig sa audio o tingnan ang preview ng larawan.',
            id: 'Klik untuk mendengarkan audio atau pratinjau gambar.'
        },
        core_87: {
            ru: 'Вы уверены, что хотите изменить верифицированный кейс?',
            en: 'Are you sure you want to reset the verification for this case?',
            tr: 'Doğrulanmış vakayı değiştirmek istediğinizden emin misiniz?',
            es: '¿Está seguro de que desea cambiar el caso verificado?',
            ar: 'هل أنت متأكد أنك تريد تغيير الحالة التي تم التحقق منها?',
            de: 'Sind Sie sicher, dass Sie den verifizierten Fall ändern möchten?',
            pt: 'Tem certeza de que deseja alterar o caso verificado?',
            fr: 'Êtes-vous sûr de vouloir modifier le cas vérifié?',
            zh: '您确定要重置此案例的验证吗？',
            ko: '이 사례의 확인을 재설정하시겠습니까?',
            ja: 'このケースの確認をリセットしてもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการรีเซ็ตการตรวจสอบสำหรับเคสนี้?',
            he: 'האם אתה בטוח שתרצה לאפס את האימות למקרה זה?',
            zh_tw: '您確定要重置此案例的驗證嗎？',
            fil: 'Sigurado ka bang gusto mong i-reset ang pag-verify para sa kaso na ito?',
            id: 'Apakah Anda yakin ingin mereset verifikasi untuk kasus ini?'
        },
        core_88: {
            ru: 'При изменении данных все верифицированные кейсы поменяют статус. Вы уверены, что хотите изменить их?',
            en: 'Do you want to clear all verifications?',
            tr: 'Veriler değiştiğinde, doğrulanmış tüm vakalar durumu değiştirir. Onları değiştirmek istediğinize emin misiniz?',
            es: 'Al cambiar los datos, todos los casos verificados cambiarán de estado. ¿Estás seguro de que quieres cambiarlos?',
            ar: 'عندما تتغير البيانات ، ستغير جميع الحالات التي تم التحقق منها حالتها. هل أنت متأكد أنك تريد تغييرها?',
            de: 'Bei Änderung von Daten ändern sich alle verifizierten Fälle. Sind Sie sicher, dass Sie sie ändern möchten?',
            pt: 'Ao alterar os dados, todos os casos verificados terão seu status alterado. Tem certeza de que deseja alterá-los?',
            fr: 'En modifiant les données, tous les cas vérifiés changeront de statut. Êtes-vous sûr de vouloir les modifier?',
            zh: '您是否要清除所有验证？',
            ko: '모든 확인을 지우시겠습니까?',
            ja: 'すべての確認をクリアしますか？',
            th: 'คุณต้องการล้างการตรวจสอบทั้งหมดหรือไม่?',
            he: 'האם ברצונך למחוק את כל האימותים?',
            zh_tw: '您想清除所有驗證嗎？',
            fil: 'Gusto mo bang burahin ang lahat ng mga pag-verify?',
            id: 'Apakah Anda ingin menghapus semua verifikasi?'
        },
        core_89: {
            ru: 'Клиент',
            en: 'Client',
            tr: 'Müşteri',
            es: 'Cliente',
            ar: 'عميل',
            de: 'Kunde',
            pt: 'Cliente',
            fr: 'Client',
            zh: '客户端',
            ko: '클라이언트',
            ja: 'クライアント',
            th: 'ลูกค้า',
            he: 'לקוח',
            zh_tw: '客戶',
            fil: 'Kliyente',
            id: 'Klien'
        },
        core_89_2: {
            ru: 'Пользователь',
            en: 'User',
            tr: 'kullanıcı',
            es: 'Usuario',
            ar: 'مستخدم',
            de: 'Benutzer',
            pt: 'Usuário',
            fr: 'Utilisateur',
            zh: '用户',
            ko: '사용자',
            ja: 'ユーザー',
            th: 'ผู้ใช้',
            he: 'משתמש',
            zh_tw: '用戶',
            fil: 'User',
            id: 'Pengguna'
        },
        core_89_3: {
            ru: 'Переводчик',
            en: 'Translator',
            tr: 'Çevirmen',
            es: 'Traductor',
            ar: 'مترجم',
            de: 'Übersetzer',
            pt: 'Tradutor',
            fr: 'Traducteur',
            zh: '翻译器',
            ko: '번역기',
            ja: '翻訳者',
            th: 'นักแปล',
            he: 'מתרגם',
            zh_tw: '翻譯者',
            fil: 'Tagasalin',
            id: 'Penerjemah'
        },
        core_90: {
            ru: 'Предупреждение',
            en: 'Warning',
            tr: 'Uyarı',
            es: 'Advertencia',
            ar: 'تحذير',
            de: 'Warnung',
            pt: 'Aviso',
            fr: 'Avertissement',
            zh: '警告',
            ko: '경고',
            ja: '警告',
            th: 'คำเตือน',
            he: 'אזהרה',
            zh_tw: '警告',
            fil: 'Babala',
            id: 'Peringatan'
        },
        core_91: {
            ru: 'При изменении поля, аудиофайл станет неактуальным.',
            en: 'If you change the field, the audio file will become irrelevant.',
            tr: 'Alanı değiştirirseniz, ses dosyası geçersiz hale gelir.',
            es: 'Si cambia el campo, el archivo de audio se volverá irrelevante.',
            ar: 'إذا قمت بتغيير الحقل ، سيصبح الملف الصوتي غير ذي صلة.',
            de: 'Bei Feldänderungen wird die Audio-Datei veraltet.',
            pt: 'Ao alterar o campo, o arquivo de áudio se tornará desatualizado.',
            fr: 'Lorsque vous modifiez un champ, le fichier audio devient obsolète.',
            zh: '如果您更改字段，则音频文件将变得不相关。',
            ko: '필드를 변경하면 오디오 파일이 관련 없게 됩니다.',
            ja: 'フィールドを変更すると、オーディオファイルは関連性を失います。',
            th: 'หากคุณเปลี่ยนฟิลด์ ไฟล์เสียงจะไม่สอดคล้องกัน',
            he: 'אם תשנה את השדה, הקובץ השמע יהפוך ללא רלוונטי.',
            zh_tw: '如果您更改該字段，音訊文件將不再相關。',
            fil: 'Kapag binago mo ang patlang, ang audio file ay hindi na magiging kaugnay.',
            id: 'Jika Anda mengubah kolom, file audio akan menjadi tidak relevan.'
        },
        core_92: {
            ru: 'Выберите профиль',
            en: 'Select a profile',
            tr: 'Bir profil seçin',
            es: 'Seleccione un perfil',
            ar: 'حدد ملف تعريف',
            de: 'Profil auswählen',
            pt: 'Selecione o perfil',
            fr: 'Sélectionnez le profil',
            zh: '选择个人资料',
            ko: '프로필 선택',
            ja: 'プロファイルを選択してください',
            th: 'เลือกโปรไฟล์',
            he: 'בחר פרופיל',
            zh_tw: '選擇資料檔案',
            fil: 'Pumili ng profil',
            id: 'Pilih profil'
        },
        core_93: {
            ru: 'Выберите',
            en: 'Select',
            tr: 'Seçme',
            es: 'Seleccionar',
            ar: 'يختار',
            de: 'Wähle aus',
            pt: 'Selecione',
            fr: 'Sélectionnez',
            zh: '选择',
            ko: '선택',
            ja: '選択',
            th: 'เลือก',
            he: 'בחר',
            zh_tw: '選擇',
            fil: 'Pumili',
            id: 'Pilih'
        },
        core_94: {
            ru: 'Выберите язык для переводчика.',
            en: 'Select a language for the translator.',
            tr: 'Çevirmen için bir dil seçin.',
            es: 'Seleccione un idioma para el traductor.',
            ar: 'حدد لغة للمترجم.',
            de: 'Wählen Sie die Sprache für den Übersetzer.',
            pt: 'Selecione o idioma para o tradutor.',
            fr: 'Sélectionnez la langue pour le traducteur.',
            zh: '选择翻译器语言。',
            ko: '번역기용 언어 선택.',
            ja: '翻訳者のための言語を選択してください。',
            th: 'เลือกภาษาสำหรับนักแปล',
            he: 'בחר שפה למתרגם.',
            zh_tw: '選擇翻譯者的語言。',
            fil: 'Pumili ng wika para sa tagasalin.',
            id: 'Pilih bahasa untuk penerjemah.'
        },
        core_95: {
            ru: 'Безлимит',
            en: 'Unlimited',
            tr: 'Sınırsız',
            es: 'Ilimitado',
            ar: 'غير محدود',
            de: 'Unbegrenzt',
            pt: 'Ilimitado',
            fr: 'Illimité',
            zh: '无限制',
            ko: '무제한',
            ja: '無制限',
            th: 'ไม่จำกัด',
            he: 'בלתי מוגבל',
            zh_tw: '無限制',
            fil: 'Walang limitasyon',
            id: 'Tidak terbatas'
        },
        core_96: {
            ru: 'Верификация',
            en: 'Verification',
            tr: 'Doğrulama',
            es: 'Verificación',
            ar: 'التحقق',
            de: 'Verifizierung',
            pt: 'Verificação',
            fr: 'Vérification',
            zh: '验证',
            ko: '확인',
            ja: '確認',
            th: 'การตรวจสอบ',
            he: 'אימות',
            zh_tw: '驗證',
            fil: 'Pag-verify',
            id: 'Verifikasi'
        },
        core_97: {
            ru: 'Кейсы',
            en: 'Cases',
            tr: 'Vakalar',
            es: 'Casos',
            ar: 'حالات',
            de: 'Fälle',
            pt: 'Casos',
            fr: 'Cas',
            zh: '案例',
            ko: '사례',
            ja: 'ケース',
            th: 'เคส',
            he: 'מקרים',
            zh_tw: '案例',
            fil: 'Mga Kaso',
            id: 'Kasus'
        },
        core_98: {
            ru: 'Лимит',
            en: 'Limit',
            tr: 'Sınır',
            es: 'Limite',
            ar: 'الحد',
            de: 'Begrenzung',
            pt: 'Limite',
            fr: 'Limite',
            zh: '限制',
            ko: '제한',
            ja: '制限',
            th: 'จำกัด',
            he: 'מגבלה',
            zh_tw: '限制',
            fil: 'Limitasyon',
            id: 'Batas'
        },
        core_99: {
            ru: 'Остаток',
            en: 'Remains',
            tr: 'Kalıntılar',
            es: 'Restos',
            ar: 'بقايا',
            de: 'Restbetrag',
            pt: 'Restante',
            fr: 'Solde',
            zh: '剩余',
            ko: '남음',
            ja: '残り',
            th: 'เหลืออยู่',
            he: 'נותר',
            zh_tw: '剩餘',
            fil: 'Natitirang Bilang',
            id: 'Sisa'
        },
        core_100: {
            ru: 'Изменить на текущем языке',
            en: 'Clear on current language',
            tr: 'Tercih edilen dile değiştir',
            es: 'Cambiar al idioma preferido',
            ar: 'التغيير إلى اللغة المفضلة',
            de: 'Auf der aktuellen Sprache ändern',
            pt: 'Alterar no idioma atual',
            fr: 'Modifier dans la langue actuelle',
            zh: '在当前语言上清除',
            ko: '현재 언어로 지우기',
            ja: '現在の言語でクリア',
            th: 'ล้างในภาษาปัจจุบัน',
            he: 'נקה בשפה הנוכחית',
            zh_tw: '在當前語言上清除',
            fil: 'Burahin sa kasalukuyang wika',
            id: 'Hapus pada bahasa saat ini'
        },
        core_101: {
            ru: 'Изменить на всех языках',
            en: 'Clear on all languages',
            tr: 'Tüm dillerde değiştir',
            es: 'Cambio en Todos los Idiomas',
            ar: 'تغيير في جميع اللغات',
            de: 'Auf allen Sprachen ändern',
            pt: 'Alterar em todos os idiomas',
            fr: 'Modifier dans toutes les langues',
            zh: '在所有语言上清除',
            ko: '모든 언어에서 지우기',
            ja: 'すべての言語でクリア',
            th: 'ล้างในทุกภาษา',
            he: 'נקה בכל השפות',
            zh_tw: '在所有語言上清除',
            fil: 'Burahin sa lahat ng wika',
            id: 'Hapus pada semua bahasa'
        },
        core_102: {
            ru: 'Изменить пароль',
            en: 'Change password',
            tr: 'Şifreyi değiştir',
            es: 'Cambiar contraseña',
            ar: 'تغيير كلمة المرور',
            de: 'Passwort ändern',
            pt: 'Alterar senha',
            fr: 'Changer de mot de passe',
            zh: '更改密码',
            ko: '비밀번호 변경',
            ja: 'パスワードの変更',
            th: 'เปลี่ยนรหัสผ่าน',
            he: 'שנה סיסמה',
            zh_tw: '更改密碼',
            fil: 'Baguhin ang password',
            id: 'Ganti kata sandi'
        },
        core_103: {
            ru: 'Словарь',
            en: 'Vocabulary',
            tr: 'Sözlük',
            es: 'Diccionario',
            ar: 'قاموس',
            de: 'Wörterbuch',
            pt: 'Dicionário',
            fr: 'Dictionnaire',
            zh: '词汇表',
            ko: '어휘',
            ja: '語彙',
            th: 'ศัพท์ภาษา',
            he: 'אוצר מילים',
            zh_tw: '詞彙',
            fil: 'Bokabularyo',
            id: 'Kosakata'
        },
        core_104: {
            ru: 'Смержить',
            en: 'Merge',
            tr: 'Birleştirmek',
            es: 'Fusionar',
            ar: 'دمج',
            de: 'Zusammenführen',
            pt: 'Unir',
            fr: 'Fusionner',
            zh: '合并',
            ko: '병합',
            ja: 'マージ',
            th: 'รวม',
            he: 'מזג',
            zh_tw: '合併',
            fil: 'Pagsasama',
            id: 'Gabung'
        },
        core_105: {
            ru: 'Все текстовые данные будут объединены со словарем. Процесс может занять несколько минут.',
            en: 'All text data will be combined with the dictionary. The process may take several minutes.',
            tr: 'Tüm metin verileri sözlükle birleştirilecektir. İşlem birkaç dakika sürebilir.',
            es: 'Todos los datos de texto se combinarán con el diccionario. El proceso puede tardar varios minutos.',
            ar: 'سيتم دمج جميع البيانات النصية مع القاموس. قد تستغرق العملية عدة دقائق.',
            de: 'Alle Textdaten werden mit dem Wörterbuch zusammengeführt. Der Vorgang kann einige Minuten dauern.',
            pt: 'Todos os dados de texto serão unidos ao dicionário. O processo pode levar vários minutos.',
            fr: 'Toutes les données textuelles seront fusionnées avec le dictionnaire. Le processus peut prendre plusieurs minutes.',
            zh: '所有文本数据将与字典合并。该过程可能需要几分钟。',
            ko: '모든 텍스트 데이터가 사전과 결합됩니다. 프로세스는 몇 분 정도 소요될 수 있습니다.',
            ja: 'すべてのテキストデータは辞書と統合されます。このプロセスには数分かかる場合があります。',
            th: 'ข้อมูลข้อความทั้งหมดจะถูกผสมกับพจนานุกรม กระบวนการอาจใช้เวลาหลายนาที',
            he: 'כל הנתונים הטקסטואליים יתאחדו עם המילון. התהליך עשוי לקחת מספר דקות.',
            zh_tw: '所有文本數據將與字典結合。此過程可能需要幾分鐘。',
            fil: 'Ang lahat ng teksto ay magsasama-sama sa diksiyonaryo. Ang proseso ay maaaring tumagal ng ilang minuto.',
            id: 'Semua data teks akan digabungkan dengan kamus. Proses ini mungkin memakan waktu beberapa menit.'
        },
        core_106: {
            ru: 'Ok',
            en: 'Ok',
            tr: 'Ok',
            es: 'Ok',
            ar: 'حسنا',
            de: 'Ok',
            pt: 'Ok',
            fr: "D'accord",
            zh: '确定',
            ko: '확인',
            ja: 'OK',
            th: 'ตกลง',
            he: 'אוקיי',
            zh_tw: '確定',
            fil: 'Ok',
            id: 'Oke'
        },
        core_107: {
            ru: 'Доступные языки:',
            en: 'Available languages:',
            tr: 'Mevcut diller:',
            es: 'Idiomas disponibles:',
            ar: 'اللغات المتوفرة:',
            de: 'Verfügbare Sprachen:',
            pt: 'Idioma:',
            fr: 'Langues disponibles:',
            zh: '可用语言：',
            ko: '사용 가능한 언어:',
            ja: '利用可能な言語：',
            th: 'ภาษาที่มีให้เลือก:',
            he: 'שפות זמינות:',
            zh_tw: '可用語言：',
            fil: 'Mga available na wika:',
            id: 'Bahasa yang tersedia:'
        },
        core_108: {
            ru: 'Список файлов',
            en: 'List of files',
            tr: 'Dosyaların listesi',
            es: 'Lista de archivos',
            ar: 'قائمة الملفات',
            de: 'Liste der Dateien',
            pt: 'Lista de ficheiros',
            fr: 'Liste des fichiers',
            zh: '文件列表',
            ko: '파일 목록',
            ja: 'ファイルの一覧',
            th: 'รายการไฟล์',
            he: 'רשימת קבצים',
            zh_tw: '文件列表',
            fil: 'Listahan ng mga file',
            id: 'Daftar file'
        },
        core_109: {
            ru: 'Превью',
            en: 'Preview',
            tr: 'Önizleme',
            es: 'Previo',
            ar: 'معاينة',
            de: 'Vorschau',
            pt: 'Visualizacao',
            fr: 'Aperçu',
            zh: '预览',
            ko: '미리보기',
            ja: 'プレビュー',
            th: 'ดูตัวอย่าง',
            he: 'תצוגה מקדימה',
            zh_tw: '預覽',
            fil: 'Preview',
            id: 'Pratinjau'
        },
        core_110: {
            ru: 'Уверены, что хотите заменить файл?',
            en: 'Are you sure you want to replace the file?',
            tr: 'Dosyayı değiştirmek istediğinize emin misiniz?',
            es: '¿Está seguro de que desea reemplazar el archivo?',
            ar: 'هل أنت متأكد أنك تريد استبدال الملف?',
            de: 'Sind Sie sicher, dass Sie die Datei ersetzen möchten?',
            pt: 'Tem a certeza de que pretende substituir o ficheiro?',
            fr: 'Êtes-vous sûr de vouloir remplacer le fichier?',
            zh: '您确定要替换文件吗？',
            ko: '파일을 교체하시겠습니까?',
            ja: 'ファイルを置き換えてもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการแทนที่ไฟล์?',
            he: 'האם אתה בטוח שברצונך להחליף את הקובץ?',
            zh_tw: '您確定要替換文件嗎？',
            fil: 'Sigurado ka bang gusto mong palitan ang file?',
            id: 'Anda yakin ingin mengganti file?'
        },
        core_111: {
            ru: 'Верифицировать кейсы',
            en: 'Verify cases',
            tr: 'Vakaları doğrulayın',
            es: 'Verificar casos',
            ar: 'التحقق من الحالات',
            de: 'Fälle verifizieren',
            pt: 'Verificar casos',
            fr: 'Vérifier les cas',
            zh: '验证案例',
            ko: '사례 확인',
            ja: 'ケースの確認',
            th: 'ตรวจสอบเคส',
            he: 'אמת מקרים',
            zh_tw: '驗證案例',
            fil: 'I-verify ang mga kaso',
            id: 'Verifikasi kasus'
        },
        core_112: {
            ru: 'Код',
            en: 'Code',
            tr: 'Kod',
            es: 'Código',
            ar: 'كود',
            de: 'Codes',
            pt: 'Código',
            fr: 'Code',
            zh: '密码',
            ko: '코드',
            ja: 'コード',
            th: 'รหัส',
            he: 'קוד',
            zh_tw: '代碼',
            fil: 'Code',
            id: 'Kode'
        },
        core_113: {
            ru: 'Название',
            en: 'Name',
            tr: 'İsim',
            es: 'Nombre',
            ar: 'الاسم',
            de: 'Name',
            pt: 'Nome',
            fr: 'Nom',
            zh: '名称',
            ko: '이름',
            ja: '名前',
            th: 'ชื่อ',
            he: 'שם',
            zh_tw: '名稱',
            fil: 'Pangalan',
            id: 'Nama'
        },
        core_114: {
            ru: 'Не изменять верификацию',
            en: 'Don`t change the verification',
            tr: 'Doğrulamayı değiştirmeyin',
            es: 'No cambie la verificación',
            ar: 'لا تقم بتغيير التحقق',
            de: 'Ändern Sie die Verifizierung nicht',
            pt: 'Não altere a verificação',
            fr: 'Ne modifiez pas la vérification',
            zh: '不要更改验证',
            ko: '확인을 변경하지 마세요.',
            ja: '確認を変更しないでください',
            th: 'อย่าเปลี่ยนการตรวจสอบ',
            he: 'אל תשנה את האימות',
            zh_tw: '不要更改驗證',
            fil: 'Huwag baguhin ang pag-verify',
            id: 'Jangan ubah verifikasi'
        },
        core_115: {
            ru: 'Скопировать кейсы',
            en: 'Copy cases',
            tr: 'Davaları kopyala',
            es: 'Casos de copia',
            ar: 'نسخ الحالات',
            de: 'Kopierhüllen',
            pt: 'Casos de cópia',
            fr: 'Cas de copie',
            zh: '复制病例',
            ko: '사례 복사',
            ja: 'ケースをコピーする',
            th: 'คัดลอกเคส',
            he: 'העתק מקרים',
            zh_tw: '複製案例',
            fil: 'Kopyahin ang mga kaso',
            id: 'Salin kasus'
        },
        core_116: {
            ru: 'Выберите профиль, кейсы которого хотите скопировать',
            en: 'Select the profile whose cases you want to copy',
            tr: 'Vakalarını kopyalamak istediğiniz profili seçin',
            es: 'Seleccione el perfil cuyos casos desea copiar',
            ar: 'حدد ملف التعريف الذي تريد نسخ حالاته',
            de: 'Wählen Sie das Profil aus, dessen Fälle Sie kopieren möchten',
            pt: 'Selecione o perfil cujos casos deseja copiar',
            fr: 'Sélectionnez le profil dont vous souhaitez copier les dossiers',
            zh: '选择您要复制病例的档案',
            ko: '사례를 복사하려는 프로필을 선택하세요.',
            ja: 'ケースをコピーしたいプロファイルを選択してください',
            th: 'เลือกโปรไฟล์ที่ต้องการคัดลอกเคส',
            he: 'בחר את הפרופיל שממנו ברצונך להעתיק מקרים',
            zh_tw: '選擇您要複製的資料檔案的案例',
            fil: 'Pumili ng profil na nais mong kopyahin ang mga kaso nito',
            id: 'Pilih profil yang kasusnya ingin Anda salin'
        },
        core_117: {
            ru: 'Пожалуйста, заполните описание файла',
            en: 'Please fill in the file description',
            tr: 'Lütfen dosya açıklamasını doldurun',
            es: 'Por favor, rellene la descripción del archivo',
            ar: 'يرجى ملء وصف الملف',
            de: 'Bitte geben Sie die Dateibeschreibung ein',
            pt: 'Por favor preencha a descrição do ficheiro',
            fr: 'Veuillez remplir la description du fichier',
            zh: '请填写文件说明',
            ko: '파일 설명을 작성하세요.',
            ja: 'ファイルの説明を入力してください',
            th: 'โปรดกรอกคำอธิบายของไฟล์',
            he: 'אנא מלא את תיאור הקובץ',
            zh_tw: '請填寫文件描述',
            fil: 'Pakipunan ang paglalarawan ng file',
            id: 'Harap isi deskripsi file'
        },
        core_118: {
            ru: 'Отправка письма при сохранении',
            en: 'Sending email when saving',
            tr: 'Kaydederken e-posta gönderme',
            es: 'Kaydederken e-posta gönderme',
            ar: 'إرسال بريد إلكتروني عند الحفظ',
            de: 'Senden einer E-Mail beim Speichern',
            pt: 'Enviando um e-mail ao salvar',
            fr: "Envoi d'un e-mail lors de l'enregistrement",
            zh: '保存时发送电子邮件',
            ko: '저장 시 이메일 발송',
            ja: '保存時にメールを送信',
            th: 'ส่งอีเมลเมื่อบันทึก',
            he: 'שליחת אימייל בעת שמירה',
            zh_tw: '保存時發送電子郵件',
            fil: 'Pagpapadala ng email kapag nag-save',
            id: 'Mengirim email saat menyimpan'
        },
        core_119: {
            ru: 'Отправить письмо',
            en: 'Send email',
            tr: 'E-posta gönder',
            es: 'Enviar correo electrónico',
            ar: 'إرسال بريد إلكتروني',
            de: 'E-Mail senden',
            pt: 'Enviar e-mail',
            fr: 'Envoyer un e-mail',
            zh: '发送电子邮件',
            ko: '이메일 발송',
            ja: 'メールを送信',
            th: 'ส่งอีเมล',
            he: 'שלח אימייל',
            zh_tw: '發送電子郵件',
            fil: 'Ipadala ang email',
            id: 'Kirim email'
        },
        core_120: {
            ru: 'Выбранный формат файла не поддерживается',
            en: 'The selected file format is not supported',
            tr: 'Seçilen dosya formatı desteklenmiyor',
            es: 'El formato de archivo seleccionado no es compatible',
            ar: 'تنسيق الملف المحدد غير مدعوم',
            de: 'Das ausgewählte Dateiformat wird nicht unterstützt',
            pt: 'O formato de arquivo selecionado não é suportado',
            fr: "Le format de fichier sélectionné n'est pas pris en charge",
            zh: '不支持所选的文件格式',
            ko: '선택한 파일 형식은 지원되지 않습니다.',
            ja: '選択したファイル形式はサポートされていません',
            th: 'รูปแบบไฟล์ที่เลือกไม่ได้รับการสนับสนุน',
            he: 'הפורמט של הקובץ הנבחר אינו נתמך',
            zh_tw: '不支持選定的文件格式',
            fil: 'Ang napiling format ng file ay hindi suportado',
            id: 'Format file yang dipilih tidak didukung'
        },
        core_121: {
            ru: 'Промпт',
            en: 'Prompt',
            tr: 'Prompt',
            es: 'Prompt',
            ar: 'Prompt',
            de: 'Prompt',
            pt: 'Prompt',
            fr: 'Prompt',
            zh: 'Prompt',
            ko: '프롬프트',
            ja: 'プロンプト',
            th: 'คำใบ้',
            he: 'רמז',
            zh_tw: '提示',
            fil: 'Prompt',
            id: 'Petunjuk'
        },
        core_122: {
            ru: 'Переведено на',
            en: 'Translated to',
            tr: 'Çevrildi',
            es: 'Traducido a',
            ar: 'تمت الترجمة إلى',
            de: 'Übersetzt in',
            pt: 'Traduzido para',
            fr: 'Traduit en',
            zh: '已翻译为',
            ko: '번역 대상',
            ja: '翻訳先',
            th: 'แปลเป็น',
            he: 'מתורגם ל',
            zh_tw: '翻譯成',
            fil: 'Isinalin sa',
            id: 'Diterjemahkan ke'
        },
        core_123: {
            ru: 'Роль пользователя',
            en: 'User role',
            tr: 'Kullanıcı rolü',
            es: 'Rol de usuario',
            ar: 'دور المستخدم',
            de: 'Benutzerrolle',
            pt: 'Função do usuário',
            fr: "Rôle de l'utilisateur",
            zh: '用户角色',
            ko: '사용자 역할',
            ja: 'ユーザーの役割',
            th: 'บทบาทของผู้ใช้',
            he: 'תפקיד המשתמש',
            zh_tw: '用戶角色',
            fil: 'Role ng User',
            id: 'Peran pengguna'
        },
        core_124: {
            ru: 'Дата создания',
            en: 'Creation date',
            tr: 'Oluşturma tarihi',
            es: 'Fecha de creación',
            ar: 'تاريخ الإنشاء',
            de: 'Erstellungsdatum',
            pt: 'Data de criação',
            fr: 'Date de création',
            zh: '创建日期',
            ko: '생성 날짜',
            ja: '作成日',
            th: 'วันที่สร้าง',
            he: 'תאריך יצירה',
            zh_tw: '創建日期',
            fil: 'Petsa ng Paglikha',
            id: 'Tanggal pembuatan'
        },
        core_125: {
            ru: 'Дата активации',
            en: 'Activation date',
            tr: 'Aktivasyon tarihi',
            es: 'Fecha de activación',
            ar: 'تاريخ التفعيل',
            de: 'Aktivierungsdatum',
            pt: 'Data de ativação',
            fr: "Date d'activation",
            zh: '激活日期',
            ko: '활성화 날짜',
            ja: 'アクティベーション日',
            th: 'วันที่เปิดใช้งาน',
            he: 'תאריך הפעלה',
            zh_tw: '激活日期',
            fil: 'Petsa ng Aktibasyon',
            id: 'Tanggal aktivasi'
        },
        core_126: {
            ru: 'Перевести',
            en: 'Translate',
            tr: 'Çevir',
            es: 'Traducir',
            ar: 'ترجمة',
            de: 'Übersetzen',
            pt: 'Traduzir',
            fr: 'Traduire',
            zh: '翻译',
            ko: '번역하다',
            ja: '翻訳する',
            th: 'แปล',
            he: 'תרגם',
            zh_tw: '翻譯',
            fil: 'Isalin',
            id: 'Terjemahkan'
        },
        core_127: {
            ru: 'Информация для VR',
            en: 'Information for VR',
            tr: 'VR için bilgi',
            es: 'Información para VR',
            ar: 'معلومات للواقع الافتراضي',
            de: 'Informationen für VR',
            pt: 'Informações para VR',
            fr: 'Informations pour la réalité virtuelle',
            zh: 'VR信息',
            ko: 'VR용 정보',
            ja: 'VR用の情報',
            th: 'ข้อมูลสำหรับ VR',
            he: 'מידע עבור VR',
            zh_tw: 'VR資訊',
            fil: 'Impormasyon para sa VR',
            id: 'Informasi untuk VR'
        },
        core_128: {
            ru: 'Информация для Desktop',
            en: 'Information for Desktop',
            tr: 'Masaüstü için bilgi',
            es: 'Información para escritorio',
            ar: 'معلومات لسطح المكتب',
            de: 'Informationen für den Desktop',
            pt: 'Informações para desktop',
            fr: 'Informations pour le bureau',
            zh: '桌面信息',
            ko: '데스크톱용 정보',
            ja: 'デスクトップ用の情報',
            th: 'ข้อมูลสำหรับเดสก์ท็อป',
            he: 'מידע עבור שולחן העבודה',
            zh_tw: '桌面資訊',
            fil: 'Impormasyon para sa Desktop',
            id: 'Informasi untuk Desktop'
        },
        core_129: {
            ru: 'Выберите файл',
            en: 'Choose file',
            tr: 'Dosya seç',
            es: 'Seleccionar archivo',
            ar: 'اختر ملف',
            de: 'Datei auswählen',
            pt: 'Escolher arquivo',
            fr: 'Choisir un fichier',
            zh: '选择文件',
            ko: '파일 선택',
            ja: 'ファイルを選択',
            th: 'เลือกไฟล์',
            he: 'בחר קובץ',
            zh_tw: '選擇文件',
            fil: 'Piliin ang file',
            id: 'Pilih file'
        },
        core_130: {
            ru: 'Файл не выбран',
            en: 'File not selected',
            tr: 'Dosya seçilmedi',
            es: 'Archivo no seleccionado',
            ar: 'لم يتم تحديد الملف',
            de: 'Datei nicht ausgewählt',
            pt: 'Arquivo não selecionado',
            fr: 'Fichier non sélectionné',
            zh: '文件未选择',
            ko: '파일이 선택되지 않았습니다',
            ja: 'ファイルが選択されていません',
            th: 'ไฟล์ไม่ได้ถูกเลือก',
            he: 'קובץ לא נבחר',
            zh_tw: '文件未選擇',
            fil: 'Hindi napili ang file',
            id: 'File tidak dipilih'
        },
        core_131: {
            ru: 'XR-Clinic',
            en: 'XR-Clinic',
            tr: 'XR-Clinic',
            es: 'XR-Clinic',
            ar: 'XR-Clinic',
            de: 'XR-Clinic',
            pt: 'XR-Clinic',
            fr: 'XR-Clinic',
            zh: 'XR-Clinic',
            ko: 'XR-Clinic',
            ja: 'XR-Clinic',
            th: 'XR-Clinic',
            he: 'XR-Clinic',
            zh_tw: 'XR-Clinic',
            fil: 'XR-Clinic',
            id: 'XR-Clinic'
        },
        core_132: {
            ru: 'MVR Nursing',
            en: 'MVR Nursing',
            tr: 'MVR Nursing',
            es: 'MVR Nursing',
            ar: 'MVR Nursing',
            de: 'MVR Nursing',
            pt: 'MVR Nursing',
            fr: 'MVR Nursing',
            zh: 'MVR Nursing',
            ko: 'MVR Nursing',
            ja: 'MVR Nursing',
            th: 'MVR Nursing',
            he: 'MVR Nursing',
            zh_tw: 'MVR Nursing',
            fil: 'MVR Nursing',
            id: 'MVR Nursing'
        },
        core_133: {
            ru: 'СМАРТ ЦЕНТР',
            en: 'SMART CENTER',
            tr: 'SMART CENTER',
            es: 'SMART CENTER',
            ar: 'SMART CENTER',
            de: 'SMART CENTER',
            pt: 'SMART CENTER',
            fr: 'SMART CENTER',
            zh: 'SMART CENTER',
            ko: 'SMART CENTER',
            ja: 'SMART CENTER',
            th: 'SMART CENTER',
            he: 'SMART CENTER',
            zh_tw: 'SMART CENTER',
            fil: 'SMART CENTER',
            id: 'SMART CENTER'
        },
        core_134: {
            ru: 'Изображение',
        },
        core_135: {
            ru: 'Надпись',
        },
        core_136: {
            ru: 'Индекс занят',
            en: 'Index is busy',
        },
        core_137: {
            ru: 'Симулятор',
            en: 'Simulator',
        },
        log_1: [
            {
                key: 'cases',
                ru: 'Кейсы',
                en: 'Cases',
                tr: 'Vakalar',
                es: 'Casos',
                ar: 'الحالات',
                de: 'Fälle',
                pt: 'Casos',
                fr: 'Cas',
                zh: '案例',
                ko: '케이스',
                ja: 'ケース',
                th: 'กรณี',
                he: 'תיקים',
                zh_tw: '案例',
                fil: 'Mga Kaso',
                id: 'Kasus'
            },
            {
                key: 'drugs',
                ru: 'Препараты',
                en: 'Medications',
                tr: 'İlaçlar',
                es: 'Medicamentos',
                ar: 'الأدوية',
                de: 'Medikamente',
                pt: 'Medicamentos',
                fr: 'Médicaments',
                zh: '药物',
                ko: '약물',
                ja: '薬',
                th: 'ยา',
                he: 'תרופות',
                zh_tw: '藥物',
                fil: 'Mga Gamot',
                id: 'Pengobatan'
            },
            {
                key: 'names',
                ru: 'Имена',
                en: 'Names',
                tr: 'İsimler',
                es: 'Nombres',
                ar: 'الأسماء',
                de: 'Namen',
                pt: 'Nomes',
                fr: 'Noms',
                zh: '名称',
                ko: '이름',
                ja: '名前',
                th: 'ชื่อ',
                he: 'שמות',
                zh_tw: '名稱',
                fil: 'Mga Pangalan',
                id: 'Nama'
            },
            {
                key: 'passport',
                ru: 'Паспорт',
                en: 'Passport',
                tr: 'Pasaport',
                es: 'Pasaporte',
                ar: 'جواز السفر',
                de: 'Reisepass',
                pt: 'Passaporte',
                fr: 'Passeport',
                zh: '护照',
                ko: '여권',
                ja: 'パスポート',
                th: 'หนังสือเดินทาง',
                he: 'דרכון',
                zh_tw: '護照',
                fil: 'Passport',
                id: 'Paspor'
            },
            {
                key: 'dialogue',
                ru: 'Диалоги',
                en: 'Dialogs',
                tr: 'Diyaloglar',
                es: 'Diálogos',
                ar: 'الحوارات',
                de: 'Dialoge',
                pt: 'Diálogos',
                fr: 'Dialogues',
                zh: '对话',
                ko: '대화',
                ja: 'ダイアログ',
                th: 'การสนทนา',
                he: 'דיאלוגים',
                zh_tw: '對話',
                fil: 'Mga Dialogo',
                id: 'Dialog'
            },
            {
                key: 'diagnosis',
                ru: 'Диагнозы',
                en: 'Diagnoses',
                tr: 'Tanılar',
                es: 'Diagnósticos',
                ar: 'التشخيصات',
                de: 'Diagnosen',
                pt: 'Diagnósticos',
                fr: 'Diagnostics',
                zh: '诊断',
                ko: '진단',
                ja: '診断',
                th: 'การวินิจฉัย',
                he: 'אבחנות',
                zh_tw: '診斷',
                fil: 'Mga Diagnosis',
                id: 'Diagnosis'
            },
            {
                key: 'inspection',
                ru: 'Осмотр',
                en: 'Examination',
                tr: 'Muayene',
                es: 'Examen',
                ar: 'الفحص',
                de: 'Untersuchung',
                pt: 'Exame',
                fr: 'Examen',
                zh: '检查',
                ko: '검사',
                ja: '検査',
                th: 'การตรวจ',
                he: 'בדיקה',
                zh_tw: '檢查',
                fil: 'Pagsusuri',
                id: 'Pemeriksaan'
            },
            {
                key: 'examination',
                ru: 'Доп. обследования',
                en: 'Additional Examinations',
                tr: 'Ek Muayeneler',
                es: 'Exámenes adicionales',
                ar: 'فحوصات إضافية',
                de: 'Zusätzliche Untersuchungen',
                pt: 'Exames adicionais',
                fr: 'Examens supplémentaires',
                zh: '附加检查',
                ko: '추가 검사',
                ja: '追加検査',
                th: 'การตรวจเพิ่มเติม',
                he: 'בדיקות נוספות',
                zh_tw: '附加檢查',
                fil: 'Karagdagang Pagsusuri',
                id: 'Pemeriksaan Tambahan'
            },
            {
                key: 'treatment',
                ru: 'Лечение',
                en: 'Treatment',
                tr: 'Tedavi',
                es: 'Tratamiento',
                ar: 'العلاج',
                de: 'Behandlung',
                pt: 'Tratamento',
                fr: 'Traitement',
                zh: '治疗',
                ko: '치료',
                ja: '治療',
                th: 'การรักษา',
                he: 'טיפול',
                zh_tw: '治療',
                fil: 'Paggamot',
                id: 'Pengobatan'
            },
            {
                key: 'other',
                ru: 'Остальное',
                en: 'Other',
                tr: 'Diğer',
                es: 'Otros',
                ar: 'أخرى',
                de: 'Andere',
                pt: 'Outros',
                fr: 'Autre',
                zh: '其他',
                ko: '기타',
                ja: 'その他',
                th: 'อื่น ๆ',
                he: 'אחר',
                zh_tw: '其他',
                fil: 'Iba pa',
                id: 'Lainnya'
            },
            {
                key: 'users',
                ru: 'Пользователи',
                en: 'Users',
                tr: 'Kullanıcılar',
                es: 'Usuarios',
                ar: 'المستخدمين',
                de: 'Benutzer',
                pt: 'Usuários',
                fr: 'Utilisateurs',
                zh: '用户',
                ko: '사용자',
                ja: 'ユーザー',
                th: 'ผู้ใช้',
                he: 'משתמשים',
                zh_tw: '使用者',
                fil: 'Mga User',
                id: 'Pengguna'
            },
            {
                key: 'profile',
                ru: 'Профили',
                en: 'Profiles',
                tr: 'Profiller',
                es: 'Perfiles',
                ar: 'البروفايلات',
                de: 'Profile',
                pt: 'Perfis',
                fr: 'Profils',
                zh: '个人资料',
                ko: '프로필',
                ja: 'プロフィール',
                th: 'โปรไฟล์',
                he: 'פרופילים',
                zh_tw: '個人資料',
                fil: 'Mga Profil',
                id: 'Profil'
            }
        ],
        log_2: {
            ru: 'Предыдущее значение',
            en: 'Previous value',
            tr: 'Önceki değer',
            es: 'Valor anterior',
            ar: 'الاسم',
            de: 'Vorheriger Wert',
            pt: 'Valor anterior',
            fr: 'Valeur précédente',
            zh: '先前的值',
            ko: '이전 값',
            ja: '以前の値',
            th: 'ค่าก่อนหน้า',
            he: 'ערך קודם',
            zh_tw: '前一個值',
            fil: 'Naunang halaga',
            id: 'Nilai sebelumnya'
        },
        log_3: {
            ru: 'Новое значение',
            en: 'New value',
            tr: 'Yeni değer',
            es: 'Nuevo valor',
            ar: 'القيمة السابقه',
            de: 'Neuer Wert',
            pt: 'Novo valor',
            fr: 'Nouvelle valeur',
            zh: '新值',
            ko: '새 값',
            ja: '新しい値',
            th: 'ค่าใหม่',
            he: 'ערך חדש',
            zh_tw: '新值',
            fil: 'Bagong halaga',
            id: 'Nilai baru'
        },
        log_4: {
            ru: 'Язык',
            en: 'Language',
            tr: 'Diller',
            es: 'Idiomas',
            ar: 'قيمة جديدة',
            de: 'Sprache',
            pt: 'Idioma',
            fr: 'Langue',
            zh: '语言',
            ko: '언어',
            ja: '言語',
            th: 'ภาษา',
            he: 'שפה',
            zh_tw: '語言',
            fil: 'Wika',
            id: 'Bahasa'
        },
        log_5: {
            ru: 'Информация',
            en: 'Info',
            tr: 'Bilgi',
            es: 'Información',
            ar: 'معلومة',
            de: 'Information',
            pt: 'Informação',
            fr: 'Information',
            zh: '信息',
            ko: '정보',
            ja: '情報',
            th: 'ข้อมูล',
            he: 'מידע',
            zh_tw: '信息',
            fil: 'Impormasyon',
            id: 'Info'
        },
        log_6: {
            ru: 'Обновить данные',
            en: 'Update the data',
            tr: 'Verileri güncelle',
            es: 'Actualizar los datos',
            ar: 'تحديث البيانات',
            de: 'Daten aktualisieren',
            pt: 'Atualizar dados',
            fr: 'Mettre à jour les données',
            zh: '更新数据',
            ko: '데이터 업데이트',
            ja: 'データを更新する',
            th: 'อัปเดตข้อมูล',
            he: 'עדכן את הנתונים',
            zh_tw: '更新數據',
            fil: 'I-update ang data',
            id: 'Perbarui data'
        },
        log_7: {
            ru: 'Применить',
            en: 'Apply',
            tr: 'Uygulamak',
            es: 'Aplicar',
            ar: 'تقديم',
            de: 'Anwenden',
            pt: 'Aplicar',
            fr: 'Appliquer',
            zh: '应用',
            ko: '적용',
            ja: '適用',
            th: 'นำไปใช้',
            he: 'החל',
            zh_tw: '應用',
            fil: 'I-apply',
            id: 'Terapkan'
        },
        log_8: {
            ru: 'Сбросить',
            en: 'Reset',
            tr: 'Sıfırlamak',
            es: 'Restablecer',
            ar: 'إعادة ضبط',
            de: 'Zurücksetzen',
            pt: 'Redefinir',
            fr: 'Réinitialiser',
            zh: '重置',
            ko: '재설정',
            ja: 'リセット',
            th: 'รีเซ็ต',
            he: 'אפס',
            zh_tw: '重置',
            fil: 'I-reset',
            id: 'Atur ulang'
        },
        log_9: (name: string) => {
            switch (name) {
                case 'category':
                    return lang.info.core_31;
                case 'user':
                    return lang.info.core_89_2;
                case 'date':
                    return lang.info.core_65;
                case 'std':
                    return lang.info.core_137;
                case 'curCase':
                    return lang.info.log_12;
                case 'pack':
                    return lang.info.core_62;
                case 'prevValue':
                    return lang.info.log_2;
                case 'newValue':
                    return lang.info.log_3;
                case 'lang':
                    return lang.info.log_4;
                case 'info':
                    return lang.info.log_5;
                case 'verify':
                    return lang.info.core_96;
            }
        },
        log_10: (flag: boolean) => {
            if (flag) {
                return {
                    ru: 'Добавлены:',
                    en: 'Added:',
                    tr: 'Eklenen',
                    es: 'Añadido',
                    ar: 'تم الإضافة',
                    de: 'Hinzugefügt',
                    pt: 'Adicionado',
                    fr: 'Ajouté',
                    zh: '已添加',
                    ko: '추가됨',
                    ja: '追加された',
                    th: 'เพิ่มเข้า',
                    he: 'נוסף',
                    zh_tw: '已新增',
                    fil: 'Idinagdag:',
                    id: 'Ditambahkan:'
                }
            } else {
                return {
                    ru: 'Удалены:',
                    en: 'Removed:',
                    tr: 'Kaldırılan',
                    es: 'Eliminado',
                    ar: 'تم الحذف',
                    de: 'Entfernt',
                    pt: 'Removido',
                    fr: 'Retiré',
                    zh: '已移除',
                    ko: '제거됨',
                    ja: '削除された',
                    th: 'ถูกลบออก',
                    he: 'הוסר',
                    zh_tw: '已移除',
                    fil: 'Inalis:',
                    id: 'Dihapus:'
                }
            }
        },
        log_11: {
            ru: 'Отменить изменения',
            en: 'Undo changes',
            tr: 'Değişiklikleri geri al',
            es: 'Deshacer cambios',
            ar: 'التراجع عن التغييرات',
            de: 'Änderungen rückgängig machen',
            pt: 'Cancelar alterações',
            fr: 'Annuler les modifications',
            zh: '撤消更改',
            ko: '변경 내용 취소',
            ja: '変更を元に戻す',
            th: 'ยกเลิกการเปลี่ยนแปลง',
            he: 'בטל שינויים',
            zh_tw: '撤銷更改',
            fil: 'I-undo ang mga pagbabago',
            id: 'Batalkan perubahan'
        },
        log_12: {
            ru: 'Кейс',
            en: 'Case',
            tr: 'Vakalar',
            es: 'Caso',
            ar: 'حالات',
            de: 'Fall',
            pt: 'Caso',
            fr: 'Cas',
            zh: '案例',
            ko: '사례',
            ja: 'ケース',
            th: 'เคส',
            he: 'מקרה',
            zh_tw: '案例',
            fil: 'Kaso',
            id: 'Kasus'
        },
        log_13: {
            verify: [
                {
                    ru: 'Верифицирован',
                    en: 'Verified',
                    tr: 'Doğrulandı',
                    es: 'Verificado',
                    ar: 'تم التحقق',
                    de: 'Überprüft',
                    pt: 'Verificado',
                    fr: 'Vérifié',
                    zh: '已验证',
                    ko: '확인됨',
                    ja: '確認済み',
                    th: 'ได้รับการยืนยัน',
                    he: 'אומת',
                    zh_tw: '已驗證',
                    fil: 'Naverify',
                    id: 'Terverifikasi'
                },
                {
                    ru: 'Не верифицирован',
                    en: 'Not verified',
                    tr: 'Doğrulanmadı',
                    es: 'No verificado',
                    ar: 'لم يتم التحقق',
                    de: 'Nicht überprüft',
                    pt: 'Não verificado',
                    fr: 'Non vérifié',
                    zh: '未验证',
                    ko: '확인되지 않음',
                    ja: '未確認',
                    th: 'ไม่ได้รับการยืนยัน',
                    he: 'לא אומת',
                    zh_tw: '未驗證',
                    fil: 'Hindi naverify',
                    id: 'Belum diverifikasi'
                }
            ],
            std: ['original', 'international', 'service']
        },
        auth_1: {
            ru: 'Почта',
            en: 'Email',
            tr: 'E-posta',
            es: 'Email',
            ar: 'البريد الإلكتروني',
            de: 'E-Mail',
            pt: 'E-mail',
            fr: 'Email',
            zh: '电子邮件',
            ko: '이메일',
            ja: 'メール',
            th: 'อีเมล',
            he: 'אימייל',
            zh_tw: '電子郵件',
            fil: 'Email',
            id: 'Email'
        },
        auth_2: {
            ru: 'Пароль',
            en: 'Password',
            tr: 'Şifre',
            es: 'Contraseña',
            ar: 'كلمة المرور',
            de: 'Passwort',
            pt: 'Senha',
            fr: 'Mot de passe',
            zh: '密码',
            ko: '비밀번호',
            ja: 'パスワード',
            th: 'รหัสผ่าน',
            he: 'סיסמה',
            zh_tw: '密碼',
            fil: 'Password',
            id: 'Kata sandi'
        },
        auth_3: {
            ru: 'Войти',
            en: 'Sign in',
            tr: 'Kayıt olmak',
            es: 'Iniciar sesión',
            ar: 'الدخول',
            de: 'Einloggen',
            pt: 'Entrar',
            fr: 'Se connecter',
            zh: '登录',
            ko: '로그인',
            ja: 'サインイン',
            th: 'เข้าสู่ระบบ',
            he: 'התחבר',
            zh_tw: '登錄',
            fil: 'Mag-sign in',
            id: 'Masuk'
        },
        auth_4: {
            ru: 'Выйти',
            en: 'Log out',
            tr: 'Çıkış Yap',
            es: 'Cerrar sesión',
            ar: 'الخروج',
            de: 'Ausloggen',
            pt: 'Sair',
            fr: 'Se déconnecter',
            zh: '退出',
            ko: '로그아웃',
            ja: 'ログアウト',
            th: 'ออกจากระบบ',
            he: 'התנתק',
            zh_tw: '登出',
            fil: 'Mag-log out',
            id: 'Keluar'
        },
        auth_5: {
            ru: 'Запомнить данные для входа.',
            en: 'Remember login details.',
            tr: 'Oturum açma ayrıntılarını hatırla.',
            es: 'Recuerde los datos de inicio de sesión.',
            ar: 'تذكر تفاصيل تسجيل الدخول.',
            de: 'Einloggdten Daten speichern.',
            pt: 'Lembrar dados de login.',
            fr: 'Mémoriser les informations de connexion.',
            zh: '记住登录详细信息。',
            ko: '로그인 정보 저장.',
            ja: 'ログイン情報を記憶する',
            th: 'จำรายละเอียดการเข้าสู่ระบบ',
            he: 'זכור פרטי ההתחברות',
            zh_tw: '記住登錄詳細信息。',
            fil: 'Tandaan ang mga detalye ng login.',
            id: 'Ingat detail login.'
        },
        auth_6: {
            ru: 'Показать пароль',
            en: 'Show password',
            tr: 'Şifreyi göster',
            es: 'Mostrar contraseña',
            ar: 'إظهار كلمة المرور',
            de: 'Passwort anzeigen',
            pt: 'Mostrar senha',
            fr: 'Afficher le mot de passe',
            zh: '显示密码',
            ko: '비밀번호 표시',
            ja: 'パスワードを表示する',
            th: 'แสดงรหัสผ่าน',
            he: 'הצג סיסמה',
            zh_tw: '顯示密碼',
            fil: 'Ipakita ang password',
            id: 'Tampilkan kata sandi'
        },
        auth_7: {
            ru: 'Дополнительная почта',
            en: 'Additional email',
            tr: 'Ek e-posta',
            es: 'Correo electrónico adicional',
            ar: 'بريد إلكتروني إضافي',
            de: 'Zusätzliche E-Mail',
            pt: 'E-mail adicional',
            fr: 'Courriel supplémentaire',
            zh: '附加邮件',
            ko: '추가 이메일',
            ja: '追加のメール',
            th: 'อีเมลเพิ่มเติม',
            he: 'דואר אלקטרוני נוסף',
            zh_tw: '附加郵件',
            fil: 'Karagdagang email',
            id: 'Email tambahan'
        },
        recovery_1: {
            ru: 'Новый пароль',
            en: 'New password',
            tr: 'Yeni şifre',
            es: 'Nueva contraseña',
            ar: 'كلمة السر الجديدة',
            de: 'Neues Passwort',
            pt: 'Nova senha',
            fr: 'Nouveau mot de passe',
            zh: '新密码',
            ko: '새 비밀번호',
            ja: '新しいパスワード',
            th: 'รหัสผ่านใหม่',
            he: 'סיסמה חדשה',
            zh_tw: '新密碼',
            fil: 'Bagong password',
            id: 'Kata sandi baru'
        },
        recovery_2: {
            ru: 'Старый пароль',
            en: 'Old password',
            tr: 'Eski şifre',
            es: 'Contraseña anterior',
            ar: 'كلمة المرور القديمة',
            de: 'Altes Passwort',
            pt: 'Senha antiga',
            fr: 'Ancien mot de passe',
            zh: '旧密码',
            ko: '이전 비밀번호',
            ja: '古いパスワード',
            th: 'รหัสผ่านเดิม',
            he: 'סיסמה ישנה',
            zh_tw: '舊密碼',
            fil: 'Lumang password',
            id: 'Kata sandi lama'
        },
        recovery_3: {
            ru: 'Ваш пароль успешно изменен.',
            en: 'Your password success change.',
            tr: 'Şifre başarı değişikliği.',
            es: 'Su cambio de contraseña con éxito.',
            ar: 'تغيير نجاح كلمة المرور الخاصة بك.',
            de: 'Ihr Passwort wurde erfolgreich geändert.',
            pt: 'Sua senha foi alterada com sucesso.',
            fr: 'Votre mot de passe a été modifié avec succès.',
            zh: '您的密码已成功更改。',
            ko: '비밀번호 변경이 성공했습니다.',
            ja: 'パスワードの変更に成功しました。',
            th: 'รหัสผ่านของคุณเปลี่ยนแปลงสำเร็จ',
            he: 'שינוי הסיסמה הצליח.',
            zh_tw: '您的密碼已成功更改。',
            fil: 'Matagumpay na nabago ang iyong password.',
            id: 'Kata sandi Anda berhasil diubah.'
        },
        recovery_5: {
            ru: 'Придумайте пароль',
            en: 'Come up with a password',
            tr: 'Bir şifre ile gel',
            es: 'Crea una contraseña',
            ar: 'تعال مع كلمة مرور',
            de: 'Überlegen Sie sich ein Passwort',
            pt: 'Crie uma senha',
            fr: 'Trouvez un mot de passe',
            zh: '想一个密码',
            ko: '비밀번호를 설정하세요.',
            ja: 'パスワードを考えてください',
            th: 'คิดรหัสผ่าน',
            he: 'חשוב על סיסמה',
            zh_tw: '創建一個密碼',
            fil: 'Mag-isip ng isang password',
            id: 'Buat kata sandi'
        },
        recovery_6: {
            ru: 'Повторите пароль',
            en: 'Repeat password',
            tr: 'Şifreyi tekrarla',
            es: 'Repetir contraseña',
            ar: 'كرر كلمة المرور',
            de: 'Passwort wiederholen',
            pt: 'Repetir palavra-passe',
            fr: 'Répéter le mot de passe',
            zh: '重复密码',
            ko: '비밀번호 반복 입력',
            ja: 'パスワードの繰り返し',
            th: 'ทำซ้ำรหัสผ่าน',
            he: 'חזור על הסיסמה',
            zh_tw: '重複密碼',
            fil: 'Ulitin ang password',
            id: 'Ulang kata sandi'
        },
        outpatient_1: {
            ru: 'Выбрать категорию назначения.',
            en: 'Select destination category.',
            tr: 'Hedef kategorisini seçin.',
            es: 'Selecciona la categoría de destino.',
            ar: 'حدد فئة الوجهة.',
            de: 'Sélectionnez la catégorie de destination.',
            pt: 'Selecione a categoria de destino.',
            fr: 'Wählen Sie eine Zielkategorie aus.',
            zh: '选择目标类别。',
            ko: '대상 카테고리 선택.',
            ja: '宛先カテゴリを選択してください。',
            th: 'เลือกหมวดหมู่ปลายทาง',
            he: 'בחר קטגוריה מטרה',
            zh_tw: '選擇目標類別。',
            fil: 'Pumili ng kategoryang patutunguhan.',
            id: 'Pilih kategori tujuan.'
        },
        outpatient_2: {
            ru: 'Копирование клинического случая.',
            en: 'Copying a clinical case.',
            tr: 'Klinik bir vakayı kopyalamak.',
            es: 'Copiar un caso clínico.',
            ar: 'نسخ حالة سريرية.',
            de: 'Kopieren des klinischen Falls.',
            pt: 'Copia do caso clínico.',
            fr: 'Copie du cas clinique.',
            zh: '复制临床案例。',
            ko: '임상 사례 복사.',
            ja: '臨床症例をコピーしています。',
            th: 'ก๊อปปี้เคสทางคลินิก',
            he: 'העתקת מקרה קליני',
            zh_tw: '複製臨床病例。',
            fil: 'Pagkopya ng klinikal na kaso.',
            id: 'Menyalin kasus klinis.'
        },
        outpatient_3: {
            ru: 'Создание новой категории.',
            en: 'Create a new category.',
            tr: 'Yeni bir kategori oluşturun.',
            es: 'Crear una nueva categoría.',
            ar: 'قم بإنشاء فئة جديدة.',
            de: 'Erstellen einer neuen Kategorie.',
            pt: 'Criação de nova categoria.',
            fr: "Création d'une nouvelle catégorie.",
            zh: '创建新类别。',
            ko: '새로운 카테고리 생성.',
            ja: '新しいカテゴリを作成します。',
            th: 'สร้างหมวดหมู่ใหม่',
            he: 'צור קטגוריה חדשה',
            zh_tw: '創建一個新類別。',
            fil: 'Lumikha ng bagong kategorya.',
            id: 'Buat kategori baru.'
        },
        outpatient_4: {
            ru: 'XR-Clinic',
            en: 'XR-Clinic',
            tr: 'XR-Clinic',
            es: 'XR-Clinic',
            ar: 'XR-Clinic',
            de: 'XR-Clinic',
            pt: 'XR-Clinic',
            fr: 'XR-Clinic',
            zh: 'XR-Clinic',
            ko: 'XR-Clinic',
            ja: 'XR-Clinic',
            th: 'XR-Clinic',
            he: 'XR-Clinic',
            zh_tw: 'XR-Clinic',
            fil: 'XR-Clinic',
            id: 'XR-Clinic',
        },
        outpatient_5: {
            ru: 'Категория:',
            en: 'Category:',
            tr: 'Kategori:',
            es: 'Categoría:',
            ar: 'فئة:',
            de: 'Kategorie:',
            pt: 'Categoria:',
            fr: 'Catégorie:',
            zh: '类别：',
            ko: '카테고리:',
            ja: 'カテゴリ：',
            th: 'หมวดหมู่:',
            he: 'קטגוריה:',
            zh_tw: '類別：',
            fil: 'Kategorya:',
            id: 'Kategori:'
        },
        outpatient_6: {
            ru: 'Работа',
            en: 'Work',
            tr: 'İş',
            es: 'Trabajo',
            ar: 'عمل',
            de: 'Arbeit',
            pt: 'Trabalho',
            fr: 'Travail',
            zh: '工作',
            ko: '작업',
            ja: '作業',
            th: 'งาน',
            he: 'עבודה',
            zh_tw: '工作',
            fil: 'Trabaho',
            id: 'Pekerjaan'
        },
        outpatient_7: {
            ru: 'Имя пациента:',
            en: 'Patient name:',
            tr: 'Hasta adı:',
            es: 'Nombre del paciente:',
            ar: 'اسم المريض:',
            de: 'Patientenname:',
            pt: 'Nome do paciente:',
            fr: 'Nom du patient:',
            zh: '患者姓名：',
            ko: '환자 이름:',
            ja: '患者名：',
            th: 'ชื่อผู้ป่วย:',
            he: 'שם החולה:',
            zh_tw: '患者姓名：',
            fil: 'Pangalan ng Pasyente:',
            id: 'Nama pasien:'
        },
        outpatient_8: {
            ru: 'Раса',
            en: 'Race',
            tr: 'Yarış',
            es: 'Raza',
            ar: 'سلالة',
            de: 'Rasse',
            pt: 'Raça',
            fr: 'Race',
            zh: '种族',
            ko: '인종',
            ja: '人種',
            th: 'เชื้อชาติ',
            he: 'גזע',
            zh_tw: '種族',
            fil: 'Lahi',
            id: 'Ras'
        },
        outpatient_9: {
            ru: 'Гендер:',
            en: 'Gender:',
            tr: 'Cinsiyet:',
            es: 'Género:',
            ar: 'جنس:',
            de: 'Geschlecht:',
            pt: 'Gênero:',
            fr: 'Genre:',
            zh: '性别：',
            ko: '성별:',
            ja: '性別：',
            th: 'เพศ:',
            he: 'מגדר:',
            zh_tw: '性別：',
            fil: 'Kasarian:',
            id: 'Jenis kelamin:'
        },
        outpatient_10: {
            ru: 'Фото:',
            en: 'Photo:',
            tr: 'Fotoğraf:',
            es: 'Foto:',
            ar: 'صورة:',
            de: 'Foto:',
            pt: 'Foto:',
            fr: 'Photo:',
            zh: '照片：',
            ko: '사진:',
            ja: '写真：',
            th: 'รูปถ่าย:',
            he: 'תמונה:',
            zh_tw: '照片：',
            fil: 'Larawan:',
            id: 'Foto:'
        },
        outpatient_11: {
            ru: 'Создание работы',
            en: 'Create a job',
            tr: 'Bir iş oluştur',
            es: 'Crear un trabajo',
            ar: 'إنشاء وظيفة',
            de: 'Arbeit erstellen',
            pt: 'Criação de trabalho',
            fr: "Création d'un emploi",
            zh: '创建职位',
            ko: '작업 생성',
            ja: '仕事を作成する',
            th: 'สร้างงาน',
            he: 'צור עבודה',
            zh_tw: '創建工作',
            fil: 'Lumikha ng trabaho',
            id: 'Buat pekerjaan'
        },
        outpatient_12: {
            ru: 'Создание имени пациента',
            en: 'Create a patient name',
            tr: 'Bir hasta adı oluşturun',
            es: 'Crear un nombre de paciente',
            ar: 'إنشاء اسم مريض',
            de: 'Patientennamen erstellen',
            pt: 'Criação de nome do paciente',
            fr: 'Création du nom du patient',
            zh: '创建病人姓名',
            ko: '환자 이름 생성',
            ja: '患者名を作成する',
            th: 'สร้างชื่อผู้ป่วย',
            he: 'צור שם לחולה',
            zh_tw: '創建患者名稱',
            fil: 'Lumikha ng pangalan ng pasyente',
            id: 'Buat nama pasien'
        },
        outpatient_13: {
            ru: 'Мужчина',
            en: 'Man',
            tr: 'Erkek adam',
            es: 'Hombre',
            ar: 'ذكر',
            de: 'Mann',
            pt: 'Masculino',
            fr: 'Homme',
            zh: '男性',
            ko: '남성',
            ja: '男性',
            th: 'ชาย',
            he: 'גבר',
            zh_tw: '男',
            fil: 'Lalaki',
            id: 'Pria'
        },
        outpatient_14: {
            ru: 'Женщина',
            en: 'Woman',
            tr: 'Kadın',
            es: 'Mujer',
            ar: 'أنثى',
            de: 'Frau',
            pt: 'Feminino',
            fr: 'Femme',
            zh: '女性',
            ko: '여성',
            ja: '女性',
            th: 'หญิง',
            he: 'אישה',
            zh_tw: '女',
            fil: 'Babae',
            id: 'Wanita'
        },
        outpatient_17: {
            ru: 'Для отображения контента, нужно выбрать клинический случай из меню.',
            en: 'To display content, you need to select a case from menu.',
            tr: 'İçeriği görüntülemek için menüden bir vaka seçmeniz gerekir.',
            es: 'Para que se muestre el contenido, debes seleccionar un caso en el menú.',
            ar: 'لعرض المحتوى ، تحتاج إلى تحديد حالة سريرية من القائمة.',
            de: 'Um Inhalte anzuzeigen, müssen Sie einen klinischen Fall aus dem Menü auswählen.',
            pt: 'Para exibir o conteúdo, é necessário selecionar um caso clínico no menu.',
            fr: 'Pour afficher le contenu, vous devez sélectionner le cas clinique dans le menu.',
            zh: '要显示内容，需要从菜单中选择一个案例。',
            ko: '콘텐츠를 표시하려면 메뉴에서 사례를 선택해야 합니다.',
            ja: 'コンテンツを表示するには、メニューからケースを選択する必要があります。',
            th: 'เพื่อแสดงเนื้อหา คุณต้องเลือกเคสจากเมนู',
            he: 'להצגת התוכן, עליך לבחור מקרה מהתפריט.',
            zh_tw: '要顯示內容，您需要從菜單中選擇一個案例。',
            fil: 'Upang maipakita ang nilalaman, kailangan mong pumili ng kaso mula sa menu.',
            id: 'Untuk menampilkan konten, Anda perlu memilih kasus dari menu.'
        },
        outpatient_18: {
            ru: 'Вопрос',
            en: 'Question',
            tr: 'Soru',
            es: 'Pregunta',
            ar: 'سؤال',
            de: 'Frage',
            pt: 'Pergunta',
            fr: 'Question',
            zh: '问题',
            ko: '질문',
            ja: '質問',
            th: 'คำถาม',
            he: 'שאלה',
            zh_tw: '問題',
            fil: 'Tanong',
            id: 'Pertanyaan'
        },
        outpatient_19: {
            ru: 'Ответ',
            en: 'Answer',
            tr: 'Cevap',
            es: 'Respuesta',
            ar: 'إجابه',
            de: 'Antwort',
            pt: 'Resposta',
            fr: 'Réponse',
            zh: '答案',
            ko: '답변',
            ja: '回答',
            th: 'คำตอบ',
            he: 'תשובה',
            zh_tw: '答案',
            fil: 'Sagot',
            id: 'Jawaban'
        },
        outpatient_20: {
            ru: 'Запись в историю',
            en: 'History entry',
            tr: 'Geçmiş girişi',
            es: 'Historial de entradas',
            ar: 'تسجيل في التاريخ',
            de: 'In der Geschichte aufzeichnen',
            pt: 'Registro no histórico',
            fr: "Enregistrement dans l'histoire",
            zh: '历史记录条目',
            ko: '기록 항목',
            ja: '履歴エントリ',
            th: 'รายการประวัติศาสตร์',
            he: 'רשומת היסטוריה',
            zh_tw: '歷史條目',
            fil: 'Kasaysayan ng pagpasok',
            id: 'Riwayat entri'
        },
        outpatient_22: {
            ru: 'Звук',
            en: 'Sound',
            tr: 'Ses',
            es: 'Sonido',
            ar: 'صوت',
            de: 'Ton',
            pt: 'Som',
            fr: 'Son',
            zh: '声音',
            ko: '음성',
            ja: '音',
            th: 'เสียง',
            he: 'צליל',
            zh_tw: '聲音',
            fil: 'Tunog',
            id: 'Suara'
        },
        outpatient_23: {
            ru: "+'",
            en: "+'",
            tr: "+'",
            es: "+'",
            ar: "+'",
            de: "+'",
            pt: "+'",
            fr: "+'",
            zh: "+'",
            ko: "+'",
            ja: "+'",
            th: "+'",
            he: "+'",
            zh_tw: "+'",
            fil: "+'",
            id: "+'"
        },
        outpatient_24: {
            ru: '-',
            en: '-',
            tr: '-',
            es: '-',
            ar: '-',
            de: '-',
            pt: '-',
            fr: '-',
            zh: '-',
            ko: '-',
            ja: '-',
            th: '-',
            he: '-',
            zh_tw: '-',
            fil: '-',
            id: '-'
        },
        outpatient_25: {
            ru: 'Условие',
            en: 'Condition',
            tr: 'Şart',
            es: 'Condiciones',
            ar: 'حالة',
            de: 'Bedingung',
            pt: 'Condição',
            fr: 'Condition',
            zh: '条件',
            ko: '상태',
            ja: '状態',
            th: 'สภาพ',
            he: 'מצב',
            zh_tw: '條件',
            fil: 'Kalagayan',
            id: 'Kondisi'
        },
        outpatient_26: {
            Complaints: {
                name: {
                    ru: 'Жалобы',
                    en: 'Complaints',
                    tr: 'Şikayetler',
                    es: 'Quejas',
                    ar: 'شكاوى',
                    de: 'Beschwerden',
                    pt: 'Queixas',
                    fr: 'Plaintes',
                    zh: '投诉',
                    ko: '불만',
                    ja: '苦情',
                    th: 'คำร้องเรียน',
                    he: 'תלונות',
                    zh_tw: '投訴',
                    fil: 'Mga reklamo',
                    id: 'Keluhan'
                },
                title: {
                    ru: 'Жалобы',
                    en: 'Complaints',
                    tr: 'Şikayetler',
                    es: 'Quejas',
                    ar: 'شكاوى',
                    de: 'Beschwerden',
                    pt: 'Queixas',
                    fr: 'Plaintes',
                    zh: '投诉',
                    ko: '불만',
                    ja: '苦情',
                    th: 'คำร้องเรียน',
                    he: 'תלונות',
                    zh_tw: '投訴',
                    fil: 'Reklamo',
                    id: 'Keluhan'
                }
            },
            AD: {
                name: {
                    ru: 'А.З.',
                    en: 'AD',
                    tr: 'H.A.',
                    es: 'Anamnesis de la enfermedad',
                    ar: 'تاريخ المرض',
                    de: 'Krankheitsanamnese',
                    pt: 'Anamnese da doença',
                    fr: 'Anamnèse de la maladie',
                    zh: '疾病病史',
                    ko: '질병의 과거력',
                    ja: '疾患の既往歴',
                    th: 'ประวัติโรค',
                    he: 'אנמנזה של המחלה',
                    zh_tw: '疾病病史',
                    fil: 'AD',
                    id: 'AD'
                },
                title: {
                    ru: 'Анамнез заболевания',
                    en: 'Anamnesis of the disease',
                    tr: 'Hastalık anamnezi',
                    es: 'Anamnesis de la enfermedad',
                    ar: 'تاريخ المرض',
                    de: 'Krankheitsanamnese',
                    pt: 'Anamnese da doença',
                    fr: 'Anamnèse de la maladie',
                    zh: '疾病病史',
                    ko: '질병의 과거력',
                    ja: '疾患の既往歴',
                    th: 'ประวัติโรค',
                    he: 'אנמנזה של המחלה',
                    zh_tw: '疾病病史',
                    fil: 'Anamnesis ng sakit',
                    id: 'Anamnesis penyakit'
                }
            },
            AL: {
                name: {
                    ru: 'А.Ж.',
                    en: 'Anamnesis of life',
                    tr: 'Yaşam anamnezi',
                    es: 'Anamnesis de la vida',
                    ar: 'تاريخ الحياة',
                    de: 'Lebensanamnese',
                    pt: 'Anamnese de vida',
                    fr: 'Anamnèse de vie',
                    zh: '生活史',
                    ko: '생애 과거력',
                    ja: '生活の既往歴',
                    th: 'ประวัติชีวิต',
                    he: 'אנמנזה של החיים',
                    zh_tw: '生活史',
                    fil: 'Anamnesis ng buhay',
                    id: 'Riwayat Anamnesis'
                },
                title: {
                    ru: 'Анамнез жизни',
                    en: 'Anamnesis of life',
                    tr: 'Yaşam anamnezi',
                    es: 'Anamnesis de la vida',
                    ar: 'تاريخ الحياة',
                    de: 'Lebensanamnese',
                    pt: 'Anamnese de vida',
                    fr: 'Anamnèse de vie',
                    zh: '生活史',
                    ko: '생애 과거력',
                    ja: '生活の既往歴',
                    th: 'ประวัติชีวิต',
                    he: 'אנמנזה של החיים',
                    zh_tw: '生活史',
                    fil: 'Anamnesis ng buhay',
                    id: 'Riwayat Anamnesis'
                }
            },
            AE: {
                name: {
                    ru: 'А.Э.',
                    en: 'Epid anamnesis',
                    tr: 'Epidemiyolojik anamnez',
                    es: 'Anamnesis epidémica',
                    ar: 'تاريخ وبائي',
                    de: 'Epidemische Anamnese',
                    pt: 'Anamnese epidemiológica',
                    fr: 'Anamnèse épidémiologique',
                    zh: '流行病史',
                    ko: '전염병 과거력',
                    ja: '疫学的既往歴',
                    th: 'ประวัติโรคระบาด',
                    he: 'אנמנזה אפידמיולוגית',
                    zh_tw: '流行病史',
                    fil: 'Epidemiological anamnesis',
                    id: 'Anamnesis epidemologi'
                },
                title: {
                    ru: 'Эпидемиологический анамнез',
                    en: 'Epidemiological history',
                    tr: 'Epidemiyolojik öykü',
                    es: 'Historial epidemiológico',
                    ar: 'التاريخ الوبائي',
                    de: 'Epidemiologische Anamnese',
                    pt: 'Histórico epidemiológico',
                    fr: 'Historique épidémiologique',
                    zh: '流行病学历史',
                    ko: '역학적 과거력',
                    ja: '疫学的履歴',
                    th: 'ประวัติโรคระบาด',
                    he: 'היסטוריה אפידמיולוגית',
                    zh_tw: '流行病學歷史',
                    fil: 'Epidemiological history',
                    id: 'Riwayat epidemiologi'
                }
            }
        },
        outpatient_27: {
            ru: 'Перкуссия',
            en: 'Percussion',
            tr: 'Perküsyon',
            es: 'Percusión',
            ar: 'قرع',
            de: 'Perkussion',
            pt: 'Percussão',
            fr: 'Percussion',
            zh: '叩诊',
            ko: '타진',
            ja: '叩打',
            th: 'การตีแปรง',
            he: 'פרקושן',
            zh_tw: '叩擊',
            fil: 'Pagtadyak',
            id: 'Perkusi'
        },
        outpatient_28: {
            ru: 'Аускультация',
            en: 'Auscultation',
            tr: 'Oskültasyon',
            es: 'Auscultación',
            ar: 'تسمع',
            de: 'Auskultation',
            pt: 'Auscultação',
            fr: 'Auscultation',
            zh: '听诊',
            ko: '청진',
            ja: '聴診',
            th: 'การฟังเสียง',
            he: 'אוסקולטציה',
            zh_tw: '聽診',
            fil: 'Pagsusuri sa pamamagitang ng pakikiramdam',
            id: 'Auskultasi'
        },
        outpatient_29: {
            ru: 'Пальпация',
            en: 'Palpation',
            tr: 'Palpasyon',
            es: 'Palpación',
            ar: 'جس',
            de: 'Palpation',
            pt: 'Palpação',
            fr: 'Palpation',
            zh: '触诊',
            ko: '촉진',
            ja: '触診',
            th: 'การสัมผัส',
            he: 'פלפציה',
            zh_tw: '觸診',
            fil: 'Pagpapalpasa',
            id: 'Palpasi'
        },
        outpatient_30: {
            ru: 'Визуальный',
            en: 'Visual',
            tr: 'Görsel',
            es: 'Visual',
            ar: 'مرئية',
            de: 'Visuelle',
            pt: 'Visual',
            fr: 'Visuel',
            zh: '视觉',
            ko: '시각적',
            ja: '視覚',
            th: 'ภาพ',
            he: 'חזותי',
            zh_tw: '視覺',
            fil: 'Pagsusuri sa paningin',
            id: 'Visual'
        },
        outpatient_31: {
            ru: 'Инструменты',
            en: 'Instruments',
            tr: 'Enstrümanlar',
            es: 'Instrumentos',
            ar: 'الادوات',
            de: 'Werkzeuge',
            pt: 'Ferramentas',
            fr: 'Outils',
            zh: '仪器',
            ko: '기구',
            ja: '器具',
            th: 'เครื่องมือ',
            he: 'כלים',
            zh_tw: '儀器',
            fil: 'Mga instrumento',
            id: 'Alat'
        },
        outpatient_32: {
            ru: 'Трактовка',
            en: 'Interpretation',
            tr: 'Tercüme',
            es: 'Interpretación',
            ar: 'تفسير',
            de: 'Interpretation',
            pt: 'Interpretação',
            fr: 'Interprétation',
            zh: '解释',
            ko: '해석',
            ja: '解釈',
            th: 'การตีความ',
            he: 'פרשנות',
            zh_tw: '解釋',
            fil: 'Interpretasyon',
            id: 'Interpretasi'
        },
        outpatient_33: {
            ru: 'Добавить новый диагноз',
            en: 'Add new diagnosis',
            tr: 'Yeni teşhis ekle',
            es: 'Añadir nuevo diagnóstico',
            ar: 'أضف تشخيصًا جديدًا',
            de: 'Neue Diagnose hinzufügen',
            pt: 'Adicionar novo diagnóstico',
            fr: 'Ajouter un nouveau diagnostic',
            zh: '添加新诊断',
            ko: '새로운 진단 추가',
            ja: '新しい診断を追加する',
            th: 'เพิ่มการวินิจฉัยใหม่',
            he: 'הוסף אבחנה חדשה',
            zh_tw: '新增診斷',
            fil: 'Magdagdag ng bagong diagnosis',
            id: 'Tambahkan diagnosis baru'
        },
        outpatient_34: {
            ru: 'Препарат',
            en: 'A drug',
            tr: 'İlaç',
            es: 'Del medicamento',
            ar: 'دواء',
            de: 'Arzneimittel',
            pt: 'Remédio',
            fr: 'Médicament',
            zh: '一种药物',
            ko: '약물',
            ja: '薬',
            th: 'ยา',
            he: 'תרופה',
            zh_tw: '藥物',
            fil: 'Isang gamot',
            id: 'Obat'
        },
        outpatient_35: {
            ru: 'Форма',
            en: 'Form',
            tr: 'Form',
            es: 'Forma',
            ar: 'شكل',
            de: 'Form',
            pt: 'Forma',
            fr: 'Formulaire',
            zh: '形式',
            ko: '형태',
            ja: '形態',
            th: 'รูปแบบ',
            he: 'צורה',
            zh_tw: '形式',
            fil: 'Anyo',
            id: 'Bentuk obat'
        },
        outpatient_36: {
            ru: 'Путь введения',
            en: 'Way of introduce',
            tr: 'Tanıtma yolu',
            es: 'Método de introducción',
            ar: 'طريقة التقديم',
            de: 'Verabreichungsweg',
            pt: 'Via de administração',
            fr: "Voie d'administration",
            zh: '引入方式',
            ko: '투여 방법',
            ja: '投与方法',
            th: 'วิธีการใช้',
            he: 'דרך הגשה',
            zh_tw: '使用方式',
            fil: 'Paraan ng pagbibigay',
            id: 'Cara pemberian'
        },
        outpatient_37: {
            ru: 'Кратность приема',
            en: 'Multiplicity of reception',
            tr: 'Resepsiyon çokluğu',
            es: 'Multiplicidad de recepción',
            ar: 'تعدد التناول',
            de: 'Einnahmehäufigkeit',
            pt: 'Frequência de uso',
            fr: "Fréquence d'administration",
            zh: '服用次数',
            ko: '복용 횟수',
            ja: '摂取頻度',
            th: 'ความถี่ในการรับประทาน',
            he: 'תדירות המתן',
            zh_tw: '服用次數',
            fil: 'Kadalasang pag-inom',
            id: 'Kelipatan resep'
        },
        outpatient_38: {
            ru: 'Продолжительность приема',
            en: 'Duration of admission',
            tr: 'Kabul süresi',
            es: 'Duración de la admisión',
            ar: 'مدة التناول',
            de: 'Einnahmedauer',
            pt: 'Duração do uso',
            fr: "Durée d'administration",
            zh: '服用时间',
            ko: '투약 기간',
            ja: '投与期間',
            th: 'ระยะเวลาในการรับประทาน',
            he: 'משך המתן',
            zh_tw: '服用時間',
            fil: 'Tagal ng pag-inom',
            id: 'Durasi pengobatan'
        },
        outpatient_39: {
            ru: 'Особые условия',
            en: 'Special conditions',
            tr: 'Özel durumlar',
            es: 'Condiciones especiales',
            ar: 'شروط خاصة',
            de: 'Besondere Bedingungen',
            pt: 'Condições especiais',
            fr: 'Conditions particulières',
            zh: '特殊条件',
            ko: '특수 조건',
            ja: '特別な条件',
            th: 'เงื่อนไขพิเศษ',
            he: 'תנאים מיוחדים',
            zh_tw: '特殊條件',
            fil: 'Espesyal na kondisyon',
            id: 'Kondisi khusus'
        },
        outpatient_40: {
            ru: 'Госпитализация',
            en: 'Hospitalization',
            tr: 'Hastaneye yatış',
            es: 'Hospitalización',
            ar: 'العلاج في المستشفيات',
            de: 'Krankenhausaufenthalt',
            pt: 'Hospitalização',
            fr: 'Hospitalisation',
            zh: '住院治疗',
            ko: '입원',
            ja: '入院',
            th: 'การเข้ารับการพักฟื้นในโรงพยาบาล',
            he: 'התפקדות בבית חולים',
            zh_tw: '住院',
            fil: 'Pagkakahospital',
            id: 'Rawat inap'
        },
        outpatient_41: {
            ru: 'Диета',
            en: 'Diet',
            tr: 'Diyet',
            es: 'Dieta',
            ar: 'نظام الغذائي',
            de: 'Diät',
            pt: 'Dieta',
            fr: 'Régime alimentaire',
            zh: '饮食',
            ko: '식이요법',
            ja: '食事',
            th: 'อาหาร',
            he: 'תזונה',
            zh_tw: '飲食',
            fil: 'Diyeta',
            id: 'Diet'
        },
        outpatient_42: {
            ru: 'Добавить новый препарат',
            en: 'Add new drug',
            tr: 'Yeni ilaç ekle',
            es: 'Añadir nuevo medicamento',
            ar: 'أضف عقارًا جديدًا',
            de: 'Neues Medikament hinzufügen',
            pt: 'Adicionar novo medicamento',
            fr: 'Ajouter un nouveau médicament',
            zh: '添加新药物',
            ko: '새로운 약물 추가',
            ja: '新しい薬を追加する',
            th: 'เพิ่มยาใหม่',
            he: 'הוסף תרופה חדשה',
            zh_tw: '新增藥物',
            fil: 'Magdagdag ng bagong gamot',
            id: 'Tambahkan obat baru'
        },
        outpatient_44: {
            ru: 'Создание препарата',
            en: 'Create drug',
            tr: 'İlaç oluştur',
            es: 'Crear medicamento',
            ar: 'صنع عقار',
            de: 'Medikament erstellen',
            pt: 'Criando medicamento',
            fr: 'Création de médicament',
            zh: '创建药物',
            ko: '약물 생성',
            ja: '薬を作成する',
            th: 'สร้างยา',
            he: 'צור תרופה',
            zh_tw: '創建藥物',
            fil: 'Lumikha ng gamot',
            id: 'Buat obat'
        },
        outpatient_45: {
            ru: 'Создание категории препарата',
            en: 'Create category drug',
            tr: 'Kategori ilacı oluştur',
            es: 'Crear categoría de medicamento',
            ar: 'إنشاء فئة العقاقير',
            de: 'Kategorie des Medikaments erstellen',
            pt: 'Criando categoria de medicamento',
            fr: 'Création de catégorie de médicament',
            zh: '创建药品类别',
            ko: '카테고리 약물 생성',
            ja: 'カテゴリ薬を作成する',
            th: 'สร้างหมวดหมู่ยา',
            he: 'צור קטגוריית תרופה',
            zh_tw: '創建藥物類別',
            fil: 'Lumikha ng kategorya ng gamot',
            id: 'Buat kategori obat'
        },
        outpatient_47: {
            ru: 'Вы уверены, что хотите удалить категорию?',
            en: 'Are you sure, you want to delete the category?',
            tr: 'Kategoriyi silmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que quieres eliminar la categoría?',
            ar: 'هل أنت متأكد أنك تريد حذف الفئة؟',
            de: 'Sind Sie sicher, dass Sie die Kategorie löschen möchten?',
            pt: 'Tem certeza de que deseja excluir a categoria?',
            fr: 'Êtes-vous sûr de vouloir supprimer la catégorie?',
            zh: '您确定要删除该类别吗？',
            ko: '이 카테고리를 삭제하시겠습니까?',
            ja: 'カテゴリを削除してもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการลบหมวดหมู่?',
            he: 'האם אתה בטוח שאתה רוצה למחוק את הקטגוריה?',
            zh_tw: '您確定要刪除該類別嗎？',
            fil: 'Sigurado ka bang gusto mong burahin ang kategorya?',
            id: 'Apakah Anda yakin ingin menghapus kategori ini?'
        },
        outpatient_48: {
            ru: 'Удалятся все кейсы связанные с данной категорией.',
            en: 'All cases associated with this category will be deleted.',
            tr: 'Bu kategoriyle ilişkili tüm vakalar silinecek.',
            es: 'Se eliminarán todos los casos asociados con esta categoría.',
            ar: 'سيتم حذف جميع الحالات المرتبطة بهذه الفئة.',
            de: 'Alle Fälle, die mit dieser Kategorie verbunden sind, werden gelöscht.',
            pt: 'Todos os casos relacionados a esta categoria serão excluídos.',
            fr: 'Tous les cas liés à cette catégorie seront supprimés.',
            zh: '所有与此类别相关的案例将被删除。',
            ko: '이 카테고리와 관련된 모든 사례가 삭제됩니다.',
            ja: 'このカテゴリに関連するすべてのケースが削除されます。',
            th: 'เคสทั้งหมดที่เกี่ยวข้องกับหมวดหมู่นี้จะถูกลบทิ้ง',
            he: 'כל המקרים הקשורים לקטגוריה זו יימחקו.',
            zh_tw: '所有與此類別關聯的案例將被刪除。',
            fil: 'Ang lahat ng mga kaso na nauugnay sa kategoryang ito ay mabubura.',
            id: 'Semua kasus yang terkait dengan kategori ini akan dihapus.'
        },
        outpatient_49: {
            ru: 'Вы уверены, что хотите удалить кейс?',
            en: 'Are you sure, you want to delete the case?',
            tr: 'Vakayı silmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que quieres eliminar el caso?',
            ar: 'هل أنت متأكد أنك تريد حذف الحالة؟',
            de: 'Sind Sie sicher, dass Sie den Fall löschen möchten?',
            pt: 'Tem certeza de que deseja excluir o caso?',
            fr: 'Êtes-vous sûr de vouloir supprimer le cas?',
            zh: '您确定要删除该案例吗？',
            ko: '이 사례를 삭제하시겠습니까?',
            ja: 'ケースを削除してもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการลบเคส?',
            he: 'האם אתה בטוח שאתה רוצה למחוק את המקרה?',
            zh_tw: '您確定要刪除該案例嗎？',
            fil: 'Sigurado ka bang gusto mong burahin ang kaso?',
            id: 'Apakah Anda yakin ingin menghapus kasus ini?'
        },
        outpatient_50: {
            ru: 'Удалятся все данные кейса.',
            en: 'All case data is deleted.',
            tr: 'Tüm vaka verileri silinir.',
            es: 'Se eliminarán todos los datos del caso.',
            ar: 'يتم حذف جميع بيانات الحالة.',
            de: 'Alle Daten des Falls werden gelöscht.',
            pt: 'Todos os dados do caso serão excluídos.',
            fr: 'Toutes les données du cas seront supprimées.',
            zh: '所有案例数据都将被删除。',
            ko: '이 사례의 모든 데이터가 삭제됩니다.',
            ja: 'すべてのケースデータが削除されます。',
            th: 'ข้อมูลเคสทั้งหมดจะถูกลบทิ้ง',
            he: 'כל נתוני המקרה נמחקים.',
            zh_tw: '所有案例數據將被刪除。',
            fil: 'Ang lahat ng data ng kaso ay mabubura.',
            id: 'Semua data kasus dihapus.'
        },
        outpatient_51: {
            ru: 'Добавить новую информацию.',
            en: 'Add new information.',
            tr: 'Yeni bilgiler ekleyin.',
            es: 'Añadir nueva información.',
            ar: 'أضف معلومات جديدة.',
            de: 'Neue Informationen hinzufügen.',
            pt: 'Adicionar nova informação.',
            fr: 'Ajouter une nouvelle information.',
            zh: '添加新信息。',
            ko: '새로운 정보 추가.',
            ja: '新しい情報を追加する',
            th: 'เพิ่มข้อมูลใหม่',
            he: 'הוסף מידע חדש.',
            zh_tw: '新增資訊。',
            fil: 'Magdagdag ng bagong impormasyon.',
            id: 'Tambahkan informasi baru.'
        },
        outpatient_52: {
            ru: 'Удалить строку.',
            en: 'Delete line.',
            tr: 'Satırı sil.',
            es: 'Borrar línea.',
            ar: 'حذف الخط.',
            de: 'Zeile löschen.',
            pt: 'Excluir linha.',
            fr: 'Supprimer la ligne.',
            zh: '删除行。',
            ko: '줄 삭제.',
            ja: '行を削除する',
            th: 'ลบบรรทัด',
            he: 'מחק שורה.',
            zh_tw: '刪除行。',
            fil: 'Burahin ang linya.',
            id: 'Hapus baris.'
        },
        outpatient_53: {
            ru: 'Выберите действие, которое хотите совершить со списоком',
            en: 'Select the action you want to take with the list',
            tr: 'Liste ile yapmak istediğiniz eylemi seçin',
            es: 'Selecciona la acción que deseas realizar de la lista',
            ar: 'حدد الإجراء الذي تريد اتخاذه مع القائمة',
            de: 'Wählen Sie die Aktion aus, die Sie auf der Liste durchführen möchten.',
            pt: 'Selecione a ação que deseja executar na lista',
            fr: "Sélectionnez l'action que vous souhaitez effectuer sur la liste",
            zh: '选择您要执行的列表操作。',
            ko: '리스트에 수행할 작업 선택',
            ja: 'リストで実行する操作を選択してください',
            th: 'เลือกการดำเนินการที่คุณต้องการดำเนินการกับรายการ',
            he: 'בחר את הפעולה שאתה רוצה לבצע עם הרשימה',
            zh_tw: '選擇您想要對列表採取的操作',
            fil: 'Pumili ng aksyon na nais mong gawin sa listahan',
            id: 'Pilih tindakan yang ingin Anda ambil dengan daftar'
        },
        outpatient_54: {
            ru: 'Заключение',
            en: 'Conclusion',
            tr: 'Çözüm',
            es: 'Conclusión',
            ar: 'استنتاج',
            de: 'Fazit',
            pt: 'Conclusão',
            fr: 'Conclusion',
            zh: '结论',
            ko: '결론',
            ja: '結論',
            th: 'สรุป',
            he: 'מסקנה',
            zh_tw: '結論',
            fil: 'Konklusyon',
            id: 'Kesimpulan'
        },
        outpatient_55: {
            ru: 'Результат',
            en: 'Result',
            tr: 'Sonuç',
            es: 'Resultado',
            ar: 'نتيجة',
            de: 'Ergebnis',
            pt: 'Resultado',
            fr: 'Résultat',
            zh: '结果',
            ko: '결과',
            ja: '結果',
            th: 'ผลลัพธ์',
            he: 'תוצאה',
            zh_tw: '結果',
            fil: 'Resulta',
            id: 'Hasil'
        },
        outpatient_56: {
            ru: 'Препараты',
            en: 'A drugs',
            tr: 'Bir uyuşturucu',
            es: 'Medicamentos',
            ar: 'أدوية',
            de: 'Medikamente',
            pt: 'Medicamentos',
            fr: 'Médicaments',
            zh: '一种药物',
            ko: '약물',
            ja: '薬',
            th: 'ยา',
            he: 'תרופות',
            zh_tw: '藥物',
            fil: 'Mga gamot',
            id: 'Obat-obatan'
        },
        outpatient_58: {
            ru: 'Добавить строку',
            en: 'Add line',
            tr: 'Satır ekle',
            es: 'Añadir línea',
            ar: 'أضف خطًا',
            de: 'Zeile hinzufügen',
            pt: 'Adicionar linha',
            fr: 'Ajouter une ligne',
            zh: '添加行',
            ko: '줄 추가',
            ja: '行を追加する',
            th: 'เพิ่มบรรทัด',
            he: 'הוסף שורה',
            zh_tw: '增加行',
            fil: 'Magdagdag ng linya',
            id: 'Tambahkan baris'
        },
        outpatient_59: {
            ru: 'Добавить заголовок',
            en: 'Add title',
            tr: 'Başlık ekle',
            es: 'Añadir título',
            ar: 'أضف عنوانا',
            de: 'Überschrift hinzufügen',
            pt: 'Adicionar título',
            fr: 'Ajouter un en-tête',
            zh: '添加标题',
            ko: '제목 추가',
            ja: 'タイトルを追加',
            th: 'เพิ่มหัวข้อ',
            he: 'הוסף כותרת',
            zh_tw: '增加標題',
            fil: 'Magdagdag ng pamagat',
            id: 'Tambahkan judul'
        },
        outpatient_60: {
            ru: 'Невозможно создать препарат, пока есть препарат с пустым именем.',
            en: 'It is not possible to create a remedy while there is a remedy with an empty name.',
            tr: 'Boş bir isim ile bir çare varken bir çare oluşturmak mümkün değildir.',
            es: 'No es posible crear una cura mientras haya una cura con un nombre vacío.',
            ar: 'لا يمكن إنشاء علاج بينما يوجد علاج باسم فارغ.',
            de: 'Es kann kein Medikament erstellt werden, solange ein Medikament ohne Namen vorhanden ist.',
            pt: 'Não é possível criar um medicamento enquanto houver um medicamento com nome vazio.',
            fr: "Impossible de créer un médicament tant qu'il existe un médicament sans nom.",
            zh: '在有一个空名称的药物时无法创建药品。',
            ko: '이름이 비어있는 약물을 생성할 수 없습니다.',
            ja: '薬剤の名前が空のままでは薬剤を作成することはできません。',
            th: 'ไม่สามารถสร้างตำรับขณะที่มีตำรับที่มีชื่อว่าว่างเปล่า',
            he: 'אין אפשרות ליצור תרופה כאשר קיימת תרופה בשם ריק.',
            zh_tw: '在名稱為空的狀態下，不可能創建補救措施。',
            fil: 'Hindi posible lumikha ng gamot habang mayroong gamot na walang laman na pangalan.',
            id: 'Tidak memungkinkan untuk membuat obat jika ada obat dengan nama kosong.'
        },
        outpatient_61: {
            ru: 'Вы уверены, что хотите удалить элемент?',
            en: 'Are you sure, you want to delete the item?',
            tr: 'Öğeyi silmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que quieres eliminar el elemento?',
            ar: 'هل أنت متأكد أنك تريد حذف العنصر؟',
            de: 'Sind Sie sicher, dass Sie das Element löschen möchten?',
            pt: 'Tem certeza de que deseja excluir o elemento?',
            fr: "Êtes-vous sûr de vouloir supprimer l'élément?",
            zh: '您确定要删除该项吗？',
            ko: '이 항목을 삭제하시겠습니까?',
            ja: 'このアイテムを削除してもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการลบรายการนี้?',
            he: 'האם אתה בטוח שאתה רוצה למחוק את הפריט?',
            zh_tw: '您確定要刪除該項目嗎？',
            fil: 'Sigurado ka bang gusto mong burahin ang item?',
            id: 'Apakah Anda yakin ingin menghapus artikel ini?'
        },
        outpatient_62: {
            ru: 'Все данные данного элемента будут удалены.',
            en: 'All data of this element will be deleted.',
            tr: 'Bu öğenin tüm verileri silinecektir.',
            es: 'Se eliminarán todos los datos de este elemento.',
            ar: 'سيتم حذف جميع بيانات هذا العنصر.',
            de: 'Alle Daten dieses Elements werden gelöscht.',
            pt: 'Todos os dados deste elemento serão excluídos.',
            fr: 'Toutes les données de cet élément seront supprimées.',
            zh: '该元素的所有数据将被删除。',
            ko: '이 요소의 모든 데이터가 삭제됩니다.',
            ja: 'この要素のすべてのデータが削除されます。',
            th: 'ข้อมูลขององค์ประกอบนี้ทั้งหมดจะถูกลบทิ้ง',
            he: 'כל נתוני הפריט הזה יימחקו.',
            zh_tw: '此元素的所有數據將被刪除。',
            fil: 'Ang lahat ng data ng elementong ito ay mabubura.',
            id: 'Semua data elemen ini akan dihapus.'
        },
        outpatient_63: {
            ru: 'Количество кейсов',
            en: 'Number of cases',
            tr: 'Vaka sayısı',
            es: 'Número de casos',
            ar: 'عدد الحلات',
            de: 'Anzahl der Fälle',
            pt: 'Número de casos',
            fr: 'Nombre de cas',
            zh: '病例数量',
            ko: '사례 수',
            ja: 'ケースの数',
            th: 'จำนวนเคส',
            he: 'מספר מקרים',
            zh_tw: '案例數量',
            fil: 'Bilang ng mga kaso',
            id: 'Jumlah kasus'
        },
        outpatient_64: {
            ru: 'Удалить категорию',
            en: 'Delete category',
            tr: 'Kategoriyi sil',
            es: 'Eliminar categoría',
            ar: 'حذف الفئة',
            de: 'Kategorie löschen',
            pt: 'Excluir categoria',
            fr: 'Supprimer la catégorie',
            zh: '删除类别',
            ko: '카테고리 삭제',
            ja: 'カテゴリの削除',
            th: 'ลบหมวดหมู่',
            he: 'מחק קטגוריה',
            zh_tw: '刪除類別',
            fil: 'Burahin ang kategorya',
            id: 'Hapus kategori'
        },
        outpatient_65: {
            ru: 'Создать категорию',
            en: 'Create category',
            tr: 'Kategori oluştur',
            es: 'Crear categoría',
            ar: 'إنشاء فئة',
            de: 'Kategorie erstellen',
            pt: 'Criar categoria',
            fr: 'Créer une catégorie',
            zh: '创建类别',
            ko: '카테고리 생성',
            ja: 'カテゴリを作成',
            th: 'สร้างหมวดหมู่',
            he: 'צור קטגוריה',
            zh_tw: '創建類別',
            fil: 'Lumikha ng kategorya',
            id: 'Buat kategori'
        },
        outpatient_66: {
            ru: 'Введите новое имя.',
            en: 'Enter a new name.',
            tr: 'Yeni bir ad girin.',
            es: 'Introduce un nombre nuevo.',
            ar: 'أدخل اسمًا جديدًا.',
            de: 'Geben Sie einen neuen Namen ein.',
            pt: 'Digite um novo nome.',
            fr: 'Entrez un nouveau nom.',
            zh: '输入新名称。',
            ko: '새 이름 입력.',
            ja: '新しい名前を入力してください。',
            th: 'ป้อนชื่อใหม่',
            he: 'הזן שם חדש.',
            zh_tw: '輸入新名稱。',
            fil: 'Maglagay ng bagong pangalan.',
            id: 'Masukkan nama baru.'
        },
        outpatient_67: {
            ru: 'Копировать из',
            en: 'Copy from',
            tr: 'Şuradan kopyala',
            es: 'Copiar de',
            ar: 'نسخ من',
            de: 'Auswahl kopieren von',
            pt: 'Copiar de',
            fr: 'Copier à partir de',
            zh: '从...复制',
            ko: '복사 대상',
            ja: 'コピー元',
            th: 'คัดลอกจาก',
            he: 'העתק מ-',
            zh_tw: '從中複製',
            fil: 'Kopyahin mula sa',
            id: 'Salin dari'
        },
        outpatient_68: {
            ru: 'Добавить ответ',
            en: 'Add answer',
            tr: 'Cevap ekle',
            es: 'Añadir respuesta',
            ar: 'أضف إجابة',
            de: 'Antwort hinzufügen',
            pt: 'Adicionar resposta',
            fr: 'Ajouter une réponse',
            zh: '添加答案',
            ko: '답변 추가',
            ja: '回答を追加',
            th: 'เพิ่มคำตอบ',
            he: 'הוסף תשובה',
            zh_tw: '增加答案',
            fil: 'Magdagdag ng sagot',
            id: 'Tambahkan jawaban'
        },
        outpatient_69: {
            ru: 'Имена',
            en: 'Names',
            tr: 'İsimler',
            es: 'Nombres',
            ar: 'الأسماء',
            de: 'Namen',
            pt: 'Nomes',
            fr: 'Noms',
            zh: '名称',
            ko: '이름',
            ja: '名前',
            th: 'นามสกุล',
            he: 'שמות',
            zh_tw: '名字',
            fil: 'Mga Pangalan',
            id: 'Nama'
        },
        outpatient_70: {
            ru: 'Негроид',
            en: 'Negroid',
            tr: 'Zenci',
            es: 'Negroide',
            ar: 'قوقازي',
            de: 'Negroid',
            pt: 'Negroide',
            fr: 'Négroïde',
            zh: '黑人',
            ko: '흑인',
            ja: 'ネグロイド',
            th: 'คนเหนือสีผิว',
            he: 'נגרואידי',
            zh_tw: '黑種人',
            fil: 'Negroid',
            id: 'Negroid'
        },
        outpatient_71: {
            ru: 'Европеоид',
            en: 'Caucasian',
            tr: 'Kafkas',
            es: 'Caucasiano',
            ar: 'قوقازي',
            de: 'Caucasoid',
            pt: 'Caucasoide',
            fr: 'Européen',
            zh: '白人',
            ko: '백인',
            ja: 'コーカソイド',
            th: 'คนคาวคอซี',
            he: 'קוקזי',
            zh_tw: '高加索人',
            fil: 'Caucasian',
            id: 'Kaukasoid'
        },
        outpatient_72: {
            ru: 'Синтез речи',
            en: 'Speech synthesis',
            tr: 'Konuşma sentezi',
            es: 'Síntesis de voz',
            ar: 'اصطناع الكلام',
            de: 'Sprachsynthese',
            pt: 'Síntese de voz',
            fr: 'Synthèse vocale',
            zh: '语音合成',
            ko: '음성 합성',
            ja: '音声合成',
            th: 'การสังเคราะห์เสียงพูด',
            he: 'סינתוזה של דיבור',
            zh_tw: '語音合成',
            fil: 'Pagsasalita ng teksto',
            id: 'Sintesis bunyi'
        },
        outpatient_75: {
            ru: 'Осмотр',
            en: 'Inspection',
            tr: 'Denetleme',
            es: 'Inspección',
            ar: 'فحص',
            de: 'Untersuchung',
            pt: 'Exame físico',
            fr: 'Examen physique',
            zh: '检查',
            ko: '검사',
            ja: '検査',
            th: 'การตรวจสอบ',
            he: 'בדיקה',
            zh_tw: '檢查',
            fil: 'Pagsusuri',
            id: 'Inspeksi'
        },
        outpatient_76: {
            ru: 'Вы уверены, что хотите загрузить данный файл {NAME}? При его загрузке изменятся все файлы с таким же названием.',
            en: 'Are you sure, you want to upload this file {NAME}? When you upload it, all files with the same name will change.',
            tr: 'Bu dosyayı {NAME} yüklemek istediğinizden emin misiniz? Yüklediğinizde, aynı ada sahip tüm dosyalar değişecektir.',
            es: '¿Está seguro de que desea cargar este archivo {NAME}? Cuando lo cargue, todos los archivos con el mismo nombre cambiarán.',
            ar: 'هل أنت متأكد, تريد تحميل هذا الملف {NAME}? عند تحميله ، ستتغير جميع الملفات التي تحمل الاسم نفسه.',
            de: 'Sind Sie sicher, dass Sie diese Datei {NAME} hochladen möchten? Wenn Sie es hochladen, ändern sich alle Dateien mit demselben Namen.',
            pt: 'Tem a certeza que pretende carregar este ficheiro {NAME}? Ao fazer o upload, todos os arquivos com o mesmo nome serão alterados.',
            fr: 'Êtes-vous sûr de vouloir télécharger ce fichier {NAME}? Lorsque vous le téléchargez, tous les fichiers portant le même nom changent.',
            zh: '您确定要上传此文件 {NAME} 吗？一旦上传，所有同名文件都将更改。',
            ko: '이 파일 {NAME}을(를) 업로드하시겠습니까? 업로드하면 동일한 이름을 가진 모든 파일이 변경됩니다.',
            ja: 'このファイル {NAME} をアップロードしてもよろしいですか？アップロードすると、同じ名前のすべてのファイルが変更されます。',
            th: 'คุณแน่ใจว่าต้องการอัปโหลดไฟล์ {NAME} นี้หรือไม่? เมื่อคุณอัปโหลดแล้ว ไฟล์ทั้งหมดที่มีชื่อเดียวกันจะเปลี่ยนแปลง',
            he: 'האם אתה בטוח שאתה רוצה להעלות את הקובץ {NAME}? בעת העלאתו, כל הקבצים באותו השם ישתנו.',
            zh_tw: '您確定要上傳此文件{NAME}嗎？上傳後，所有同名文件都將更改。',
            fil: 'Sigurado ka bang gusto mong i-upload ang file na ito {NAME}? Kapag i-upload mo ito, lahat ng mga file na may parehong pangalan ay magbabago.',
            id: 'Apakah Anda yakin ingin mengunggah file ini {NAME}? Saat Anda mengunggahnya, semua file dengan nama yang sama akan berubah.'
        },
        outpatient_77: {
            ru: 'Удалить изображение.',
            en: 'Delete image.',
            tr: 'Resmi Sil.',
            es: 'Borrar imagen.',
            ar: 'حذف صورة.',
            de: 'Bild löschen.',
            pt: 'Excluir imagem.',
            fr: "Supprimer l'image.",
            zh: '删除图像。',
            ko: '이미지 삭제.',
            ja: '画像を削除する。',
            th: 'ลบรูปภาพ',
            he: 'מחק תמונה.',
            zh_tw: '刪除圖片。',
            fil: 'Burahin ang larawan.',
            id: 'Hapus gambar.'
        },
        outpatient_78: {
            ru: 'Удалить станцию.',
            en: 'Delete station.',
            tr: 'İstasyonu sil.',
            es: 'Eliminar estación.',
            ar: 'حذف المحطة.',
            de: 'Station löschen.',
            pt: 'Excluir estação.',
            fr: 'Supprimer la station.',
            zh: '删除站点。',
            ko: '역할 삭제.',
            ja: '駅を削除する。',
            th: 'ลบสถานี',
            he: 'מחק תחנה.',
            zh_tw: '刪除站點。',
            fil: 'Burahin ang istasyon.',
            id: 'Hapus stasiun.'
        },
        outpatient_79: {
            ru: 'Добавить диету',
            en: 'Add diet',
            tr: 'Diyet ekle',
            es: 'Añadir dieta',
            ar: 'أضف النظام الغذائي',
            de: 'Diät hinzufügen',
            pt: 'Adicionar dieta',
            fr: 'Ajouter un régime alimentaire',
            zh: '添加饮食',
            ko: '식이요법 추가',
            ja: '食事を追加',
            th: 'เพิ่มเมนูอาหาร',
            he: 'הוסף דיאטה',
            zh_tw: '添加飲食',
            fil: 'Magdagdag ng diyeta',
            id: 'Tambahkan diet'
        },
        outpatient_80: {
            ru: 'Удалить диету',
            en: 'Delete diet',
            tr: 'Diyeti sil',
            es: 'Eliminar dieta',
            ar: 'حذف النظام الغذائي',
            de: 'Diät löschen',
            pt: 'Excluir dieta',
            fr: 'Supprimer un régime alimentaire',
            zh: '删除饮食',
            ko: '식이요법 삭제',
            ja: '食事を削除する',
            th: 'ลบเมนูอาหาร',
            he: 'מחק דיאטה',
            zh_tw: '刪除飲食',
            fil: 'Burahin ang diyeta',
            id: 'Hapus diet'
        },
        outpatient_81: {
            ru: 'Замена',
            en: 'Replacement',
            tr: 'Yenisiyle değiştirme',
            es: 'Reemplazar',
            ar: 'تبديل',
            de: 'Ersetzung',
            pt: 'Substituição',
            fr: 'Remplacement',
            zh: '替代品',
            ko: '교체',
            ja: '置換',
            th: 'การแทนที่',
            he: 'החלפה',
            zh_tw: '替代',
            fil: 'Papalitan',
            id: 'Penggantian'
        },
        outpatient_82: {
            ru: 'Доп. обследования',
            en: 'Additional examination',
            tr: 'Ek muayene',
            es: 'Examen adicional',
            ar: 'فحص إضافي',
            de: 'Weitere Untersuchungen',
            pt: 'Exames adicionais',
            fr: 'Examens complémentaires',
            zh: '附加检查',
            ko: '추가 검사',
            ja: '追加検査',
            th: 'การตรวจเพิ่มเติม',
            he: 'בדיקה נוספת',
            zh_tw: '額外檢查',
            fil: 'Karagdagang pagsusuri',
            id: 'Pemeriksaan tambahan'
        },
        outpatient_83: {
            ru: 'Таблица',
            en: 'Table',
            tr: 'Masa',
            es: 'Tabla',
            ar: 'جدول',
            de: 'Tabelle',
            pt: 'Tabela',
            fr: 'Tableau',
            zh: '表格',
            ko: '표',
            ja: 'テーブル',
            th: 'ตาราง',
            he: 'טבלה',
            zh_tw: '表格',
            fil: 'Talaan',
            id: 'Tabel'
        },
        outpatient_84: {
            ru: 'Изображение + заключение',
            en: 'Image + conclusion',
            tr: 'Resim + sonuç',
            es: 'Imagen + conclusión',
            ar: 'صورة + استنتاج',
            de: 'Bild + Fazit',
            pt: 'Imagem + conclusão',
            fr: 'Image + conclusion',
            zh: '图像 + 结论',
            ko: '이미지 + 결론',
            ja: '画像 + 結論',
            th: 'รูปภาพ + สรุป',
            he: 'תמונה + מסקנה',
            zh_tw: '圖片+結論',
            fil: 'Larawan + konklusyon',
            id: 'Gambar + kesimpulan'
        },
        outpatient_85: {
            ru: 'Результат + заключение',
            en: 'Result + conclusion',
            tr: 'Sonuç + sonuç',
            es: 'Resultado + conclusión',
            ar: 'النتيجة + الاستنتاج',
            de: 'Ergebnis + Fazit',
            pt: 'Resultado + conclusão',
            fr: 'Résultat + conclusion',
            zh: '结果 + 结论',
            ko: '결과 + 결론',
            ja: '結果 + 結論',
            th: 'ผลลัพธ์ + สรุป',
            he: 'תוצאה + מסקנה',
            zh_tw: '結果+結論',
            fil: 'Resulta + konklusyon',
            id: 'Hasil + kesimpulan'
        },
        outpatient_86: {
            ru: 'Колонки для таблицы',
            en: 'Columns for the table',
            tr: 'Tablo için sütunlar',
            es: 'Columnas para la tabla',
            ar: 'أعمدة الجدول',
            de: 'Spalten für die Tabelle',
            pt: 'Colunas da tabela',
            fr: 'Colonnes pour le tableau',
            zh: '表格列',
            ko: '표의 열',
            ja: 'テーブルの列',
            th: 'คอลัมน์สำหรับตาราง',
            he: 'עמודות לטבלה',
            zh_tw: '表格的列',
            fil: 'Mga haligi para sa talaan',
            id: 'Kolom untuk tabel'
        },
        outpatient_87: {
            ru: 'Колонка "Выделить" будет установлена по умолчанию.',
            en: 'The "Highlight" column will be set by default.',
            tr: '"Vurgulamak" sütunu varsayılan olarak ayarlanacaktır.',
            es: 'El resaltado de la columna se establecerá de forma predeterminada.',
            ar: 'سيتم تعيين عمود "تمييز" بشكل افتراضي.',
            de: 'Die Spalte "Markieren" wird standardmäßig aktiviert.',
            pt: 'A coluna "Destacar" será definida por padrão.',
            fr: 'La colonne "Sélectionner" sera définie par défaut.',
            zh: '“高亮”列将默认设置。',
            ko: '강조 열은 기본으로 설정됩니다.',
            ja: '「ハイライト」列はデフォルトで設定されます。',
            th: 'คอลัมน์ "ไฮไลต์" จะถูกตั้งค่าเป็นค่าเริ่มต้น',
            he: 'העמודה "הדגשה" תוגדר כברירת מחדל.',
            zh_tw: '“突出顯示”列將默認設置。',
            fil: 'Ang kolum na "I-highlight" ay magiging default.',
            id: 'Kolom "Sorot" akan diatur secara default.'
        },
        outpatient_88: {
            ru: 'Название колонки',
            en: 'Column name',
            tr: 'Sütun adı',
            es: 'Nombre de la columna',
            ar: 'اسم العمود',
            de: 'Spaltenname',
            pt: 'Nome da coluna',
            fr: 'Nom de la colonne',
            zh: '列名',
            ko: '열 이름',
            ja: '列の名前',
            th: 'ชื่อคอลัมน์',
            he: 'שם העמודה',
            zh_tw: '列名',
            fil: 'Pangalan ng kolum',
            id: 'Nama Kolom'
        },
        outpatient_89: {
            ru: 'Вы уверены, что хотите изменить тип вкладки?',
            en: 'Are you sure you want to change the tab type?',
            tr: 'Sekme türünü değiştirmek istediğinizden emin misiniz?',
            es: '¿Estás seguro de que quieres cambiar el tipo de pestaña?',
            ar: 'هل أنت متأكد أنك تريد تغيير نوع علامة التبويب؟',
            de: 'Sind Sie sicher, dass Sie den Tab-Typ ändern möchten?',
            pt: 'Tem certeza de que deseja alterar o tipo de guia?',
            fr: "Êtes-vous sûr de vouloir modifier le type d'onglet?",
            zh: '您确定要更改选项卡类型吗？',
            ko: '탭 유형을 변경하시겠습니까?',
            ja: 'タブのタイプを変更してもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการเปลี่ยนประเภทแท็บหรือไม่?',
            he: 'האם אתה בטוח שאתה רוצה לשנות את סוג הכרטיסייה?',
            zh_tw: '您確定要更改標籤類型嗎？',
            fil: 'Sigurado ka bang gusto mong baguhin ang uri ng tab?',
            id: 'Apakah Anda yakin ingin mengubah jenis tab?'
        },
        outpatient_90: {
            ru: 'Все текущие данные этой вкладки будут невозвратно удалены.',
            en: 'All current data of this tab will be permanently deleted.',
            tr: 'Bu sekmenin tüm güncel verileri kalıcı olarak silinecektir.',
            es: 'Todos los datos actuales de esta pestaña se eliminarán de forma permanente.',
            ar: 'سيتم حذف جميع البيانات الحالية الموجودة في علامة التبويب هذه نهائيًا.',
            de: 'Alle aktuellen Daten dieses Tabs werden unwiderruflich gelöscht.',
            pt: 'Todos os dados atuais desta guia serão excluídos permanentemente.',
            fr: 'Toutes les données actuelles de cet onglet seront supprimées de manière irréversible.',
            zh: '此选项卡的所有当前数据都将永久删除。',
            ko: '이 탭의 현재 데이터가 영구적으로 삭제됩니다.',
            ja: 'このタブの現在のデータは永久に削除されます。',
            th: 'ข้อมูลปัจจุบันของแท็บนี้จะถูกลบอย่างถาวร',
            he: 'כל הנתונים הנוכחיים של הכרטיסייה הזו יימחקו לצמיתות.',
            zh_tw: '此標籤的所有當前數據都將被永久刪除。',
            fil: 'Ang lahat ng kasalukuyang data ng tab na ito ay permanenteng mabubura.',
            id: 'Semua data terkini pada tab ini akan dihapus secara permanen.'
        },
        outpatient_91: {
            ru: 'Изменить',
            en: 'Change',
            tr: 'Değişim',
            es: 'Cambiar',
            ar: 'تغيير',
            de: 'Ändern',
            pt: 'Mudança',
            fr: 'Modifier',
            zh: '更改',
            ko: '변경',
            ja: '変更',
            th: 'เปลี่ยน',
            he: 'שינוי',
            zh_tw: '更改',
            fil: 'Baguhin',
            id: 'Ubah'
        },
        outpatient_92: {
            ru: 'Использовать валидацию для изображения',
            en: 'Use validation for an image',
            tr: 'Bir görüntü için doğrulama kullanma',
            es: 'Usar la validación de la imagen',
            ar: 'استخدم التحقق من صحة الصورة',
            de: 'Validierung für Bild verwenden',
            pt: 'Usar validação para imagem',
            fr: "Utiliser une validation pour l'image",
            zh: '使用图像验证',
            ko: '이미지에 유효성 검사 사용',
            ja: '画像の検証を使用する',
            th: 'ใช้การตรวจสอบสำหรับรูปภาพ',
            he: 'השתמש באימות תמונה',
            zh_tw: '使用圖片驗證',
            fil: 'Gamitin ang pag-validate para sa isang larawan',
            id: 'Gunakan validasi untuk gambar'
        },
        outpatient_93: {
            ru: 'При использовании валидации пустое изображение будет отображаться как ошибка.',
            en: 'When using validation, an empty image will be displayed as an error.',
            tr: 'Doğrulama kullanılırken, hata olarak boş bir resim görüntülenir.',
            es: 'Al usar la validación, se mostrará una imagen vacía como un error.',
            ar: 'عند استخدام التحقق ، سيتم عرض صورة فارغة كخطأ.',
            de: "Lors de l'utilisation de la validation, une image vide sera affichée comme une erreur.",
            pt: 'Ao usar validação, a imagem vazia será exibida como um erro.',
            fr: 'Bei Verwendung der Validierung wird ein leeres Bild als Fehler angezeigt.',
            zh: '使用验证时，空图像将显示为错误。',
            ko: '유효성 검사 사용 시 빈 이미지는 오류로 표시됩니다.',
            ja: '検証を使用すると、空の画像がエラーとして表示されます。',
            th: 'เมื่อใช้การตรวจสอบ รูปภาพที่ว่างเปล่าจะแสดงเป็นข้อผิดพลาด',
            he: 'כאשר אתה משתמש באימות, תמונה ריקה תוצג כשגיאה.',
            zh_tw: '使用驗證時，空白圖片將顯示為錯誤。',
            fil: 'Kapag ginagamit ang pag-validate, ang isang walang laman na larawan ay ipapakita bilang error.',
            id: 'Saat menggunakan validasi, gambar kosong akan ditampilkan sebagai gangguan/eror.'
        },
        outpatient_94: {
            ru: 'Все кейсы',
            en: 'All cases',
            tr: 'Tüm davalar',
            es: 'Todos los casos',
            ar: 'جميع الحالات',
            de: 'Alle Fälle',
            pt: 'Todos os casos',
            fr: 'Tous les cas',
            zh: '所有案例',
            ko: '모든 사례',
            ja: 'すべてのケース',
            th: 'เคสทั้งหมด',
            he: 'כל המקרים',
            zh_tw: '所有案例',
            fil: 'Lahat ng mga kaso',
            id: 'Semua kasus'
        },
        outpatient_94_2: {
            ru: 'Кейсы без профилей',
            en: 'Cases without profiles',
            tr: 'Profilsiz kasalar',
            es: 'Casos sin perfiles',
            ar: 'حالات بدون ملامح',
            de: 'Fälle ohne Profile',
            pt: 'Casos sem perfil',
            fr: 'Cas sans profils',
            zh: '没有档案的案例',
            ko: '프로필 없는 사례',
            ja: 'プロファイルのないケース',
            th: 'เคสที่ไม่มีโปรไฟล์',
            he: 'מקרים ללא פרופילים',
            zh_tw: '無資料檔案的案例',
            fil: 'Mga kaso na walang mga profil',
            id: 'Kasus tanpa profil'
        },
        outpatient_95: {
            ru: 'Создание нового доп обследования',
            en: 'Creating a new additional examination',
            tr: 'Yeni bir ek sınav oluşturma',
            es: 'Creación de un nuevo examen adicional',
            ar: 'عمل فحص إضافي جديد',
            de: 'Neue zusätzliche Untersuchung erstellen',
            pt: 'Criando novo exame adicional',
            fr: "Création d'un nouvel examen complémentaire",
            zh: '创建新的附加检查',
            ko: '새로운 추가 검사 생성',
            ja: '新しい追加検査の作成',
            th: 'การสร้างการตรวจเพิ่มเติมใหม่',
            he: 'יצירת בדיקה נוספת חדשה',
            zh_tw: '創建新的額外檢查',
            fil: 'Lumilikha ng bagong karagdagang pagsusuri',
            id: 'Membuat pemeriksaan tambahan baru'
        },
        outpatient_96: {
            ru: 'Вы уверены, что хотите удалить обследование?',
            en: 'Are you sure, you want to delete the examination?',
            tr: 'Sınavı silmek istediğine emin misin?',
            es: '¿Estás seguro de que quieres eliminar el examen?',
            ar: 'هل أنت متأكد أنك تريد حذف الفحص؟',
            de: 'Sind Sie sicher, dass Sie die Untersuchung löschen möchten?',
            pt: 'Tem certeza de que deseja excluir o exame?',
            fr: "Êtes-vous sûr de vouloir supprimer l'examen?",
            zh: '您确定要删除检查吗？',
            ko: '검사를 삭제하시겠습니까?',
            ja: '検査を削除してもよろしいですか？',
            th: 'คุณแน่ใจว่าต้องการลบการตรวจสอบ?',
            he: 'האם אתה בטוח שאתה רוצה למחוק את הבדיקה?',
            zh_tw: '您確定要刪除檢查嗎？',
            fil: 'Sigurado ka bang gusto mong burahin ang pagsusuri?',
            id: 'Apakah Anda yakin ingin menghapus pemeriksaan ini?'
        },
        outpatient_97: {
            ru: 'Элементы с одинаковыми именами.',
            en: 'Elements with the same name.',
            tr: 'Aynı ada sahip elementler.',
            es: 'Elementos con el mismo nombre.',
            ar: 'عناصر بنفس الاسم.',
            de: 'Elemente mit identischen Namen.',
            pt: 'Elementos com os mesmos nomes.',
            fr: 'Éléments avec des noms identiques.',
            zh: '相同名称的元素。',
            ko: '같은 이름을 가진 요소들입니다.',
            ja: '同じ名前の要素',
            th: 'องค์ประกอบที่มีชื่อเดียวกัน',
            he: 'אלמנטים באותו השם.',
            zh_tw: '具有相同名稱的元素。',
            fil: 'Mga elemento na may parehong pangalan.',
            id: 'Elemen dengan nama yang sama'
        },
        outpatient_98: {
            ru: 'Общее',
            en: 'Common',
            tr: 'Yaygın',
            es: 'Común',
            ar: 'مشترك',
            de: 'Gemeinsam',
            pt: 'Geral',
            fr: 'Général',
            zh: '通用',
            ko: '공통',
            ja: '共通',
            th: 'ทั่วไป',
            he: 'כללי',
            zh_tw: '普通',
            fil: 'Karaniwan',
            id: 'Umum'
        },
        outpatient_99: {
            ru: 'Генерация аудио',
            en: 'Audio generation',
            tr: 'Ses üretimi',
            es: 'Generación de audio',
            ar: 'توليد الصوت',
            de: 'Audio-Generierung',
            pt: 'Geração de áudio',
            fr: 'Génération audio',
            zh: '语音生成',
            ko: '음성 생성',
            ja: '音声生成',
            th: 'การสร้างเสียงเอกสาร',
            he: 'יצירת אודיו',
            zh_tw: '音頻生成',
            fil: 'Paglikha ng audio',
            id: 'Pengadaan suara'
        },
        outpatient_100: {
            ru: 'Напишите текст для конвертации его в аудио запись.',
            en: 'Write text to convert it into an audio recording.',
            tr: 'Bir ses kaydına dönüştürmek için metin yazın.',
            es: 'Escribe texto para convertirlo en una grabación de audio.',
            ar: 'اكتب نصًا لتحويله إلى تسجيل صوتي.',
            de: 'Schreiben Sie den Text, um ihn in eine Audioaufnahme zu konvertieren.',
            pt: 'Digite o texto para convertê-lo em uma gravação de áudio.',
            fr: 'Entrez le texte à convertir en enregistrement audio.',
            zh: '将文本写入以将其转换为音频记录。',
            ko: '텍스트를 작성하여 오디오 녹음으로 변환하세요.',
            ja: 'テキストを書いて音声録音に変換します。',
            th: 'เขียนข้อความเพื่อแปลงเป็นการบันทึกเสียง',
            he: 'כתוב טקסט כדי להמיר אותו להקלטת אודיו.',
            zh_tw: '將文本轉換為音訊錄音。',
            fil: 'Isulat ang teksto upang i-convert ito sa isang audio recording.',
            id: 'Tulis teks untuk mengubahnya menjadi rekaman audio.'
        },
        outpatient_101: {
            ru: 'Номер диалога:',
            en: 'Dialog number:',
            tr: 'İletişim kutusu numarası:',
            es: 'Número de diálogo:',
            ar: 'رقم الحوار:',
            de: 'Dialognummer:',
            pt: 'Número do diálogo:',
            fr: 'Numéro de dialogue:',
            zh: '对话编号：',
            ko: '대화 번호:',
            ja: 'ダイアログ番号：',
            th: 'หมายเลขของสนทนา:',
            he: 'מספר השיחה:',
            zh_tw: '對話號碼：',
            fil: 'Numero ng dialogo:',
            id: 'Nomor dialog:'
        },
        outpatient_102: {
            ru: 'Текст:',
            en: 'Text:',
            tr: 'Metin:',
            es: 'Texto:',
            ar: 'نص:',
            de: 'Text:',
            pt: 'Texto:',
            fr: 'Texte:',
            zh: '文字：',
            ko: '텍스트:',
            ja: 'テキスト：',
            th: 'ข้อความ:',
            he: 'טקסט:',
            zh_tw: '文本：',
            fil: 'Teksto:',
            id: 'Teks:'
        },
        outpatient_103: {
            ru: 'Записать в ответ',
            en: 'Write in response',
            tr: 'Yanıt olarak yaz',
            es: 'Escribir en respuesta',
            ar: 'اكتب الجواب',
            de: 'In Antwort schreiben',
            pt: 'Gravar na resposta',
            fr: 'Enregistrer en réponse',
            zh: '以回复方式写入',
            ko: '응답으로 작성하기',
            ja: '応答に書く',
            th: 'เขียนเป็นคำตอบ',
            he: 'כתוב בתגובה',
            zh_tw: '書寫回應',
            fil: 'Isulat bilang tugon',
            id: 'Tulis sebagai tanggapan'
        },
        outpatient_104: {
            ru: 'Голос:',
            en: 'Voice:',
            tr: 'Ses:',
            es: 'Voz:',
            ar: 'صوت:',
            de: 'Stimme:',
            pt: 'Voz:',
            fr: 'Voix:',
            zh: '语音：',
            ko: '음성:',
            ja: '音声：',
            th: 'เสียง:',
            he: 'קול:',
            zh_tw: '聲音：',
            fil: 'Boses:',
            id: 'Suara:'
        },
        outpatient_105: {
            ru: 'Название аудио:',
            en: 'Audio name:',
            tr: 'Ses adı:',
            es: 'Nombre del audio:',
            ar: 'اسم الصوت:',
            de: 'Name der Audioaufnahme:',
            pt: 'Nome do áudio:',
            fr: 'Nom audio:',
            zh: '音频名称：',
            ko: '오디오 이름:',
            ja: 'オーディオ名：',
            th: 'ชื่อเอกสารเสียง:',
            he: 'שם האודיו:',
            zh_tw: '音訊名稱：',
            fil: 'Pangalan ng audio:',
            id: 'Nama audio:'
        },
        outpatient_106: {
            ru: 'Пример:',
            en: 'Example:',
            tr: 'Örnek:',
            es: 'Ejemplo:',
            ar: 'مثال:',
            de: 'Beispiel:',
            pt: 'Exemplo:',
            fr: 'Exemple:',
            zh: '例：',
            ko: '예시:',
            ja: '例：',
            th: 'ตัวอย่าง:',
            he: 'דוגמה:',
            zh_tw: '範例：',
            fil: 'Halimbawa:',
            id: 'Contoh:'
        },
        outpatient_107: {
            ru: 'Скрыть',
            en: 'Hide',
            tr: 'Saklamak',
            es: 'Ocultar',
            ar: 'إخفاء',
            de: 'Verstecken',
            pt: 'Esconder',
            fr: 'Cacher',
            zh: '隐藏',
            ko: '숨기기',
            ja: '非表示',
            th: 'ซ่อน',
            he: 'הסתר',
            zh_tw: '隱藏',
            fil: 'Itago',
            id: 'Sembunyikan'
        },
        outpatient_108: {
            ru: 'Показать',
            en: 'Show',
            tr: 'Göstermek',
            es: 'Mostrar',
            ar: 'إضهار',
            de: 'Anzeigen',
            pt: 'Mostrar',
            fr: 'Afficher',
            zh: '显示',
            ko: '보이기',
            ja: '表示',
            th: 'แสดง',
            he: 'הצג',
            zh_tw: '顯示',
            fil: 'Ipakita',
            id: 'Tunjukkan'
        },
        outpatient_109: {
            ru: 'Удалить аудио/изображение.',
            en: 'Delete audio/image.',
            tr: 'Sesi/resmi silin.',
            es: 'Eliminar audio/imagen.',
            ar: 'حذف الصوت / الصورة.',
            de: 'Audio/Bild löschen.',
            pt: 'Excluir áudio/imagem.',
            fr: 'Supprimer audio/image.',
            zh: '删除音频/图像。',
            ko: '오디오/이미지 삭제.',
            ja: '音声/画像を削除する。',
            th: 'ลบเสียง/รูปภาพ',
            he: 'מחק אודיו/תמונה.',
            zh_tw: '刪除音頻/圖片。',
            fil: 'Burahin ang audio/larawan.',
            id: 'Hapus audio/gambar'
        },
        outpatient_109_2: {
            ru: 'Оставить аудио',
            en: 'Leave audio.',
            tr: 'Ses bırakın.',
            es: 'Dejar audio.',
            ar: 'اترك الصوت.',
            de: 'Audio behalten',
            pt: 'Deixar áudio',
            fr: "Laisser l'audio",
            zh: '保留音频。',
            ko: '오디오 유지.',
            ja: '音声を残す',
            th: 'ออกจากเสียง',
            he: 'השאר אודיו.',
            zh_tw: '保留音頻。',
            fil: 'Iwan ang audio.',
            id: 'Tinggalkan audio.'
        },
        outpatient_109_3: {
            ru: 'Удалить аудио',
            en: 'Delete audio.',
            tr: 'Sesi sil',
            es: 'Eliminar audio',
            ar: 'حذف الصوت',
            de: 'Audio löschen',
            pt: 'Excluir áudio',
            fr: "Supprimer l'audio",
            zh: '删除音频。',
            ko: '오디오 삭제.',
            ja: '音声を削除する',
            th: 'ลบเสียง',
            he: 'מחק אודיו.',
            zh_tw: '刪除音頻。',
            fil: 'Burahin ang audio.',
            id: 'Hapus audio.'
        },
        outpatient_110: {
            ru: 'Возраст',
            en: 'Age',
            tr: 'Yaş',
            es: 'Años',
            ar: 'عمر',
            de: 'Alter',
            pt: 'Idade',
            fr: 'Âge',
            zh: '年龄',
            ko: '나이',
            ja: '年齢',
            th: 'อายุ',
            he: 'גיל',
            zh_tw: '年齡',
            fil: 'Edad',
            id: 'Usia'
        },
        outpatient_111: {
            ru: 'Загрузить медиа файлы',
            en: 'Upload media files',
            tr: 'Ses/görüntü yükle',
            es: 'Subir audio/imágenes',
            ar: 'تحميل الصوت / الصور',
            de: 'Audio/Bilder hochladen',
            pt: 'Carregar áudio/imagens',
            fr: 'Télécharger des audios/images',
            zh: '上传音频/图像',
            ko: '오디오/이미지 업로드',
            ja: '音声/画像をアップロードする',
            th: 'อัปโหลดเสียง/รูปภาพ',
            he: 'העלה אודיו/תמונות',
            zh_tw: '上傳音頻/圖片',
            fil: 'Mag-upload ng audio/larawan',
            id: 'Unggah audio/gambar'
        },
        outpatient_112: {
            ru: 'Ответы',
            en: 'Answers',
            tr: 'Yanıtlar',
            es: 'Respuestas',
            ar: 'الإجابات',
            de: 'Antworten',
            pt: 'Respostas',
            fr: 'Réponses',
            zh: '答案',
            ko: '답변',
            ja: '回答',
            th: 'คำตอบ',
            he: 'תשובות',
            zh_tw: '答案',
            fil: 'Mga Sagot',
            id: 'Jawaban'
        },
        outpatient_113: {
            ru: 'Количество возможных копирований кейсов',
            en: 'The number of possible copies of cases',
            tr: 'Vakaların olası kopyalarının sayısı',
            es: 'El número de copias posibles de los casos',
            ar: 'عدد النسخ الممكنة من الحالات',
            de: 'Anzahl der möglichen Kopien von Fällen',
            pt: 'Número de possíveis cópias de casos',
            fr: 'Nombre de copies possibles de cas',
            zh: '可能复制案例的数量',
            ko: '사례 복사 가능한 횟수',
            ja: 'ケースの可能なコピー数',
            th: 'จำนวนสำเนาเคสที่เป็นไปได้',
            he: 'מספר העתקות מקרים אפשרי',
            zh_tw: '案例的可能複製數量',
            fil: 'Bilang ng posibleng kopya ng mga kaso',
            id: 'Jumlah kemungkinan salinan kasus'
        },
        outpatient_114: {
            ru: 'Блокировка редактирования',
            en: 'Edit Lock',
            tr: 'Kilidi Düzenle',
            es: 'Editar bloqueo',
            ar: 'تحرير القفل',
            de: 'Bearbeitungssperre',
            pt: 'Bloqueio de edição',
            fr: "Verrouillage de l'édition",
            zh: '编辑锁定',
            ko: '편집 잠금',
            ja: '編集ロック',
            th: 'ล็อคการแก้ไข',
            he: 'נעילת עריכה',
            zh_tw: '編輯鎖定',
            fil: 'I-lock ang pag-edit',
            id: 'Sunting Kunci'
        },
        outpatient_115: {
            ru: 'Выбрать все',
            en: 'Select all',
            tr: 'Hepsini seç',
            es: 'Seleccionar todo',
            ar: 'اختر الكل',
            de: 'Alle auswählen',
            pt: 'Selecionar tudo',
            fr: 'Tout sélectionner',
            zh: '全选',
            ko: '모두 선택',
            ja: 'すべてを選択する',
            th: 'เลือกทั้งหมด',
            he: 'בחר הכל',
            zh_tw: '全選',
            fil: 'Piliin ang lahat',
            id: 'Pilih semua'
        },
        outpatient_116: {
            ru: 'Боль',
            en: 'Pain',
            tr: 'Ağrı',
            es: 'Dolor',
            ar: 'ألم',
            de: 'Schmerz',
            pt: 'Dor',
            fr: 'Douleur',
            zh: '疼痛',
            ko: '통증',
            ja: '痛み',
            th: 'อาการปวด',
            he: 'כאב',
            zh_tw: '疼痛',
            fil: 'Sakit',
            id: 'Nyeri'
        },
        outpatient_117: {
            ru: 'Обследования',
            en: 'Exams',
            tr: 'Sınavlar',
            es: 'Examen',
            ar: 'Sınavlar',
            de: 'Untersuchungen',
            pt: 'Exames',
            fr: 'Examens',
            zh: '考试',
            ko: '검사',
            ja: '検査',
            th: 'การตรวจ',
            he: 'בדיקות',
            zh_tw: '考試',
            fil: 'Mga Pagsusuri',
            id: 'Pemeriksaan'
        },
        outpatient_118: {
            ru: 'Патологии',
            en: 'Pathologies',
            tr: 'Patolojiler',
            es: 'Patologías',
            ar: 'الأمراض الشائعة',
            de: 'Pathologien',
            pt: 'Patologias',
            fr: 'Pathologies',
            zh: '病理学',
            ko: '병리학',
            ja: '病理学',
            th: 'โรค',
            he: 'פתולוגיות',
            zh_tw: '病理',
            fil: 'Mga Pathologya',
            id: 'Patologi'
        },
        outpatient_119: {
            ru: 'Балл',
            en: 'Score',
            tr: 'Puan',
            es: 'Puntaje',
            ar: 'النتيجة',
            de: 'Ergebnis',
            pt: 'Pontuacao',
            fr: 'Note',
            zh: '分数',
            ko: '점수',
            ja: 'スコア',
            th: 'คะแนน',
            he: 'ניקוד',
            zh_tw: '分數',
            fil: 'Iskor',
            id: 'Skor'
        },
        outpatient_120: {
            ru: 'Прохождение кейса',
            en: 'Passing case',
            tr: 'Geçen dava',
            es: 'Caso de paso',
            ar: 'الأسماء',
            de: 'Fall übergeben',
            pt: 'Processo de passagem',
            fr: 'Cas de passage',
            zh: '通过案例',
            ko: '통과 사례',
            ja: 'ケースを進行中',
            th: 'กรณีที่ผ่านไป',
            he: 'מקרה עובר',
            zh_tw: '通過案例',
            fil: 'Paglipas ng kaso',
            id: 'Kasus yang berhasil diselesaikan'
        },
        outpatient_121: {
            ru: 'Группа болезни',
            en: 'Disease group',
            tr: 'Hastalık grubu',
            es: 'Grupo de enfermedades',
            ar: 'مجموعة المرض',
            de: 'Krankheitsgruppe',
            pt: 'Grupo da doença',
            fr: 'Groupe de maladies',
            zh: '疾病组',
            ko: '질병 그룹',
            ja: '疾患グループ',
            th: 'กลุ่มโรค',
            he: 'קבוצת מחלות',
            zh_tw: '疾病組',
            fil: 'Grupo ng sakit',
            id: 'Kelompok penyakit'
        },
        outpatient_122: {
            ru: 'Подгруппа болезни',
            en: 'Subgroup of the disease',
            tr: 'Hastalığın alt grubu',
            es: 'Subgrupo de la enfermedad',
            ar: 'مجموعة فرعية من المرض',
            de: 'Untergruppe der Krankheit',
            pt: 'Subgrupo da doença',
            fr: 'Sous-groupe de la maladie',
            zh: '疾病子组',
            ko: '질병 하위 그룹',
            ja: '疾患のサブグループ',
            th: 'กลุ่มย่อยของโรค',
            he: 'תת-קבוצת המחלה',
            zh_tw: '疾病子組',
            fil: 'Subgrupo ng sakit',
            id: 'Subkelompok penyakit'
        },
        outpatient_123: {
            ru: 'Болезнь',
            en: 'Disease',
            tr: 'Hastalık',
            es: 'Enfermedad',
            ar: 'المرض',
            de: 'Krankheit',
            pt: 'Doenca',
            fr: 'Maladie',
            zh: '疾病',
            ko: '질병',
            ja: '疾患',
            th: 'โรค',
            he: 'מחלה',
            zh_tw: '疾病',
            fil: 'Sakit',
            id: 'Penyakit'
        },
        outpatient_124: {
            ru: 'Подвид болезни',
            en: 'Subspecies of the disease',
            tr: 'Hastalığın alt türleri',
            es: 'Subespecies de la enfermedad',
            ar: 'سلالات من المرض',
            de: 'Unterarten der Krankheit',
            pt: 'Subespécies da doença',
            fr: 'Sous-espèces de la maladie',
            zh: '疾病亚种',
            ko: '질병 하위 종',
            ja: '疾患のサブスペシーズ',
            th: 'สายพันธุ์ย่อยของโรค',
            he: 'תת-מין של המחלה',
            zh_tw: '疾病亞種',
            fil: 'Subspecies ng sakit',
            id: 'Subspesies penyakit'
        },
        outpatient_125: {
            ru: 'Добавление диагноза',
            en: 'Adding a diagnosis',
            tr: 'Teşhis ekleme',
            es: 'Agregar un diagnóstico',
            ar: 'إضافة التشخيص',
            de: 'Hinzufügen einer Diagnose',
            pt: 'Adicionando um diagnóstico',
            fr: "Ajout d'un diagnostic",
            zh: '添加诊断',
            ko: '진단 추가',
            ja: '診断の追加',
            th: 'การเพิ่มการวินิจฉัย',
            he: 'הוספת אבחנה',
            zh_tw: '添加診斷',
            fil: 'Pagdaragdag ng diagnosis',
            id: 'Menambahkan diagnosis'
        },
        outpatient_126: {
            ru: 'Добавление препарата',
            en: 'Adding medicine',
            tr: 'İlaç ekleme',
            es: 'Adición de medicamentos',
            ar: 'إضافة الدواء',
            de: 'Medizin hinzufügen',
            pt: 'Adição de medicamentos',
            fr: 'Ajout de médicaments',
            zh: '添加药品',
            ko: '약물 추가',
            ja: '薬の追加',
            th: 'การเพิ่มยา',
            he: 'הוספת תרופה',
            zh_tw: '添加藥物',
            fil: 'Pagdaragdag ng gamot',
            id: 'Menambahkan obat'
        },
        outpatient_127: {
            ru: 'Подкатегория',
            en: 'Subcategory',
            tr: 'Alt Kategori',
            es: 'Subcategoría',
            ar: 'الفئة الفرعية',
            de: 'Unterkategorie',
            pt: 'Subcategoria',
            fr: 'Sous-Catégorie',
            zh: '子类别',
            ko: '하위 카테고리',
            ja: 'サブカテゴリ',
            th: 'หมวดหมู่ย่อย',
            he: 'תת-קטגוריה',
            zh_tw: '子類別',
            fil: 'Subkategorya',
            id: 'Subkategori'
        },
        outpatient_128: {
            ru: 'Укажите кол-во колонок и их названия.',
            en: 'Enter the number of columns and their names.',
            tr: 'Sütun sayısını ve isimlerini girin.',
            es: 'Ingrese el número de columnas y sus nombres.',
            ar: 'أدخل عدد الأعمدة وأسماءها.',
            de: 'Geben Sie die Anzahl der Spalten und deren Namen ein.',
            pt: 'Digite o número de colunas e seus nomes.',
            fr: 'Entrez le nombre de colonnes et leurs noms.',
            zh: '输入列的数量及其名称。',
            ko: '열의 개수와 그 이름을 입력하세요.',
            ja: '列の数とその名前を入力してください。',
            th: 'ป้อนจำนวนคอลัมน์และชื่อของพวกเขา',
            he: 'הזן את מספר העמודות ושמותיהן.',
            zh_tw: '輸入列的數量和名稱。',
            fil: 'Ipasok ang bilang ng mga kolum at ang kanilang mga pangalan.',
            id: 'Masukkan jumlah kolom dan namanya.'
        },
        outpatient_129: {
            ru: 'Выделить',
            en: 'Highlight',
            tr: 'Vurgula',
            es: 'Destacar',
            ar: 'تسليط الضوء',
            de: 'Hervorheben',
            pt: 'Destacar',
            fr: 'Mettre en évidence',
            zh: '高亮',
            ko: '하이라이트',
            ja: 'ハイライト',
            th: 'เน้น',
            he: 'הדגשה',
            zh_tw: '突出顯示',
            fil: 'I-highlight',
            id: 'Sorotan'
        },
        outpatient_130: {
            ru: 'Категория доп. обследования',
            en: 'Additional examination category',
            tr: 'Ek inceleme kategorisi',
            es: 'Categoría de examen adicional',
            ar: 'فئة الفحص الإضافي',
            de: 'Zusätzliche Untersuchungskategorie',
            pt: 'Categoria de exame adicional',
            fr: "Catégorie d'examen supplémentaire",
            zh: '额外检查类别',
            ko: '추가 검사 카테고리',
            ja: '追加の検査カテゴリ',
            th: 'หมวดการตรวจเพิ่มเติม',
            he: 'קטגוריית בדיקה נוספת',
            zh_tw: '額外檢查類別',
            fil: 'Karagdagang kategorya ng pagsusuri',
            id: 'Kategori pemeriksaan tambahan'
        },
        outpatient_131: {
            ru: 'Вкладки доп. обследования',
            en: 'Additional examination tabs',
            tr: 'Ek inceleme sekmeleri',
            es: 'Pestañas de examen adicional',
            ar: 'علامات تبويب الفحص الإضافي',
            de: 'Zusätzliche Untersuchungstabs',
            pt: 'Abas de exame adicional',
            fr: "Onglets d'examen supplémentaire",
            zh: '额外检查标签',
            ko: '추가 검사 탭',
            ja: '追加の検査タブ',
            th: 'แท็บการตรวจเพิ่มเติม',
            he: 'כרטיסיות בדיקה נוספת',
            zh_tw: '額外檢查標籤',
            fil: 'Mga tab ng karagdagang pagsusuri',
            id: 'Tab pemeriksaan tambahan'
        },
        outpatient_132: {
            ru: 'Вкладки кейса',
            en: 'Case tabs',
            tr: 'Vaka sekmeleri',
            es: 'Pestañas de caso',
            ar: 'علامات تبويب الحالة',
            de: 'Fall-Tabs',
            pt: 'Abas de caso',
            fr: 'Onglets de cas',
            zh: '案例标签',
            ko: '사례 탭',
            ja: 'ケースタブ',
            th: 'แท็บเคส',
            he: 'כרטיסיות מקרה',
            zh_tw: '案例標籤',
            fil: 'Mga tab ng kaso',
            id: 'Tab kasus'
        },
        outpatient_133: {
            ru: 'Категория препаратов',
            en: 'Drug category',
            tr: 'İlaç kategorisi',
            es: 'Categoría de medicamento',
            ar: 'فئة الدواء',
            de: 'Medikamentenkategorie',
            pt: 'Categoria de medicamento',
            fr: 'Catégorie de médicament',
            zh: '药物类别',
            ko: '약품 카테고리',
            ja: '薬のカテゴリ',
            th: 'หมวดยา',
            he: 'קטגוריית תרופה',
            zh_tw: '藥物類別',
            fil: 'Kategorya ng gamot',
            id: 'Kategori obat'
        },
        outpatient_134: {
            ru: 'Подкатегория препаратов',
            en: 'Drug Subcategory',
            tr: 'İlaç Alt Kategorisi',
            es: 'Subcategoría de medicamento',
            ar: 'تصنيف فرعي للدواء',
            de: 'Medikamenten-Unterkategorie',
            pt: 'Subcategoria de medicamento',
            fr: 'Sous-catégorie de médicament',
            zh: '药物子类',
            ko: '약품 하위 카테고리',
            ja: '薬のサブカテゴリ',
            th: 'หมวดย่อยของยา',
            he: 'תת-קטגוריית תרופה',
            zh_tw: '藥物子類別',
            fil: 'Subkategorya ng gamot',
            id: 'Subkategori obat'
        },
        outpatient_135: {
            ru: 'Скин',
            en: 'Appearance',
            tr: 'Görünüş',
            es: 'Apariencia',
            ar: 'المظهر',
            de: 'Erscheinungsbild',
            pt: 'Aparência',
            fr: 'Apparence',
            zh: '外观',
            ko: '외관',
            ja: '外観',
            th: 'รูปลักษณ์',
            he: 'מראה',
            zh_tw: '外觀',
            fil: 'Anyo',
            id: 'Wujud'
        },
        outpatient_136: {
            ru: 'Название кейсов',
            en: 'Case name',
            tr: 'Vaka adı',
            es: 'Nombre del caso.',
            ar: 'اسم الحالة',
            de: 'Fallname.',
            pt: 'Nome do caso',
            fr: 'Nom du cas',
            zh: '案例名称',
            ko: '사례 이름',
            ja: 'ケース名',
            th: 'ชื่อเคส',
            he: 'שם המקרה',
            zh_tw: '案例名稱',
            fil: 'Pangalan ng kaso',
            id: 'Nama kasus'
        },
        outpatient_137: {
            ru: 'Базовый',
            en: 'Basic',
            tr: 'Temel',
            es: 'Básico',
            ar: 'أساسي',
            de: 'Basis',
            pt: 'Básico',
            fr: 'Basique',
            zh: '基本',
            ko: '기본',
            ja: 'ベーシック',
            th: 'พื้นฐาน',
            he: 'בסיסי',
            zh_tw: '基本',
            fil: 'Batayang',
            id: 'Dasar'
        },
        outpatient_138: {
            ru: 'Пользовательский',
            en: 'Custom',
            tr: 'Özel',
            es: 'Personalizado',
            ar: 'مخصص',
            de: 'Benutzerdefiniert',
            pt: 'Personalizado',
            fr: 'Personnalisé',
            zh: '自定义',
            ko: '사용자 정의',
            ja: 'カスタム',
            th: 'ทำเอง',
            he: 'מותאם אישית',
            zh_tw: '自訂',
            fil: 'Pasadya',
            id: 'Custom'
        },
        outpatient_139: {
            ru: 'Переключение кейса',
            en: 'Case switch.',
            tr: 'Vaka değiştirme.',
            es: 'Cambio de caso.',
            ar: 'تبديل الحالة.',
            de: 'Fallwechsel.',
            pt: 'Mudança de caso.',
            fr: 'Changement de cas.',
            zh: '案件切换 (Ànjiàn qiēhuàn).',
            ko: '케이스 전환 ',
            ja: 'ケースの切り替え',
            th: 'เปลี่ยนเคส',
            he: 'החלפת מקרה',
            zh_tw: '案例切換',
            fil: 'Pagpapalit ng kaso',
            id: 'Pertukaran kasus.'
        },
        outpatient_140: {
            ru: 'Все назначенные в Смарт Центре экзамены данного кейса будут отменены',
            en: 'All exams assigned in the Smart Center for this case will be canceled.',
            tr: "Bu vaka için Smart Merkezi'nde atanan tüm sınavlar iptal edilecektir.",
            es: 'Todos los exámenes asignados en el Centro Inteligente para este caso serán cancelados.',
            ar: 'سيتم إلغاء جميع الامتحانات المعينة في مركز سمارت لهذا الحالة.',
            de: 'Alle im Smart Center für diesen Fall zugewiesenen Prüfungen werden abgesagt.',
            pt: 'Todos os exames atribuídos no Smart Center para este caso serão cancelados.',
            fr: 'Tous les examens assignés dans le Smart Centre pour ce cas seront annulés.',
            zh: '所有在智能中心为此案例安排的考试将被取消',
            ko: '이 케이스에 할당된 스마트 센터의 모든 시험은 취소될 것입니다',
            ja: 'このケースのすべてのスマートセンターで割り当てられた試験はキャンセルされます',
            th: 'การสอบที่กำหนดในศูนย์สมาร์ทสำหรับกรณีนี้จะถูกยกเลิกทั้งหมด',
            he: 'כל המבחנים שמוקצים במרכז החכם למקרה זה יבוטלו',
            zh_tw: '所有在智能中心為此案例安排的考試將被取消',
            fil: 'Lahat ng mga eksaminasyong itinalaga sa Smart Center para sa kaso na ito ay kanselado',
            id: 'Semua ujian yang ditugaskan di Smart Center untuk kasus ini akan dibatalkan'
        },
        outpatient_141: {
            ru: 'Рост',
            en: 'Height',
            tr: 'Boy',
            es: 'Altura',
            ar: 'الطول',
            de: 'Größe',
            pt: 'Altura',
            fr: 'Taille',
            zh: '身高',
            ko: '키',
            ja: '身長',
            th: 'ส่วนสูง',
            he: 'גובה',
            zh_tw: '身高',
            fil: 'Taas',
            id: 'Tinggi'
        },
        outpatient_142: {
            ru: 'Вес',
            en: 'Weight',
            tr: 'Kilo',
            es: 'Peso',
            ar: 'الوزن',
            de: 'Gewicht',
            pt: 'Peso',
            fr: 'Poids',
            zh: '体重',
            ko: '몸무게',
            ja: '体重',
            th: 'น้ำหนัก',
            he: 'משקל',
            zh_tw: '體重',
            fil: 'Timbang',
            id: 'Berat'
        },
        outpatient_143: {
            ru: 'Пациент',
            en: 'Patient',
            tr: 'Hasta',
            es: 'Paciente',
            ar: 'المريض',
            de: 'Patient',
            pt: 'Paciente',
            fr: 'Patient',
            zh: '患者',
            ko: '환자',
            ja: '患者',
            th: 'ผู้ป่วย',
            he: 'מטופל',
            zh_tw: '病患',
            fil: 'Pasyente',
            id: 'Pasien'
        },
        outpatient_144: {
            ru: 'Сопровождающий',
            en: '',
            tr: '',
            es: '',
            ar: '',
            de: '',
            pt: '',
            fr: '',
            zh: '',
            ko: '',
            ja: '',
            th: '',
            he: '',
            zh_tw: '',
            fil: '',
            id: ''
        },
        common_1: {
            ru: 'Паспорт',
            en: 'Passport',
            tr: 'Pasaport',
            es: 'Pasaporte',
            ar: 'جواز سفر',
            de: 'Reisepass',
            pt: 'Passaporte',
            fr: 'Passeport',
            zh: '护照',
            ko: '여권',
            ja: 'パスポート',
            th: 'หนังสือเดินทาง',
            he: 'דרכון',
            zh_tw: '護照',
            fil: 'Passport',
            id: 'Paspor'
        },
        common_2: {
            ru: 'Лечение',
            en: 'Treatment',
            tr: 'Tedavi',
            es: 'Tratamiento',
            ar: 'علاج',
            de: 'Behandlung',
            pt: 'Tratamento',
            fr: 'Traitement',
            zh: '基础信息',
            ko: '치료',
            ja: '治療',
            th: 'การรักษา',
            he: 'טיפול',
            zh_tw: '治療',
            fil: 'Paggamot',
            id: 'Pengobatan'
        },
        common_3: {
            ru: 'Препараты',
            en: 'Drugs',
            tr: 'İlaçlar',
            es: 'Medicamentos',
            ar: 'أدوية',
            de: 'Medikamente',
            pt: 'Remédios',
            fr: 'Médicaments',
            zh: '药物',
            ko: '약물',
            ja: '薬剤',
            th: 'ยา',
            he: 'תרופות',
            zh_tw: '藥物',
            fil: 'Mga Gamot',
            id: 'Obat'
        },
        common_4: {
            ru: 'Диалоги',
            en: 'Dialogue',
            tr: 'Diyalog',
            es: 'Diálogo',
            ar: 'حوار',
            de: 'Dialoge',
            pt: 'Diálogos',
            fr: 'Dialogues',
            zh: '对话',
            ko: '대화',
            ja: '対話',
            th: 'สนทนา',
            he: 'שיחה',
            zh_tw: '對話',
            fil: 'Dialogo',
            id: 'Dialog'
        },
        common_5: {
            ru: 'Диагнозы',
            en: 'Diagnosis',
            tr: 'Teşhis',
            es: 'Diagnóstico',
            ar: 'تشخيص',
            de: 'Diagnosen',
            pt: 'Diagnósticos',
            fr: 'Diagnostic',
            zh: '诊断',
            ko: '진단',
            ja: '診断',
            th: 'การวินิจฉัย',
            he: 'אבחנה',
            zh_tw: '診斷',
            fil: 'Diagnosis',
            id: 'Diagnosis'
        }
    },
    race: [
        {
            _id: '62289508ed5066ebe88c9663',
            name: {
                ru: 'Негроид',
                en: 'Negroid',
                tr: 'Zenci',
                es: 'Negroide',
                ar: 'قوقازي',
                de: 'Negroid',
                pt: 'Negroide',
                fr: 'Négroïde',
                zh: '黑人',
                ko: '흑인',
                ja: 'ネグロイド',
                th: 'คนเหนือสีผิว',
                he: 'נגרואידי',
                zh_tw: '黑種人',
                fil: 'Negroid',
                id: 'Negroid'
            }
        },
        {
            _id: '622b104826be943bd6fa5ded',
            name: {
                ru: 'Европеоид',
                en: 'Caucasian',
                tr: 'Kafkas',
                es: 'Caucasiano',
                ar: 'قوقازي',
                de: 'Caucasoid',
                pt: 'Caucasoide',
                fr: 'Européen',
                zh: '白人',
                ko: '백인',
                ja: 'コーカソイド',
                th: 'คนคาวคอซี',
                he: 'קוקזי',
                zh_tw: '高加索人',
                fil: 'Caucasian',
                id: 'Kaukasoid'
            }
        }
    ]
}

interface Lang {
    errors: any,
    info: any
    race: Array<{ _id: string, name: LangLocalization }>
}
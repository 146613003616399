import {$api} from "./index";
import {detectedError} from "../Utils";

export const getNursingActiveCases = async () => {
    try {
        const response = await $api.get(`api/nursing/cases/active?packId=${localStorage.getItem('nursingPackId')}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingErrorCases = async (data: object) => {
    try {
        const response = await $api.post(`api/nursing/cases/error`, data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getCategories = async () => {
    try {
        const response = await $api.get('api/nursing/categories');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getCaseData = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/case/data?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateMainData = async (data: object) => {
    try {
        return await $api.put(`api/nursing/case/data`, data);
    } catch (ex) {
        detectedError(ex);
    }
}

export const getLocation = async () => {
    try {
        const response = await $api.get('api/nursing/case/location');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateLocation = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/case/location', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertLocation = async () => {
    try {
        const response = await $api.post('api/nursing/case/location');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteLocation = async (locationId: string) => {
    try {
        const response = await $api.delete(`api/nursing/case/location?locationId=${locationId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getType = async () => {
    try {
        const response = await $api.get('api/nursing/case/type');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateType = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/case/type', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertType = async () => {
    try {
        const response = await $api.post('api/nursing/case/type');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteType = async (typeId: string) => {
    try {
        const response = await $api.delete(`api/nursing/case/type?typeId=${typeId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getUrinaryColor = async () => {
    try {
        const response = await $api.get('api/nursing/patient/urinary');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateUrinaryColor = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/patient/urinary', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertUrinaryColor = async () => {
    try {
        const response = await $api.post('api/nursing/patient/urinary');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteUrinaryColor = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/patient/urinary?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getPupilReaction = async () => {
    try {
        const response = await $api.get('api/nursing/patient/pupil');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePupilReaction = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/patient/pupil', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertPupilReaction = async () => {
    try {
        const response = await $api.post('api/nursing/patient/pupil');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deletePupilReaction = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/patient/pupil?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getSkinTurgor = async () => {
    try {
        const response = await $api.get('api/nursing/patient/skin');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateSkinTurgor = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/patient/skin', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertSkinTurgor = async () => {
    try {
        const response = await $api.post('api/nursing/patient/skin');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteSkinTurgor = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/patient/skin?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getSpeech = async () => {
    try {
        const response = await $api.get('api/nursing/patient/speech');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateSpeech = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/patient/speech', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertSpeech = async () => {
    try {
        const response = await $api.post('api/nursing/patient/speech');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteSpeech = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/patient/speech?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getStates = async () => {
    try {
        const response = await $api.get('api/nursing/patient/states');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateStates = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/patient/states', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertStates = async () => {
    try {
        const response = await $api.post('api/nursing/patient/states');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteStates = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/patient/states?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getWho = async () => {
    try {
        const response = await $api.get('api/nursing/dialogue/who');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateWho = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/dialogue/who', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertWho = async () => {
    try {
        const response = await $api.post('api/nursing/dialogue/who');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteWho = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/dialogue/who?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getVacutainerTubes = async () => {
    try {
        const response = await $api.get('api/nursing/investigation/vacutainer');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateVacutainerTubes = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/investigation/vacutainer', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertVacutainerTubes = async () => {
    try {
        const response = await $api.post('api/nursing/investigation/vacutainer');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteVacutainerTubes = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/investigation/vacutainer?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getDurationUnit = async () => {
    try {
        const response = await $api.get('api/nursing/investigation/duration');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateDurationUnit = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/investigation/duration', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertDurationUnit = async () => {
    try {
        const response = await $api.post('api/nursing/investigation/duration');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteDurationUnit = async (id: string) => {
    try {
        const response = await $api.delete(`api/nursing/investigation/duration?id=${id}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getPatientState = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/patient/state?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updatePatientState = async (data: object) => {
    try {
        return await $api.put(`api/nursing/patient/state`, data);
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertPatientState = async (data: object) => {
    try {
        return await $api.post(`api/nursing/patient/state`, data);
    } catch (ex) {
        detectedError(ex);
    }
}

export const copySpecialStates = async (data: object) => {
    try {
        return await $api.post(`api/nursing/patient/state/states/copy`, data);
    } catch (ex) {
        detectedError(ex);
    }
}

export const deletePatientState = async (patientId: string, caseId: string) => {
    try {
        return await $api.delete(`api/nursing/patient/state?patientId=${patientId}&caseId=${caseId}`);
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingDialogue = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/dialogue?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingDialogue = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/dialogue`, body);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertNursingDialogue = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/dialogue`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const generateAudioNursingDialogue = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/dialogue/generate/audio`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const generateAudioNursingDialoguePackage = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/dialogue/generate/audio/package`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteNursingDialogueAudio = async (params: object) => {
    try {
        const response = await $api.delete(`api/nursing/dialogue/audio`, {data: params});
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteNursingDialogue = async (dialogueId: string, caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/dialogue?dialogueId=${dialogueId}&caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getInvestigation = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/investigation?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateInvestigation = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/investigation`, body);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertInvestigation = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/investigation`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteInvestigation = async (investigationId: string, caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/investigation?investigationId=${investigationId}&caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getDrugRange = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/drug?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertDrugRangeSet = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/drug/set`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertDrugRange = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/drug`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateDrugRange = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/drug`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateDrugRangeSet = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/drug/set`, body);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const deleteDrugRange = async (rangeId: string, caseId: string, rangeSetId: string, rangeSetIndex: number) => {
    try {
        const response = await $api.delete(`api/nursing/drug?rangeId=${rangeId}&caseId=${caseId}&rangeSetId=${rangeSetId}&rangeSetIndex=${rangeSetIndex}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteDrugRangeSet = async (rangeSetId: string, caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/drug/set?rangeSetId=${rangeSetId}&caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingMedication = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/medication?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertNursingMedication = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/medication`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteNursingMedication = async (medicineId: string, body: object) => {
    try {
        const response = await $api.delete(`api/nursing/medication?medicineId=${medicineId}`, {data: body});
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingMedication = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/medication`, body);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const updateNursingMedicationDosage = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/medication/dosage`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getDrugType = async () => {
    try {
        const response = await $api.get('api/nursing/drug/type');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateDrugType = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/drug/type', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const nursingUploadFile = async (data: object) => {
    try {
        const response = await $api.post('api/nursing/media/upload', data, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteInvestigationImage = async (investigationId: string, caseId: string, index: number) => {
    try {
        const response = await $api.delete(`api/nursing/investigation/image?investigationId=${investigationId}&caseId=${caseId}&index=${index}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteImageCaseData = async (dataId: string, caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/case/data/image?dataId=${dataId}&caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getNursingProfileCases = async () => {
    try {
        const response = await $api.get(`api/nursing/case/profile`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingPackCase = async (data: object) => {
    try {
        const response = await $api.put('api/nursing/pack/case', data);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const copyNursingCase = async (data: object) => {
    try {
        const response = await $api.post('api/nursing/case/copy', data);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const copyTabNursingCase = async (data: object) => {
    try {
        const response = await $api.post('api/nursing/case/copy/tab', data);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const deleteNursingCase = async (caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/case?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const updateNameNursingCase = async (data: object) => {
    try {
        const response = await $api.put(`api/nursing/case/name`, data);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const deleteFlowchartNursingCase = async (caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/case/flowchart?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const getNursingMessages = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/messages?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingMessage = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/messages`, body);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const insertNursingMessage = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/messages`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}
export const deleteNursingMessage = async (messageId: string, caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/messages?messageId=${messageId}&caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateNursingCaseVerify = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/case/verify`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getVentilation = async (caseId: string) => {
    try {
        const response = await $api.get(`api/nursing/ventilation?caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertVentilationSet = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/ventilation/set`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertVentilation = async (body: object) => {
    try {
        const response = await $api.post(`api/nursing/ventilation`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateVentilation = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/ventilation`, body);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateVentilationSet = async (body: object) => {
    try {
        const response = await $api.put(`api/nursing/ventilation/set`, body);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const deleteVentilation = async (ventilationId: string, caseId: string, rangeSetId: string, rangeSetIndex: number) => {
    try {
        const response = await $api.delete(`api/nursing/ventilation?ventilationId=${ventilationId}&caseId=${caseId}&rangeSetId=${rangeSetId}&rangeSetIndex=${rangeSetIndex}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteVentilationSet = async (rangeSetId: string, caseId: string) => {
    try {
        const response = await $api.delete(`api/nursing/ventilation/set?rangeSetId=${rangeSetId}&caseId=${caseId}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const switchNursingCase = async (params: object) => {
    try {
        const response = await $api.post('api/nursing/case/switch', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}